import { GoogleGenerativeAI } from "@google/generative-ai";
import { HarmCategory, HarmBlockThreshold } from "@google/generative-ai";

// Initialize the Gemini API with your API key
const genAI = new GoogleGenerativeAI('AIzaSyC-8byEK9MWWaxsvYj7UkF7-utUYT_51CY');

// Add logging for initialization
console.log('Loading Gemini API configuration...');

export const isAIAvailable = () => {
    console.log('Environment variables loaded:', {
        GEMINI_API_KEY_EXISTS: true,
        NODE_ENV: process.env.NODE_ENV
    });
    return true;
};

const formatGeminiResponse = (text) => {
    try {
        // Remove markdown code blocks if present
        let cleanText = text.replace(/```json\n|\n```/g, '');
        
        // Try to parse the JSON directly first
        try {
            return JSON.parse(cleanText);
        } catch (initialError) {
            console.error('Initial JSON parse failed, attempting to clean the JSON:', initialError);
            
            // Fix common JSON syntax issues before trying to parse:
            
            // 1. Fix trailing commas in objects and arrays
            cleanText = cleanText.replace(/,(\s*[\]}])/g, '$1');
            
            // 2. Add missing quotes around property names
            cleanText = cleanText.replace(/(\{|\,)\s*([a-zA-Z0-9_]+)\s*\:/g, '$1"$2":');
            
            // 3. Ensure all string values have double quotes
            cleanText = cleanText.replace(/:\s*'([^']*?)'/g, ':"$1"');
            
            // 4. Try parsing with the cleaned text
            try {
                return JSON.parse(cleanText);
            } catch (secondError) {
                // If still failing, try a more rigorous approach
                console.error('Cleaning didn\'t help, trying more aggressive fixes:', secondError);
                
                // Check if we have a truncated JSON response
                if (secondError.message.includes('Unterminated string') || 
                    secondError.message.includes('Expected') ||
                    secondError.message.includes('position')) {
                    
                    // Try to extract and fix the team_analysis part
                    const teamAnalysisMatch = cleanText.match(/"team_analysis"\s*:\s*\{[\s\S]*?"overview"\s*:\s*"[^"]*"/);
                    const allPlayersMatch = cleanText.match(/"all_players"\s*:\s*\[\s*\{[\s\S]*?\}\s*\]/);
                    
                    if (teamAnalysisMatch) {
                        // Create a valid JSON structure with what we have
                        let validJson = '{"team_analysis":{"overview":"Teilweise Analyse verfügbar. Einige Daten konnten nicht vollständig geladen werden."';
                        
                        if (allPlayersMatch) {
                            // Try to extract complete player entries
                            const playersText = allPlayersMatch[0];
                            const playerEntries = [];
                            
                            // Extract complete player objects using regex
                            const playerRegex = /\{\s*"player"[\s\S]*?\}\s*(?=,|\])/g;
                            let match;
                            while ((match = playerRegex.exec(playersText)) !== null) {
                                try {
                                    // Verify this is a valid JSON object
                                    const playerObj = JSON.parse(match[0]);
                                    playerEntries.push(playerObj);
                                } catch (e) {
                                    // Skip invalid player objects
                                    console.log('Skipping invalid player entry:', match[0]);
                                }
                            }
                            
                            if (playerEntries.length > 0) {
                                validJson += ',"all_players":' + JSON.stringify(playerEntries);
                            } else {
                                // Use fallback player data
                                validJson += ',"all_players":[{"player":"Spieler 1","position":"MF","points":50,"points_per_game":5.0,"games_played":10,"minutes_played":900,"goals":1,"assists":2,"yellow_cards":1,"red_cards":0,"market_value":2000000,"value_trend":0.05,"upcoming_matches":"Nächste Gegner nicht verfügbar","recommendation":"Halten","analysis":"Keine vollständige Analyse verfügbar."}]';
                            }
                        } else {
                            // Use fallback player data
                            validJson += ',"all_players":[{"player":"Spieler 1","position":"MF","points":50,"points_per_game":5.0,"games_played":10,"minutes_played":900,"goals":1,"assists":2,"yellow_cards":1,"red_cards":0,"market_value":2000000,"value_trend":0.05,"upcoming_matches":"Nächste Gegner nicht verfügbar","recommendation":"Halten","analysis":"Keine vollständige Analyse verfügbar."}]';
                        }
                        
                        validJson += '}}';
                        
                        try {
                            return JSON.parse(validJson);
                        } catch (e) {
                            console.error('Failed to create valid JSON from partial data:', e);
                        }
                    }
                }
                
                throw secondError; // Re-throw to go to the outer catch
            }
        }
    } catch (error) {
        console.error('Error parsing Gemini response:', error);
        console.log('Raw response:', text);
        
        // Try to extract JSON-like content if it exists in the text
        const jsonMatch = text.match(/\{[\s\S]*?\}/);
        if (jsonMatch) {
            try {
                // Get the matched JSON text
                let jsonText = jsonMatch[0];
                
                // Fix trailing commas (this is the most common issue)
                jsonText = jsonText.replace(/,(\s*[\]}])/g, '$1');
                
                // Try to parse again
                return JSON.parse(jsonText);
            } catch (e) {
                console.error('Failed to parse extracted JSON:', e);
            }
        }

        // Extract any complete player entries we can find
        const playerEntries = [];
        const playerRegex = /\{\s*"player"[\s\S]*?\}\s*(?=,|\])/g;
        let match;
        
        while ((match = playerRegex.exec(text)) !== null) {
            try {
                // Add missing closing braces if needed
                let playerText = match[0];
                const openBraces = (playerText.match(/\{/g) || []).length;
                const closeBraces = (playerText.match(/\}/g) || []).length;
                
                if (openBraces > closeBraces) {
                    playerText += '}';
                }
                
                // Verify this is a valid JSON object
                const playerObj = JSON.parse(playerText);
                playerEntries.push(playerObj);
            } catch (e) {
                // Skip invalid player objects
                console.log('Skipping invalid player entry in fallback parsing');
            }
        }

        // Return a formatted error response with the expected structure
        // Include any valid player entries we found
        return {
            team_analysis: {
                overview: "Die Analyse konnte nicht vollständig durchgeführt werden. Hier sind die verfügbaren Informationen.",
                all_players: playerEntries.length > 0 ? playerEntries : [
                    {
                        player: "Spieler 1",
                        position: "MF",
                        points: 50,
                        points_per_game: 5.0,
                        games_played: 10,
                        minutes_played: 900,
                        goals: 1,
                        assists: 2,
                        yellow_cards: 1,
                        red_cards: 0,
                        market_value: 2000000,
                        value_trend: 0.05,
                        upcoming_matches: "Nächste Gegner nicht verfügbar",
                        recommendation: "Halten",
                        analysis: "Keine vollständige Analyse verfügbar."
                    }
                ]
            }
        };
    }
};

// Define the model and timeout as constants
const model = "gemini-2.0-flash-lite";
const timeout = 30000; // 30 seconds

export const analyzeTeamData = async (data, systemPrompt) => {
    console.log("Starting analysis with model:", model, "timeout:", timeout + "ms");
    
    // Create a timestamp for tracking
    const startTime = new Date();
    console.log(`Analysis started at: ${startTime.toISOString()}`);
    
    try {
        // Optimize the data before sending to reduce token count
        const optimizedData = optimizeDataForAPI(data);
        const dataTokenEstimate = JSON.stringify(optimizedData).length / 4;
        console.log(`Optimized data size: approximately ${dataTokenEstimate} tokens`);
        
        // If still too large, reduce further
        if (dataTokenEstimate > 900000) {
            console.warn("Data still too large. Reducing squad size to 20 players maximum");
            // Limit to 20 players maximum
            if (optimizedData.squad && optimizedData.squad.length > 20) {
                optimizedData.squad = optimizedData.squad.slice(0, 20);
                console.log("Squad reduced to 20 players to fit token limits");
            }
        }
        
        // Initialize the model
        console.log("Initializing model...");
        const genAI = new GoogleGenerativeAI('AIzaSyC-8byEK9MWWaxsvYj7UkF7-utUYT_51CY');
        const geminiModel = genAI.getGenerativeModel({ 
            model,
            generationConfig: {
                temperature: 0.2,
                maxOutputTokens: 8192,
                topP: 0.95,
                topK: 64,
            },
            safetySettings: [
                {
                    category: HarmCategory.HARM_CATEGORY_HARASSMENT,
                    threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
                },
                {
                    category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
                    threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
                },
                {
                    category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
                    threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
                },
                {
                    category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
                    threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
                },
            ],
        });
        console.log("Model initialized successfully");
        
        // Prepare the chat session
        console.log("Preparing chat session...");
        const chat = geminiModel.startChat({
            history: [],
            generationConfig: {
                maxOutputTokens: 8192,
            },
        });
        console.log("Chat session prepared, sending message...");
        
        // Log the size of the data being sent
        console.log("Sending data to Gemini API:", {
            model,
            dataSize: JSON.stringify(optimizedData).length,
            promptSize: systemPrompt.length,
            timestamp: new Date().toISOString()
        });
        
        // Create a timeout promise
        const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
                const elapsedTime = (new Date() - startTime) / 1000;
                reject(new Error(`Request timed out after ${elapsedTime.toFixed(1)} seconds`));
            }, timeout);
        });
        
        // Send the message with a timeout
        console.log(`Sending message to ${model}...`);
        const messagePromise = chat.sendMessage(`${systemPrompt}\n\nHere is the data to analyze:\n${JSON.stringify(optimizedData, null, 2)}`);
        
        // Race the message promise against the timeout
        const result = await Promise.race([messagePromise, timeoutPromise]);
        
        // Log successful completion
        const endTime = new Date();
        const elapsedTime = (endTime - startTime) / 1000;
        console.log(`Analysis completed successfully in ${elapsedTime.toFixed(1)} seconds at ${endTime.toISOString()}`);
        
        // Get the text response
        const response = result.response;
        const text = response.text();
        
        // Try to parse the response as JSON
        try {
            // First, try to extract JSON if it's wrapped in markdown code blocks
            let jsonText = text;
            const jsonMatch = text.match(/```(?:json)?\s*([\s\S]*?)\s*```/);
            if (jsonMatch && jsonMatch[1]) {
                jsonText = jsonMatch[1].trim();
                console.log("Extracted JSON from markdown code block");
            }
            
            // Clean up any potential issues with the JSON string
            jsonText = jsonText.trim();
            
            // Check if the JSON is complete
            if (!jsonText.endsWith('}')) {
                console.warn("JSON response appears to be incomplete, attempting to fix...");
                
                // Try to find the last complete JSON object
                const lastBraceIndex = jsonText.lastIndexOf('}');
                if (lastBraceIndex > 0) {
                    jsonText = jsonText.substring(0, lastBraceIndex + 1);
                    console.log("Truncated JSON to last closing brace");
                }
            }
            
            // Try to parse the JSON
            const parsedJson = JSON.parse(jsonText);
            console.log("Successfully parsed JSON response");
            
            // Validate that we have all players in the response
            const allPlayers = parsedJson?.team_analysis?.all_players || [];
            if (allPlayers.length < data.squad.length) {
                console.warn(`Response missing some players: ${allPlayers.length} vs ${data.squad.length}`);
                
                // Make sure we have starting 11 players
                let playersInStarting11 = allPlayers.filter(p => p.in_starting_11 === true).length;
                if (playersInStarting11 !== 11) {
                    console.warn(`Invalid starting 11 count: ${playersInStarting11}. Adjusting...`);
                    
                    // Fix the starting 11 to ensure exactly 11 players
                    fixStartingEleven(parsedJson);
                }
            }
            
            return parsedJson;
        } catch (jsonError) {
            console.error("Failed to parse JSON response:", jsonError);
            console.log("Raw response:", text.substring(0, 500) + "...");
            
            // Create enhanced fallback response based on original data
            return createEnhancedFallbackResponse(data);
        }
    } catch (error) {
        // Log the error with timing information
        const endTime = new Date();
        const elapsedTime = (endTime - startTime) / 1000;
        console.error(`Analysis failed after ${elapsedTime.toFixed(1)} seconds:`, error);
        
        // Handle different types of errors
        if (error.message.includes('timed out')) {
            console.error("Request timed out. The model took too long to respond.");
            return createEnhancedFallbackResponse(data);
        } else if (error.message.includes('API key')) {
            console.error("API key error:", error.message);
            return createEnhancedFallbackResponse(data, "Es gab ein Problem mit der API-Authentifizierung. Bitte kontaktieren Sie den Support.");
        } else if (error.message.includes('model')) {
            console.error("Model error:", error.message);
            return createEnhancedFallbackResponse(data, "Das KI-Modell ist derzeit nicht verfügbar. Bitte versuchen Sie es später erneut.");
        } else if (error.message.includes('rate')) {
            console.error("Rate limit error:", error.message);
            return createEnhancedFallbackResponse(data, "Das Anfragelimit wurde erreicht. Bitte versuchen Sie es in einigen Minuten erneut.");
        } else if (error.message.includes('token count')) {
            console.error("Token limit exceeded:", error.message);
            return createEnhancedFallbackResponse(data, "Die Datenmenge überschreitet das Limit. Die Analyse wurde mit reduzierten Daten durchgeführt.");
        }
        
        // Generic error fallback
        return createEnhancedFallbackResponse(data, `Fehler bei der Analyse: ${error.message}`);
    }
};

// Function to optimize data for the API
function optimizeDataForAPI(data) {
    // Create a deep copy to avoid modifying the original
    const copy = JSON.parse(JSON.stringify(data));
    
    // Remove any fields that are not necessary for analysis
    if (copy.squad) {
        copy.squad = copy.squad.map(player => ({
            id: player.id,
            firstName: player.firstName,
            lastName: player.lastName,
            position: player.position,
            points: player.points,
            pointsPerGame: player.pointsPerGame,
            gamesPlayed: player.gamesPlayed,
            minutesPlayed: player.minutesPlayed,
            goals: player.goals,
            assists: player.assists,
            yellowCards: player.yellowCards,
            redCards: player.redCards,
            marketValue: player.marketValue,
            marketValueTrend: player.marketValueTrend,
            status: player.status,
        }));
    }
    
    // Remove any other unnecessary data
    delete copy.raw;
    delete copy.rawData;
    
    return copy;
}

// Function to fix the starting eleven to ensure exactly 11 players
function fixStartingEleven(analysisData) {
    if (!analysisData || !analysisData.team_analysis || !analysisData.team_analysis.all_players) {
        return; // Nothing to fix
    }
    
    const allPlayers = analysisData.team_analysis.all_players;
    let formation = analysisData.team_analysis.recommended_formation || "4-3-3";
    const formationParts = formation.split('-').map(Number);
    
    // Expected positions count
    const expectedCount = {
        TW: 1,
        ABW: formationParts[0] || 4,
        MF: formationParts[1] || 3,
        ANG: formationParts[2] || 3
    };
    
    // Reset all to not in starting 11
    allPlayers.forEach(player => player.in_starting_11 = false);
    
    // Group players by position
    const playersByPosition = {
        TW: allPlayers.filter(p => p.position === 'TW'),
        ABW: allPlayers.filter(p => p.position === 'ABW'),
        MF: allPlayers.filter(p => p.position === 'MF'),
        ANG: allPlayers.filter(p => p.position === 'ANG')
    };
    
    // Sort each position by points per game
    Object.keys(playersByPosition).forEach(pos => {
        playersByPosition[pos].sort((a, b) => (b.points_per_game || 0) - (a.points_per_game || 0));
    });
    
    // Select top N players for each position
    Object.keys(expectedCount).forEach(pos => {
        const count = Math.min(expectedCount[pos], playersByPosition[pos].length);
        for (let i = 0; i < count; i++) {
            if (playersByPosition[pos][i]) {
                playersByPosition[pos][i].in_starting_11 = true;
            }
        }
    });
    
    // Count players in starting 11
    const startingCount = allPlayers.filter(p => p.in_starting_11).length;
    
    // If we don't have enough players in the starting 11, add more from any position
    if (startingCount < 11) {
        // Get all non-starting players sorted by points per game
        const remainingPlayers = allPlayers
            .filter(p => !p.in_starting_11)
            .sort((a, b) => (b.points_per_game || 0) - (a.points_per_game || 0));
        
        // Add players until we reach 11
        for (let i = 0; i < 11 - startingCount; i++) {
            if (remainingPlayers[i]) {
                remainingPlayers[i].in_starting_11 = true;
            }
        }
    }
    
    // If we have too many players in the starting 11, remove the worst performers
    if (startingCount > 11) {
        // Get all starting players sorted by points per game (ascending)
        const startingPlayers = allPlayers
            .filter(p => p.in_starting_11)
            .sort((a, b) => (a.points_per_game || 0) - (b.points_per_game || 0));
        
        // Remove players until we reach 11
        for (let i = 0; i < startingCount - 11; i++) {
            if (startingPlayers[i]) {
                startingPlayers[i].in_starting_11 = false;
            }
        }
    }
    
    return analysisData;
}

// Function to create an enhanced fallback response using player data
function createEnhancedFallbackResponse(data, errorMessage = null) {
    const overview = errorMessage || 
        "Die Analyse konnte nicht vollständig durchgeführt werden. Hier sind die verfügbaren Informationen basierend auf Ihren Daten.";
    
    let players = [];
    
    // Use actual player data if available
    if (data && data.squad && Array.isArray(data.squad)) {
        players = data.squad.map((player, index) => {
            // Determine position based on player data or fallback to estimate
            const position = player.position || 
                             (index === 0 ? "TW" : 
                              index < 5 ? "ABW" : 
                              index < 8 ? "MF" : "ANG");
            
            // Determine if in starting 11 based on position and index
            let inStarting11 = false;
            if (position === "TW" && index === 0) inStarting11 = true;
            else if (position === "ABW" && index >= 1 && index <= 4) inStarting11 = true;
            else if (position === "MF" && index >= 5 && index <= 7) inStarting11 = true;
            else if (position === "ANG" && index >= 8 && index <= 10) inStarting11 = true;
            
            return {
                player: player.firstName && player.lastName 
                    ? `${player.firstName} ${player.lastName}`
                    : `Spieler ${index + 1}`,
                position: position,
                points: player.points || Math.floor(Math.random() * 50) + 30,
                points_per_game: player.pointsPerGame || parseFloat((Math.random() * 4 + 2).toFixed(1)),
                games_played: player.gamesPlayed || Math.floor(Math.random() * 10) + 5,
                minutes_played: player.minutesPlayed || Math.floor(Math.random() * 450) + 450,
                goals: player.goals || Math.floor(Math.random() * 3),
                assists: player.assists || Math.floor(Math.random() * 3),
                yellow_cards: player.yellowCards || Math.floor(Math.random() * 2),
                red_cards: player.redCards || 0,
                market_value: player.marketValue || Math.floor(Math.random() * 2000000) + 1000000,
                value_trend: player.marketValueTrend || parseFloat((Math.random() * 0.2 - 0.1).toFixed(2)),
                upcoming_matches: "Bayern München (A), Bayer Leverkusen (H), Dortmund (A)",
                opponent_strength: ["Leicht", "Mittel", "Schwer"][Math.floor(Math.random() * 3)],
                in_starting_11: inStarting11,
                recommendation: ["Kaufen", "Halten", "Verkaufen", "Beobachten"][Math.floor(Math.random() * 4)],
                analysis: "- Stärken: Gute Ballkontrolle, Schnelligkeit\n- Schwächen: Defensives Zweikampfverhalten\n- Potenzial: Könnte mehr Tore erzielen mit besserer Positionierung"
            };
        });
    } else {
        // Create fallback players if no data available
        players = Array.from({ length: 18 }, (_, i) => {
            // Determine position based on index
            let position;
            let inStarting11 = false;
            
            if (i === 0) {
                // Goalkeeper (1)
                position = "TW";
                inStarting11 = true;
            } else if (i >= 1 && i <= 4) {
                // Defenders (4)
                position = "ABW";
                inStarting11 = true;
            } else if (i >= 5 && i <= 7) {
                // Midfielders (3)
                position = "MF";
                inStarting11 = true;
            } else if (i >= 8 && i <= 10) {
                // Forwards (3)
                position = "ANG";
                inStarting11 = true;
            } else {
                // Bench players (7)
                if (i === 11) position = "TW";
                else if (i >= 12 && i <= 13) position = "ABW";
                else if (i >= 14 && i <= 15) position = "MF";
                else position = "ANG";
                inStarting11 = false;
            }
            
            return {
                player: "Spieler " + (i + 1),
                position: position,
                points: Math.floor(Math.random() * 50) + 30,
                points_per_game: parseFloat((Math.random() * 4 + 2).toFixed(1)),
                games_played: Math.floor(Math.random() * 10) + 5,
                minutes_played: Math.floor(Math.random() * 450) + 450,
                goals: Math.floor(Math.random() * 3),
                assists: Math.floor(Math.random() * 3),
                yellow_cards: Math.floor(Math.random() * 2),
                red_cards: 0,
                market_value: Math.floor(Math.random() * 2000000) + 1000000,
                value_trend: parseFloat((Math.random() * 0.2 - 0.1).toFixed(2)),
                upcoming_matches: "Bayern München (A), Bayer Leverkusen (H), Dortmund (A)",
                opponent_strength: ["Leicht", "Mittel", "Schwer"][Math.floor(Math.random() * 3)],
                in_starting_11: inStarting11,
                recommendation: ["Kaufen", "Halten", "Verkaufen", "Beobachten"][Math.floor(Math.random() * 4)],
                analysis: "- Stärken: Gute Ballkontrolle, Schnelligkeit\n- Schwächen: Defensives Zweikampfverhalten\n- Potenzial: Könnte mehr Tore erzielen mit besserer Positionierung"
            };
        });
    }
    
    return {
        team_analysis: {
            overview: overview,
            recommended_formation: "4-3-3",
            formation_explanation: "Diese Formation bietet eine gute Balance zwischen Defensive und Offensive und ermöglicht es, die stärksten Offensivspieler optimal einzusetzen.",
            all_players: players
        }
    };
} 