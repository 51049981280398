import { 
    makeAutoObservable, 
    observable, 
    runInAction, 
    computed, 
    action 
} from 'mobx';
import { getUserInfo, getPlayerInfo, getMarketPlayers, getLeagueStats,getLeagueUserInfo, getPlayerStats, getFavoritePlayers, addFavoritePlayer, removeFavoritePlayer, getLeagueFeed, getPlayerValueAtDate, getPlayerPoints, getLeagueRanking } from '../api';
import { playerService } from '../api/playerService';
import { functions } from '../firebase/config';
import { httpsCallable } from 'firebase/functions';

import Player from './Player';
import { getKnownPlayerIds } from '../utils/playerUtils';
import { dbManager } from '../utils/DatabaseManager';
import { openDB } from 'idb';
import React from 'react';

class PlayerStore {
    players = [];
    _leagueId = null;
    leagues = observable.array([], { deep: false });
    leaguesLoading = true;
    marketPlayers = observable.array([], { deep: false });
    expire = 0;
    userInfo = null;
    currentLeagueId = null;
    favoritePlayers = [];
    favoritePlayersCache = new Map();
    allPlayers = [];
    marketWinners = [];
    marketLoosers = [];
    userInfo = null;
    marketAll = [];
    calculatedPlayerDataCache = new Map();
    recentSales = [];
    estimatedSalePrices = new Map();
    dbPromise;
    statsCache = new Map();
    leagueInfo = null;
    marketPlayersFetched = false;
    isInitialized = false;
    budget = 0;
    isAuthenticated = false;
    user = null;
    token = null;
    teamValue = 0;
    cache = new Map();
    cacheTimeout = 5 * 60 * 1000; // 5 minutes

    constructor() {
        runInAction(() => {
            this.leagues = observable.array([], { deep: false });
            this.players = observable.array([], { deep: false });
            this.marketPlayers = observable.array([], { deep: false });
            this.leagueId = null;
            this.budget = 0;
        });

        makeAutoObservable(this, {
            players: false,
            marketPlayers: false,
            leagues: false,
            userPlayers: computed
        });

        // Run cache cleanup every 10 minutes
        setInterval(() => this.cleanupCache(), 10 * 60 * 1000);
    }

    cleanupCache() {
        const now = Date.now();
        for (const [key, value] of this.cache.entries()) {
            if (now - value.timestamp > this.cacheTimeout) {
                this.cache.delete(key);
            }
        }
    }

    async fetchWithRetry(fn, retries = 3, delay = 1000) {
        for (let i = 0; i < retries; i++) {
            try {
                return await fn();
            } catch (error) {
                if (i === retries - 1) throw error;
                console.warn(`Retry attempt ${i + 1} of ${retries} failed:`, error);
                await new Promise(resolve => setTimeout(resolve, delay * Math.pow(2, i)));
            }
        }
    }

    async refreshTokenIfNeeded() {
        const tokenExpiry = localStorage.getItem('token_expiry');
        if (tokenExpiry && Date.now() > parseInt(tokenExpiry) - 300000) { // 5 minutes before expiry
            try {
                // Implement token refresh logic here
                const response = await this.refreshToken();
                localStorage.setItem('token', response.token);
                localStorage.setItem('token_expiry', response.expiry);
            } catch (error) {
                console.error('Token refresh failed:', error);
                this.clearAuth();
            }
        }
    }

    async getEnhancedPlayerData(playerId) {
        const cacheKey = `player_${playerId}`;
        const cachedData = this.getCachedData(cacheKey);
        
        if (cachedData) return cachedData;

        const data = await this.fetchWithRetry(() => 
            playerService.getEnhancedPlayerData(this.leagueId, playerId)
        );

        this.setCachedData(cacheKey, data);
        return data;
    }

    async initialize() {
        try {
            const authState = this.checkAuthState();
            if (!authState) {
                return false;
            }

            // Fetch leagues with new structure
            const leaguesResponse = await getLeagueUserInfo();
            console.log('Raw leagues response:', leaguesResponse);

            if (!leaguesResponse?.leagues) {
                console.error('Invalid leagues response:', leaguesResponse);
                return false;
            }

            // The leagues are already transformed in the API call
            const transformedLeagues = leaguesResponse.leagues;
            console.log('Leagues to set:', transformedLeagues);

            runInAction(() => {
                this.leagues.replace(transformedLeagues);
                console.log('Leagues after setting:', this.leagues.slice());
            });

            // If we have leagues, get user info for the first/stored league
            if (transformedLeagues.length > 0) {
                const storedLeagueId = localStorage.getItem('selectedLeagueId');
                const validLeagueId = storedLeagueId && transformedLeagues.some(l => l.id === storedLeagueId)
                    ? storedLeagueId
                    : transformedLeagues[0].id;

                console.log('Setting league ID:', validLeagueId);

                // Set league ID first
                runInAction(() => {
                    this._leagueId = validLeagueId;
                });
                localStorage.setItem('selectedLeagueId', validLeagueId);

                // Set initial values from league data
                const selectedLeague = transformedLeagues.find(l => l.id === validLeagueId);
                if (selectedLeague) {
                    runInAction(() => {
                        this.budget = Number(selectedLeague.budget) || 0;
                        this.teamValue = Number(selectedLeague.teamValue) || 0;
                    });
                }

                // Fetch user info and league ranking in parallel
                const [userInfoResult, rankingResult] = await Promise.all([
                    this.fetchUserInfo(validLeagueId),
                    this.fetchLeagueRanking(validLeagueId)
                ]);

                // Update values from league ranking if available (most accurate)
                if (rankingResult?.us) {
                    const currentUserData = rankingResult.us.find(
                        user => user.i === this.userInfo?.un?.toString()
                    );
                    if (currentUserData) {
                        runInAction(() => {
                            this.teamValue = Number(currentUserData.tv) || this.teamValue || 0;
                            this.budget = Number(currentUserData.b) || this.budget || 0;
                            console.log('Updated values from ranking:', {
                                teamValue: this.teamValue,
                                budget: this.budget
                            });
                        });
                    }
                }
            }

            runInAction(() => {
                this.isInitialized = true;
            });

            return true;
        } catch (error) {
            console.error('Store initialization failed:', error);
            return false;
        }
    }

    checkAuthState() {
        const token = localStorage.getItem('token');
        const savedUser = localStorage.getItem('user');

        if (!token) {
            console.log('No token found, clearing auth state...');
            this.clearAuth();
            return false;
        }

        if (token && savedUser) {
            try {
                const user = JSON.parse(savedUser);
                runInAction(() => {
                    this.token = token;
                    this.user = user;
                    this.isAuthenticated = true;
                });
                return true;
            } catch (error) {
                console.warn('Invalid stored auth data:', error);
                this.clearAuth();
            }
        }
        return false;
    }

    clearAuth() {
        console.log('Clearing auth state...');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('selectedLeagueId');
        runInAction(() => {
            this.token = null;
            this.user = null;
            this.isAuthenticated = false;
            this.isInitialized = false;
            this.currentLeagueId = null;
            this.leagues = [];
            this.userInfo = null;
        });
        
        // Redirect to login page if we're not already there
        if (window.location.pathname !== '/login') {
            window.location.href = '/login';
        }
    }

    async openDatabase() {
        return openDB('PlayerDatabase', 2, {
            upgrade(db, oldVersion, newVersion) {
                if (!db.objectStoreNames.contains('players')) {
                    db.createObjectStore('players', { keyPath: 'id' });
                }
            },
        });
    }

    setUserInfo(info) {
        console.log('Setting user info in PlayerStore:', info);
        runInAction(() => {
            this.userInfo = {
                ...info,
                id: info.id || info.un // Use un (user number) as fallback
            };
            this.budget = info.budget || info.b || 0; // Support both budget and b properties
        });
    }

    setCurrentLeagueId(id) {
        console.log('Setting current league ID in PlayerStore:', id);
        this.currentLeagueId = id;
    }

    getUserId() {
        if (this.userInfo && this.userInfo.id) {
            console.log('Getting userId from userInfo:', this.userInfo.id);
            return this.userInfo.id;
        }
        // Try to get from localStorage as fallback
        const userString = localStorage.getItem("user");
        if (userString) {
            try {
                const userData = JSON.parse(userString);
                if (userData && userData.id) {
                    console.log('Getting userId from localStorage:', userData.id);
                    return userData.id;
                }
            } catch (error) {
                console.error('Error parsing user data from localStorage:', error);
            }
        }
        console.log('No userId found');
        return null;
    }
    setUserId(id) {
        this.userId = id;
    }

    async savePlayersToDB(players) {
        const db = await this.dbPromise;
        const tx = db.transaction('players', 'readwrite');
        const store = tx.objectStore('players');

        for (const player of players) {
            if (player) {
                await store.put(player);
            }
        }

        await tx.done;
    }

    getPositionCounts() {
        const positionMap = {
            1: 'Torwart',
            2: 'Verteidiger',
            3: 'Mittelfeldspieler',
            4: 'Stürmer',
            'GK': 'Torwart',
            'DEF': 'Verteidiger',
            'MID': 'Mittelfeldspieler',
            'FWD': 'Stürmer'
        };

        return this.players.reduce((counts, player) => {
            const mappedPosition = positionMap[player.position] || player.position;
            counts[mappedPosition] = (counts[mappedPosition] || 0) + 1;
            return counts;
        }, {
            'Torwart': 0,
            'Verteidiger': 0,
            'Mittelfeldspieler': 0,
            'Stürmer': 0
        });
    }

    get investmentLeft() {
        const teamValue = this.userInfo?.teamValue || 0;
        const budget = this.userInfo?.budget || 0;
        const realValue = teamValue + budget;
        let investment;
        if (budget < 0) {
            investment = (realValue * 0.33) + budget;
        } else {
            investment = (teamValue * 0.33) + budget;
        }
        return investment;
    }

    async initData(leagueId = this.leagueId) {
        if (!leagueId) {
            console.error('Invalid league id:', leagueId);
            return;
        }

        console.log(`Initializing data for league: ${leagueId}`);

        try {
            await this.fetchUserInfo(leagueId);
        } catch (error) {
            console.error('Error initializing data:', error);
            // Don't throw here, just log the error
        }
    }

    async setLeagueId(leagueId) {
        if (!leagueId || leagueId === this._leagueId) {
            return;
        }

        console.log('Setting league ID:', leagueId);
        
        runInAction(() => {
            this._leagueId = leagueId;
        });

        try {
            // Initialize data for the new league
            await this.initData(leagueId);
        } catch (error) {
            console.error('Error setting league ID:', error);
            throw error;
        }
    }

    // ----------------- User Related Methods -----------------
    async fetchUserInfo(leagueId) {
        try {
            this.isTokenExpired();
            const token = localStorage.getItem('token');
            if (!token || !leagueId) {
                console.error('Missing token or leagueId');
                return null;
            }
            
            console.log('Fetching user info for league:', leagueId);
            const data = await getUserInfo(token, leagueId);
            
            // Ensure we have the required user data
            if (!data || !data.un) {
                console.error('Invalid user info response:', data);
                return null;
            }

            const userInfo = {
                un: data.un,                           // user number (required for identification)
                budget: data.b || 0,                   // budget
                budgetStatus: data.bs || 0,            // budget status
                maxPlayersPerUser: data.mppu || 0,     // max players per user
                isAdmin: data.adm || false,            // is admin
                competitionId: data.cpi || '',         // competition id
                leagueName: data.lnm || '',            // league name
                teamPlayerCounts: data.tpc || [],      // team player counts array
                marketPlayerStatus: data.mpst || 0     // market player status
            };
            
            runInAction(() => {
                this.userInfo = userInfo;
                this.budget = data.b || 0;
                console.log('Updated user info:', this.userInfo);
            });

            return userInfo;
        } catch (error) {
            console.error("Error fetching user info:", error);
            return null;
        }
    }

    async fetchLeagueRanking(leagueId) {
        try {
            const ranking = await playerService.getLeagueRanking(leagueId);
            runInAction(() => {
                // Store previous ranking before updating
                this.previousLeagueRanking = this.leagueRanking || { it: [] };
                this.leagueRanking = ranking;
            });
            return ranking;
        } catch (error) {
            console.error('Error fetching league ranking:', error);
            // Return empty structure instead of throwing
            return { it: [] };
        }
    }

    renewData() {
        localStorage.removeItem('userPlayers');
        localStorage.removeItem('marketPlayers');
        this.initData(this.leagueId)

    }

    isTokenExpired() {
        const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;
        const currentTime = new Date().getTime();
        const tokenTimestamp = localStorage.getItem('token_timestamp');
        const token = localStorage.getItem('token');

        if (!token) return false;

        if (!tokenTimestamp) {
            localStorage.removeItem('token');
            localStorage.removeItem('token_timestamp');
            return true;
        }

        const tokenAge = currentTime - tokenTimestamp;
        if (tokenAge > twoDaysInMilliseconds) {
            localStorage.removeItem('token');
            localStorage.removeItem('token_timestamp');
            return true;
        }
        return false;
    };

    // ----------------- Player Related Methods -----------------
    async fetchPlayers(leagueId) {
        try {
            // First get the squad data
            const squadData = await playerService.getSquadPlayers(leagueId);
            
            // Then get enhanced data for each player
            const enhancedPlayersPromises = squadData.it.map(async squadPlayer => {
                try {
                    // Use the same method as PlayerDebugView
                    const enhancedData = await playerService.getEnhancedPlayerData(
                        leagueId, 
                        squadPlayer.i
                    );
                    
                    // Create new Player instance with enhanced data
                    return {
                        ...enhancedData,
                        id: enhancedData.id,
                        firstName: enhancedData.firstName,
                        lastName: enhancedData.lastName,
                        fullName: enhancedData.fullName,
                        shirtNumber: enhancedData.shirtNumber,
                        teamId: enhancedData.teamId,
                        teamName: enhancedData.teamName,
                        status: enhancedData.status,
                        statusText: enhancedData.statusText,
                        position: enhancedData.position,
                        totalPoints: enhancedData.totalPoints,
                        averagePoints: enhancedData.averagePoints,
                        goals: enhancedData.goals,
                        assists: enhancedData.assists,
                        marketValue: enhancedData.marketValue,
                        marketValueTrend: enhancedData.marketValueTrend,
                        fourHourTrend: enhancedData.fourHourTrend,
                        currentDay: enhancedData.currentDay,
                        redCards: enhancedData.redCards,
                        yellowCards: enhancedData.yellowCards,
                        performanceHistory: enhancedData.performanceHistory,
                        seasonPerformance: enhancedData.seasonPerformance,
                        marketStats: enhancedData.marketStats,
                        upcomingMatches: enhancedData.upcomingMatches,
                        isMarketPlayer: false
                    };
                } catch (error) {
                    console.error(`Error fetching enhanced data for player ${squadPlayer.i}:`, error);
                    return null;
                }
            });

            const enhancedPlayers = await Promise.all(enhancedPlayersPromises);
            
            runInAction(() => {
                this.players = enhancedPlayers.filter(Boolean);
            });

            console.log('Enhanced players loaded:', this.players.length);
        } catch (error) {
            console.error('Error fetching players:', error);
            runInAction(() => {
                this.players = [];
            });
        }
    }

    // Helper method to get player by ID
    getPlayerById(playerId) {
        return this.players.get(playerId);
    }

    // Helper method to get all players as array
    get playersList() {
        return Array.from(this.players.values());
    }

    // Helper method to get user's players
    get userPlayers() {
        try {
            return this.players.filter(player => player.userId === this.userId);
        } catch (error) {
            console.error('Error in userPlayers getter:', error);
            return [];
        }
    }

    // Update a single player's data
    async updatePlayerData(playerId) {
        try {
            const enhancedData = await playerService.getEnhancedPlayerData(this.leagueId, playerId);
            runInAction(() => {
                const player = new Player(enhancedData);
                this.players.set(playerId, player);
            });
        } catch (error) {
            console.error(`Error updating player ${playerId}:`, error);
        }
    }

    async getPlayerStatsFromIndexedDB(playerId) {
        return dbManager.get('playerStats', playerId);
    }

    async savePlayerStatsToIndexedDB(playerId, stats) {
        await dbManager.put('playerStats', { id: playerId, ...stats });
    }

    setPlayers(players) {
        this.players = players;
    }

    //----------------------Favorite Player Related Methods-----------------
    async fetchFavoritePlayers(leagueId = this.leagueId) {
        const cacheKey = `favorites_${leagueId}`;
        const cachedData = this.favoritePlayersCache.get(cacheKey);

        if (cachedData && Date.now() - cachedData.timestamp < 5 * 60 * 1000) { // 5 minutes cache
            runInAction(() => {
                this.favoritePlayers = cachedData.players;
            });
            return;
        }

        try {
            const data = await getFavoritePlayers(leagueId);
            console.log('Received favorite players data:', data);

            runInAction(() => {
                if (data && data.p && Array.isArray(data.p)) {
                    this.favoritePlayers = data.p.map(playerData => new Player(playerData, this.currentMatchDay));
                    this.favoritePlayersCache.set(cacheKey, {
                        players: this.favoritePlayers,
                        timestamp: Date.now()
                    });
                } else {
                    console.error('Unexpected data structure for favorite players:', data);
                    this.favoritePlayers = [];
                }
            });
        } catch (error) {
            console.error("Error fetching favorite players:", error);
            runInAction(() => {
                this.favoritePlayers = [];
            });
        }
    }

    async addPlayerToFavorites(playerId) {
        try {
            const token = localStorage.getItem('token');

        } catch (error) {
            console.error('Failed to add player to favorites:', error);
        }
    }


    async removePlayerFromFavorites(playerId) {
        try {
            const token = localStorage.getItem('token');
            const response = await removeFavoritePlayer(playerId, token);
        } catch (error) {
            console.error('Failed to remove player from favorites:', error);
        }
    }



    // ----------------- Market Player Related Methods -----------------
    async fetchMarketPlayers(leagueId) {
        try {
            const marketData = await playerService.getMarketPlayers(leagueId);
            
            const enhancedMarketPlayersPromises = marketData.items.map(async marketPlayer => {
                try {
                    const enhancedData = await playerService.getEnhancedPlayerData(
                        leagueId,
                        marketPlayer.id
                    );

                    return {
                        ...enhancedData,
                        isMarketPlayer: true,
                        price: marketPlayer.price,
                        expiryDate: marketPlayer.expiry,
                        seller: marketPlayer.u
                    };
                } catch (error) {
                    console.error(`Error fetching enhanced market player data ${marketPlayer.id}:`, error);
                    return null;
                }
            });

            const enhancedMarketPlayers = await Promise.all(enhancedMarketPlayersPromises);
            
            runInAction(() => {
                this.marketPlayers = enhancedMarketPlayers.filter(Boolean);
            });

            console.log('Enhanced market players loaded:', this.marketPlayers.length);
        } catch (error) {
            console.error('Error fetching market players:', error);
            runInAction(() => {
                this.marketPlayers = [];
            });
        }
    }
    
    setMarketPlayers(players) {
        try {
            const marketPlayers = players
                .filter(player => {
                    const isUserPlayer = this.players.some(p => p.id === player.id);
                    return !isUserPlayer;
                })
                .map(playerData => new Player(playerData))
                .filter(Boolean);

            runInAction(() => {
                this.marketPlayers.replace(marketPlayers);
            });
        } catch (error) {
            console.error('Error setting market players:', error);
            this.marketPlayers.replace([]);
        }
    }
    shouldFetchStatsForMarketPlayer(playerData) {
        return Math.random() < 0.1;
    }
    calculateFitPercentage(player) {
        if (player && typeof player.calculateFitPercentage === 'function') {
            return player.calculateFitPercentage(this);
        } else {
            console.warn(`Player ${player?.name || 'Unknown'} does not have calculateFitPercentage method`);
            return 0; // or some default value
        }
    }


    compareWithTeamPlayers(player) {
        const teamPositionPlayers = this.players.filter(p => p.position === player.position);
        if (teamPositionPlayers.length === 0) return { isBest: true, difference: player.averagePoints };
        
        const bestTeamPlayer = teamPositionPlayers.reduce((best, current) => 
            current.averagePoints > best.averagePoints ? current : best
        );
        
        return {
            isBest: player.averagePoints >= bestTeamPlayer.averagePoints,
            difference: player.averagePoints - bestTeamPlayer.averagePoints
        };
    }

    getPositionRanking(player) {
        const allPositionPlayers = [
            ...this.players,
            ...this.marketPlayers,
            ...this.allPlayers
        ].filter(p => p.position === player.position);

        const uniquePlayers = Array.from(new Set(allPositionPlayers.map(p => p.id)))
            .map(id => allPositionPlayers.find(p => p.id === id));

        const sortedPlayers = uniquePlayers.sort((a, b) => b.averagePoints - a.averagePoints);

        const rank = sortedPlayers.findIndex(p => p.id === player.id) + 1;
        return {
            rank: rank,
            total: sortedPlayers.length
        };
    }

    compareWithCurrentPlayers(player) {
        const allPositionPlayers = [...this.players, ...this.marketPlayers]
            .filter(p => p.position === player.position && p.totalPoints > 0);
        
        if (allPositionPlayers.length === 0) return { averagePointsDiff: 0, percentile: 100 };
        
        const averagePoints = allPositionPlayers.reduce((sum, p) => sum + p.averagePoints, 0) / allPositionPlayers.length;
        const sortedPoints = allPositionPlayers.map(p => p.averagePoints).sort((a, b) => a - b);
        const playerIndex = sortedPoints.findIndex(points => points >= player.averagePoints);
        const percentile = ((allPositionPlayers.length - playerIndex) / allPositionPlayers.length) * 100;
        
        return {
            averagePointsDiff: player.averagePoints - averagePoints || 0,
            percentile: Math.round(percentile) || 0
        };
    }

    // ----------------- League Related Methods -----------------
    getLeagues() {
        return this.leagues;
    }

    set leagueId(id) {
        this._leagueId = id;
        this.initData(id);
    }

    get leagueId() {
        return this._leagueId;
    }
    translatePosition(positionNumber) {
        switch (Number(positionNumber)) {
            case 1: return "Torwart";
            case 2: return "Verteidiger";
            case 3: return "Mittelfeldspieler";
            case 4: return "Stürmer";
            default:
                console.error("Unknown position number:", positionNumber);
                return "Unbekannt";
        }
    }

    // ----------------- Expiry Related Methods -----------------
    formatExpiry(seconds) {
        let days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        let hrs = Math.floor(seconds / 3600);
        seconds -= hrs * 3600;
        let mins = Math.floor(seconds / 60);

        return [
            days > 0 ? `${days} days` : null,
            hrs > 0 ? `${hrs} hours` : null,
            mins > 0 ? `${mins} minutes` : null
        ].filter(Boolean).join(' ').trim();
    }

    get isExpiryLessThanOneHour() {
        return this.expire < 3600;
    }

    // ----------------- Initialization Methods -----------------


    async fetchRecentSales() {
        try {
            // If getRecentSales doesn't exist, use getLeagueFeed as a fallback
            const recentSales = this.getRecentSales 
                ? await this.getRecentSales(this.leagueId)
                : (await getLeagueFeed(this.leagueId)).items.filter(item => item.type === 12);

            runInAction(() => {
                this.recentSales = recentSales.map(sale => {
                    const player = new Player(sale, this.currentMatchDay, true, this);
                    return {
                        ...sale,
                        player
                    };
                });
            });
            await this.calculateOverpayments();
        } catch (error) {
            console.warn("Error fetching recent sales:", error);
            // Set an empty array to prevent further errors
            runInAction(() => {
                this.recentSales = [];
            });
        }
    }

    async calculateOverpayments() {
        if (!this.leagueId) {
            console.error('LeagueId is not set in PlayerStore');
            return;
        }

        for (const sale of this.recentSales) {
            if (sale.player && typeof sale.player.fetchHistoricalMarketValues === 'function') {
                try {
                    await sale.player.fetchHistoricalMarketValues();
                } catch (error) {
                    console.error(`Error fetching historical market values for player ${sale.player.id}:`, error);
                }
            } else {
                console.warn(`Player ${sale.player?.name || 'Unknown'} does not have fetchHistoricalMarketValues method`);
            }
        }
    }

    async estimateSalePrice(player) {
        if (!player || typeof player !== 'object' || !player.id) {
         //   console.warn(`Invalid player object for price estimation:`, player);
            return null;
        }

        const playerId = player.id;

        const similarSales = await this.fetchSimilarPlayerSales(player.marketValue);
        
        if (similarSales.length === 0) {
        //    console.warn(`No similar sales found for player ${playerId}`);
            return player.marketValue * 1.05;
        }

        const avgSalePercentage = similarSales.reduce((sum, sale) => sum + (sale.salePrice / sale.marketValue), 0) / similarSales.length;

        let adjustedPercentage = avgSalePercentage;
        if (player.form === 'Gute Form') adjustedPercentage *= 1.05;
        if (player.form === 'Sehr gute Form') adjustedPercentage *= 1.1;
        
        const recentTrend = await this.getRecentMarketTrend(playerId);
        adjustedPercentage *= (1 + recentTrend);

        let estimatedPrice = player.marketValue * adjustedPercentage;

        estimatedPrice = Math.max(estimatedPrice, player.marketValue * 1.02);

        console.log(`Estimated sale price for player ${playerId}: ${estimatedPrice.toFixed(2)}`);

        return Math.round(estimatedPrice);
    }

    async fetchSimilarPlayerSales(marketValue) {
        const sales = this.recentSales
            .filter(sale => Math.abs(sale.meta.mv - marketValue) / marketValue < 0.2)
            .map(sale => ({ salePrice: sale.meta.p, marketValue: sale.meta.mv }));

        if (sales.length === 0) {
           // console.warn(`No similar sales found for market value ${marketValue}`);
        }

        return sales;
    }

    async getRecentMarketTrend(playerId) {
        const marketValueHistory = await this.fetchPlayerMarketValueHistory(playerId);
        if (!marketValueHistory || marketValueHistory.length < 2) return 0;

        const recentHistory = marketValueHistory.slice(0, 7);
        const oldestValue = recentHistory[recentHistory.length - 1].m;
        const newestValue = recentHistory[0].m;
        return (newestValue - oldestValue) / oldestValue;
    }

    async fetchPlayerMarketValueHistory(playerId) {
        try {
            const history = await getPlayerValueAtDate(this.leagueId, playerId);
            return history.sort((a, b) => new Date(b.d) - new Date(a.d));
        } catch (error) {
            console.error(`Error fetching market value history for player ${playerId}:`, error);
            return [];
        }
    }

    async fetchAllPlayers(leagueId) {
        const knownPlayerIds = getKnownPlayerIds();
        let fetchedPlayers = [];

        const batchSize = 20;

        for (let i = 0; i < knownPlayerIds.length; i += batchSize) {
            const batchIds = knownPlayerIds.slice(i, i + batchSize);

            const detailedPlayersPromises = batchIds.map(id =>
                Promise.all([
                    this.getPlayerStats(id),
                    getPlayerInfo(leagueId, this.userId)
                ]).then(([playerStats, playerDetailedInfo]) => {
                    return { id, ...playerDetailedInfo, ...playerStats };
                }).catch(err => {
                    console.error(`Error fetching data for Player ID ${id}:`, err);
                    return null;
                })
            );

            try {
                const detailedPlayers = await Promise.all(detailedPlayersPromises);
                fetchedPlayers = fetchedPlayers.concat(detailedPlayers);
                await this.savePlayersToDB(fetchedPlayers.filter(Boolean));
                this.setPlayers(fetchedPlayers.filter(Boolean));
            } catch (error) {
                console.error('Failed to fetch detailed information for player batch:', error);
            }
        }

        return fetchedPlayers;
    }

    calculateIncrease(player, days) {
        if (player.historicalData && player.historicalData.length > days) {
            const latestValue = player.historicalData[0].m;
            const previousValue = player.historicalData[days].m;
            return latestValue - previousValue;
        }
        return null;
    }

    getTopPlayers = (players) => {
        return players
            .map(player => {
                const increase = this.calculateIncrease(player, 1);
                return { ...player, marketValueIncrease: increase };
            })
            .sort((a, b) => b.marketValueIncrease - a.marketValueIncrease)
            .slice(0, 25);
    };


    async getPlayers(leagueId) {
        const savedAt = await localStorage.getItem('savedAt');
        if (savedAt) {
            const savedDate = new Date(savedAt);
            const currentDate = new Date();
            const expiryDate = new Date(savedDate.getTime() + 10 * 60 * 60 * 1000);

            if (currentDate < expiryDate) {
                return this.getPlayersFromDB();
            }
        }

        return this.fetchAllPlayers(leagueId);
    }
    updatePlayersWithMarketData() {
        const ligaInsiderMapped = this.mapLigaInsiderData([...this.marketWinners, ...this.marketLoosers, ...this.marketAll]);

        this.players.forEach(player => {
            const playerKey = player.name
            const matchingLiPlayer = ligaInsiderMapped.find(li => li.key === playerKey);

            if (matchingLiPlayer) {
                player.marketValueLigaInsider = matchingLiPlayer.marketValue;
            } else {
                console.log(`No matching player found for key ${playerKey}`);
            }

            player.marketWinnerRank = this.findRank(this.marketWinners, playerKey);
            player.marketLooserRank = this.findRank(this.marketLoosers, playerKey);
            player.marketAllRank = this.findRank(this.marketAll, playerKey);

        });
    }

    mapLigaInsiderData(data) {
        return data.map(liPlayer => {
            const key = liPlayer.name.replace(/\s+/g, '').toLowerCase();
            return { ...liPlayer, key };
        });
    }

    findRank(array, key) {
        const rank = array.findIndex(li => li.key === key) + 1;
        return rank === 0 ? null : rank;
    }
    getPlayerTraits(playerId, fromMarket = false) {
        if (!playerId) return [];
        const arrayToSearch = fromMarket ? this.marketPlayers : this.players;
        this.fetchLeagueStats(this.leagueId);

        const player = arrayToSearch.find(p => p.id === playerId);
        return player ? player.getTraits() : [];
    }

    async fetchLeagueStats(leagueId) {
        try {
            const leagueStats = await getLeagueStats(leagueId);
            runInAction(() => {
                this.currentMatchDay = leagueStats.currentDay;

            });
        } catch (error) {
            console.error('Error fetching league stats:', error);
        }
    }

    getTeamPlayersCount(teamId) {
        return this.players.filter(player => player.teamId === teamId).length;
    }

    setCachedPlayerData(playerId, data) {
        this.calculatedPlayerDataCache.set(playerId, data);
    }

    getCachedPlayerData(playerId) {
        return this.calculatedPlayerDataCache.get(playerId);
    }

    clearCache() {
        this.calculatedPlayerDataCache.clear();
    }

    async fetchPlayerById(playerId) {
        // Implement this method to fetch player data from your API
        // Return a Player object or null if not found
    }

    async getPlayerStats(leagueId, playerId) {
        if (!playerId) {
            console.warn('Attempted to fetch stats for undefined player');
            return null;
        }
        try {
            const stats = await getPlayerStats(leagueId, playerId.toString());
            return stats;
        } catch (error) {
            console.error(`Error fetching stats for player ${playerId}:`, error);
            return null;
        }
    }

    async getPlayerPoints(playerId) {
        try {
            const points = await getPlayerPoints(playerId);
            return points;
        } catch (error) {
            console.error(`Error fetching points for player ${playerId}:`, error);
            return null;
        }
    }

    getTeamDiversity(player) {
        return this.players.filter(p => p.teamId === player.teamId).length;
    }

    getPositionOccupancy(position) {
        return this.players.filter(p => p.position === position).length;
    }

    getPerformanceRank(player) {
        const sortedPlayers = [...this.players].sort((a, b) => b.averagePoints - a.averagePoints);
        const rank = sortedPlayers.findIndex(p => p.id === player.id) + 1;
        return `${rank}/${sortedPlayers.length}`;
    }

    setLeagueInfo(leagueData) {
        this.leagueInfo = {
            id: leagueData.id,
            name: leagueData.name,
            pl: leagueData.pl,
            mpst: leagueData.mpst,
            // Add other relevant fields
        };
    }

    async fetchLeagueInfo() {
        try {
            const response = await getLeagueUserInfo();
            if (response.leagues && response.leagues.length > 0) {
                runInAction(() => {
                    this.leagueInfo = {
                        id: response.leagues[0].id,
                        name: response.leagues[0].name,
                        pl: response.leagues[0].pl,
                        mpst: response.leagues[0].mpst,
                        // Add other relevant fields
                    };
                });
            }
        } catch (error) {
            console.error('Error fetching league info:', error);
        }
    }

    setBudget(value) {
        this.budget = value;
    }

    updateBudget(value) {
        this.budget = value;
    }

    async subscribeToPlayerNotifications(playerId, token) {
        try {
            console.log(`🔔 Attempting to subscribe to notifications for player ${playerId}`);
            
            const subscribeFunction = httpsCallable(functions, 'subscribeToPlayerNotifications');
            
            const result = await subscribeFunction({ playerId, token });
            console.log('✅ Notification subscription successful:', result.data);
            
            return result.data;
        } catch (error) {
            console.error('❌ Error subscribing to notifications:', error);
            throw error;
        }
    }

    get currentBudget() {
        return Number(this.budget) || 0;
    }

    get currentTeamValue() {
        return Number(this.teamValue) || 0;
    }

    get realValue() {
        return this.currentTeamValue + this.currentBudget;
    }

    getCachedData(key) {
        const cached = this.cache.get(key);
        if (cached && Date.now() - cached.timestamp < this.cacheTimeout) {
            return cached.data;
        }
        return null;
    }

    setCachedData(key, data) {
        this.cache.set(key, {
            data,
            timestamp: Date.now()
        });
    }
}

export const playerStoreInstance = new PlayerStore();

export const PlayerStoreContext = React.createContext(playerStoreInstance);
export const usePlayerStore = () => React.useContext(PlayerStoreContext);
export default PlayerStore;