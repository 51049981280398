import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import { playerStoreInstance } from '../stores/PlayerStore';
import { analyzeTeamData, isAIAvailable } from '../api/gemini';
import { marked } from 'marked';
import { playerService } from '../api/playerService';

// Update number formatting utilities
const formatNumber = (number, options = {}) => {
    if (number === undefined || number === null) return '-';
    
    const {
        minimumFractionDigits = 0,
        maximumFractionDigits = 0,
        style = 'decimal',
        currency = 'EUR',
        notation = 'standard',
        useGrouping = false // This will prevent the thousand separator
    } = options;

    const formatted = new Intl.NumberFormat('de-DE', {
        style,
        currency,
        notation,
        minimumFractionDigits,
        maximumFractionDigits,
        useGrouping
    }).format(number);

    // For points, we want to format like "3.234" instead of "3234"
    if (style === 'decimal' && !useGrouping && number >= 1000) {
        const parts = formatted.split('');
        parts.splice(-3, 0, '.');
        return parts.join('');
    }

    return formatted;
};

const formatCurrency = (amount) => formatNumber(amount, { 
    style: 'currency', 
    currency: 'EUR',
    useGrouping: true 
});

const formatPercent = (value) => {
    // Format like "12,79 %" with exactly 2 decimal places
    const formatted = formatNumber(value * 100, { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true 
    });
    return formatted.replace('.', ',') + ' %';
};

const AIAnalytics = ({ onClose, onLoadingChange }) => {
    const [analysis, setAnalysis] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(true);
    const [squadData, setSquadData] = useState(null);

    // Add handleRegenerate function
    const handleRegenerate = async () => {
        setAnalysis(null);
        setError(null);
        await analyzeData();
    };

    // Start analysis immediately when component mounts
    useEffect(() => {
        if (!analysis && !loading && !error && isAIAvailable()) {
            console.log('Starting initial analysis...');
            analyzeData();
        }
    }, [analysis, loading, error]);

    // Check if AI is available before mounting
    useEffect(() => {
        if (!isAIAvailable()) {
            onClose();
        }
    }, [onClose]);

    // Notify parent component of loading state changes
    useEffect(() => {
        if (onLoadingChange) {
            onLoadingChange(loading);
        }
    }, [loading, onLoadingChange]);

    const systemPrompt = `Analysiere die Daten präzise und zahlenbasiert. Nutze das vorgegebene Format für die Ausgabe. Fokussiere auf:

1. Teamübersicht:
   - Gesamtpunkte und Durchschnitt
   - Teambalance (Verteilung der Positionen)
   - Marktwert und Entwicklung

2. Stärken:
   - Top-Scorer und ihre Performance
   - Spieler mit bester Entwicklung
   - Besonders effiziente Spieler (Punkte/Marktwert)

3. Schwächen:
   - Unterperformer
   - Positionelle Schwächen
   - Risikospieler

4. Ligaanalyse:
   - Tabellenposition und Punkteabstand
   - Vergleich mit anderen Teams
   - Entwicklungstrend in der Liga

5. Marktempfehlungen:
   - Beste Kaufgelegenheiten
   - Verkaufsempfehlungen
   - Marktwertprognosen

Format:
{
    "team_analysis": {
        "overview": "### Teamübersicht\\n\\n**Gesamtpunkte**: [Zahl] | **Durchschnitt**: [Zahl] | **Trend**: [↑/↓]\\n\\n**Teambalance**: [Details zur Positionsverteilung]\\n\\n**Marktwert**: [Zahl]€ ([±Zahl]€ heute)",
        "strengths": [
            {
                "player": "**[Spielername]**",
                "points": [Punktzahl als ganze Zahl],
                "impact": [Prozentuale Abweichung vom Durchschnitt als Dezimalzahl, z.B. 12.79 für +12,79%],
                "description": "[Detaillierte Stärken mit Toren, Assists, Karten]"
            }
        ],
        "weaknesses": [
            {
                "player": "**[Spielername]**",
                "points": [Punktzahl als ganze Zahl],
                "impact": [Prozentuale Abweichung vom Durchschnitt als Dezimalzahl, z.B. -15.43 für -15,43%],
                "description": "[Detaillierte Schwächen mit konkreten Zahlen]"
            }
        ]
    },
    "league_analysis": {
        "position": {
            "current": [Aktuelle Position],
            "points": [Punktzahl],
            "trend": [↑/↓],
            "description": "[Beschreibung der Ligasituation]"
        },
        "competitors": [
            {
                "team": "[Teamname]",
                "position": [Position],
                "points": [Punktzahl],
                "difference": [Punktedifferenz],
                "description": "[Vergleich und Analyse]"
            }
        ]
    },
    "market_recommendations": {
        "buy": [
            {
                "player": "**[Spielername]**",
                "current_value": [Aktueller Marktwert],
                "expected_value": [Erwarteter Marktwert],
                "roi_potential": [ROI als Dezimalzahl],
                "reason": "[Begründung für Kaufempfehlung]"
            }
        ],
        "sell": [
            {
                "player": "**[Spielername]**",
                "current_value": [Aktueller Marktwert],
                "expected_value": [Erwarteter Marktwert],
                "reason": "[Begründung für Verkaufsempfehlung]"
            }
        ]
    }
}`;

    const collectData = async () => {
        try {
            const leagueId = playerStoreInstance.leagueId;
            
            // Fetch all required data in parallel
            const [squadData, marketData, userInfo, leagueRankingResponse] = await Promise.all([
                playerService.getSquadPlayers(leagueId),
                playerService.getMarketPlayers(leagueId),
                playerStoreInstance.fetchUserInfo(leagueId),
                playerService.getLeagueRanking(leagueId)
            ]);

            // Debug logging for raw responses
            console.log('=== Raw Response Data ===');
            console.log('Market Data:', {
                raw: marketData,
                items: marketData.items?.map(item => ({
                    id: item.id,
                    fullName: item.firstName + ' ' + item.lastName,
                    price: item.price,
                    marketValue: item.marketValue,
                    position: item.position,
                    teamId: item.teamId,
                    expireInSeconds: item.expireInSeconds,
                    status: item.status
                }))
            });

            console.log('League Ranking:', {
                raw: leagueRankingResponse,
                rankings: leagueRankingResponse?.us?.map(user => ({
                    id: user.i,
                    name: user.n,
                    points: user.sp,
                    teamValue: user.tv,
                    position: user.p
                }))
            });

            // Ensure we have valid user info
            if (!userInfo || !userInfo.un) {
                throw new Error('User information is not available');
            }

            // Get rankings from the response and sort by season points
            const rankings = leagueRankingResponse?.us || [];
            const sortedRankings = [...rankings].sort((a, b) => b.sp - a.sp);

            // Find current user's position and stats
            const userRanking = sortedRankings.find(rank => rank.i === userInfo.un);
            const userPosition = sortedRankings.findIndex(rank => rank.i === userInfo.un) + 1;

            // Get the previous ranking to determine trend
            const previousRanking = playerStoreInstance.previousLeagueRanking?.us || [];
            const previousSortedRankings = [...previousRanking].sort((a, b) => b.sp - a.sp);
            const previousUserPosition = previousSortedRankings.findIndex(rank => rank.i === userInfo.un) + 1;

            // Calculate trend
            const trend = previousUserPosition > userPosition ? '↑' : previousUserPosition < userPosition ? '↓' : '→';

            // Find closest competitors (3 above and 3 below)
            const userIndex = sortedRankings.findIndex(rank => rank.i === userInfo.un);
            const competitors = [];
            
            // Add competitors above and below
            for (let i = Math.max(0, userIndex - 3); i < userIndex; i++) {
                const competitor = sortedRankings[i];
                competitors.push({
                    team: competitor.n,
                    position: i + 1,
                    points: competitor.sp,
                    difference: competitor.sp - userRanking.sp,
                    description: `${competitor.n} liegt ${Math.abs(competitor.sp - userRanking.sp)} Punkte vor dir.`
                });
            }
            for (let i = userIndex + 1; i < Math.min(sortedRankings.length, userIndex + 4); i++) {
                const competitor = sortedRankings[i];
                competitors.push({
                    team: competitor.n,
                    position: i + 1,
                    points: competitor.sp,
                    difference: competitor.sp - userRanking.sp,
                    description: `${competitor.n} liegt ${Math.abs(competitor.sp - userRanking.sp)} Punkte hinter dir.`
                });
            }

            // Create league stats object
            const leagueStats = {
                position: {
                    current: userPosition,
                    points: userRanking?.sp || 0,
                    trend: trend,
                    description: `Aktuell auf Platz ${userPosition} von ${sortedRankings.length} Teams mit ${formatNumber(userRanking?.sp || 0)} Punkten.`
                },
                competitors: competitors
            };

            // Get enhanced data for all squad players
            const enhancedPlayersPromises = squadData.it.map(player => 
                playerService.getEnhancedPlayerData(leagueId, player.i)
            );
            const enhancedPlayers = await Promise.all(enhancedPlayersPromises);

            // Get enhanced data for market players
            const marketPlayersPromises = marketData.items
                .filter(player => {
                    // Filter out players we already own
                    const isOwnedPlayer = enhancedPlayers.some(squadPlayer => squadPlayer.id === player.id);
                    // Include players slightly above budget (10% margin)
                    const isWithinBudget = player.price <= playerStoreInstance.currentBudget * 1.1;
                    return !isOwnedPlayer && isWithinBudget;
                })
                .map(player => playerService.getEnhancedPlayerData(leagueId, player.id));
            const enhancedMarketPlayers = await Promise.all(marketPlayersPromises);

            // Debug logging for processed data
            console.log('=== Processed Data ===');
            console.log('Market Opportunities:', enhancedMarketPlayers.map(player => ({
                id: player.id,
                name: player.fullName,
                price: player.price,
                marketValue: player.marketValue,
                averagePoints: player.totalPoints / (player.gamesPlayed || 1),
                position: player.position,
                expireInSeconds: player.expireInSeconds,
                stats: {
                    goals: player.goals,
                    assists: player.assists,
                    gamesPlayed: player.gamesPlayed,
                    totalPoints: player.totalPoints
                }
            })));

            console.log('League Analysis:', {
                userPosition,
                userPoints: userRanking?.sp,
                trend,
                competitors: competitors.map(comp => ({
                    team: comp.team,
                    position: comp.position,
                    points: comp.points,
                    difference: comp.difference
                }))
            });

            // Calculate team statistics
            const teamStats = enhancedPlayers.reduce((stats, player) => {
                stats.totalPoints += player.totalPoints || 0;
                stats.totalValue += player.marketValue || 0;
                stats.averagePoints += player.averagePoints || 0;
                stats.totalGoals += player.goals || 0;
                stats.totalAssists += player.assists || 0;
                stats.valueGainLoss += player.marketStats?.marketValueGainLoss || 0;
                stats.positions[player.position] = (stats.positions[player.position] || 0) + 1;
                return stats;
            }, { 
                totalPoints: 0, 
                totalValue: 0, 
                averagePoints: 0, 
                totalGoals: 0, 
                totalAssists: 0,
                valueGainLoss: 0,
                positions: {}
            });

            // Calculate market opportunities from market players
            const marketOpportunities = enhancedMarketPlayers
                .map(player => {
                    const averagePointsPerGame = player.totalPoints / (player.gamesPlayed || 1);
                    const expectedValue = averagePointsPerGame * 100000 * (player.gamesPlayed || 1);
                    const currentValue = player.price || player.marketValue;
                    const roiPotential = (expectedValue - currentValue) / currentValue;

                    return {
                        id: player.id,
                        name: player.fullName,
                        currentValue,
                        expectedValue,
                        roiPotential,
                        averagePoints: averagePointsPerGame,
                        position: player.position,
                        team: player.team,
                        expireInSeconds: player.expireInSeconds,
                        stats: {
                            goals: player.goals,
                            assists: player.assists,
                            yellowCards: player.yellowCards,
                            redCards: player.redCards,
                            minutesPlayed: player.minutesPlayed,
                            gamesPlayed: player.gamesPlayed,
                            totalPoints: player.totalPoints
                        }
                    };
                })
                .sort((a, b) => b.roiPotential - a.roiPotential);

            // Identify sell candidates from squad players
            const sellCandidates = enhancedPlayers
                .map(player => {
                    const averagePointsPerGame = player.totalPoints / (player.gamesPlayed || 1);
                    const expectedValue = averagePointsPerGame * 100000 * (player.gamesPlayed || 1);
                    const currentValue = player.marketValue;
                    const roiPotential = (expectedValue - currentValue) / currentValue;

                    return {
                        id: player.id,
                        name: player.fullName,
                        currentValue,
                        expectedValue,
                        roiPotential,
                        averagePoints: averagePointsPerGame,
                        position: player.position,
                        team: player.team,
                        stats: {
                            goals: player.goals,
                            assists: player.assists,
                            yellowCards: player.yellowCards,
                            redCards: player.redCards,
                            minutesPlayed: player.minutesPlayed,
                            gamesPlayed: player.gamesPlayed,
                            totalPoints: player.totalPoints
                        }
                    };
                })
                .filter(player => player.roiPotential < -0.1)
                .sort((a, b) => a.roiPotential - b.roiPotential);

            // Debug final data structure being sent to AI
            console.log('=== Final Data Structure for AI ===', {
                team_stats: teamStats,
                league_stats: leagueStats,
                market_opportunities: marketOpportunities.slice(0, 5),
                sell_candidates: sellCandidates.slice(0, 5)
            });

            return {
                team_stats: teamStats,
                league_stats: leagueStats,
                market_opportunities: marketOpportunities.slice(0, 5),
                sell_candidates: sellCandidates.slice(0, 5),
                user_info: userInfo,
                current_budget: playerStoreInstance.currentBudget,
                team_value: playerStoreInstance.currentTeamValue,
                real_value: playerStoreInstance.realValue,
                squad: enhancedPlayers,
                league_id: playerStoreInstance.leagueId,
                total_players: enhancedPlayers.length,
                max_players: playerStoreInstance.userInfo?.mppu || 0,
                league_name: playerStoreInstance.userInfo?.lnm || '',
                league_status: playerStoreInstance.userInfo?.bs || 0,
                investment_left: (playerStoreInstance.currentBudget < 0) 
                    ? (playerStoreInstance.realValue * 0.33) + playerStoreInstance.currentBudget
                    : (playerStoreInstance.currentTeamValue * 0.33) + playerStoreInstance.currentBudget
            };
        } catch (error) {
            console.error('Error collecting data:', error);
            throw error;
        }
    };

    const analyzeData = async () => {
        setLoading(true);
        setError(null);

        try {
            const data = await collectData();
            
            // Log the data being sent to Gemini
            console.log('Data being sent to Gemini:', JSON.stringify(data, null, 2));
            
            const analysisResult = await analyzeTeamData(data, systemPrompt);
            
            // Store squad data for player cards
            setSquadData(data.squad);
            
            // Log the raw analysis result
            console.log('Raw AI Analysis Result:', {
                hasTeamAnalysis: !!analysisResult?.team_analysis,
                hasLeagueAnalysis: !!analysisResult?.league_analysis,
                hasMarketRecommendations: !!analysisResult?.market_recommendations,
                rawResult: analysisResult
            });

            // Validate analysis result structure with detailed logging
            if (!analysisResult?.team_analysis) {
                console.error('Missing team_analysis in result:', analysisResult);
                throw new Error('Invalid analysis result structure');
            }

            // Validate required sections with detailed logging
            const { overview, strengths, weaknesses } = analysisResult.team_analysis;
            console.log('Validating sections:', {
                hasOverview: !!overview,
                strengthsLength: strengths?.length,
                weaknessesLength: weaknesses?.length,
                strengthsType: Array.isArray(strengths) ? 'array' : typeof strengths,
                weaknessesType: Array.isArray(weaknesses) ? 'array' : typeof weaknesses
            });

            if (!overview || !Array.isArray(strengths) || !Array.isArray(weaknesses)) {
                throw new Error('Missing required analysis sections');
            }

            // Enhanced player data validation
            const validatePlayerData = (item, index, type) => {
                const validationResults = {
                    isValid: true,
                    errors: []
                };

                if (!item || typeof item !== 'object') {
                    validationResults.errors.push(`Invalid ${type} item at index ${index}`);
                    validationResults.isValid = false;
                    return validationResults;
                }

                // Check player name
                if (!item.player || typeof item.player !== 'string') {
                    validationResults.errors.push(`Missing or invalid player name in ${type} at index ${index}`);
                    validationResults.isValid = false;
                }

                // Check points
                if (typeof item.points !== 'number' || isNaN(item.points)) {
                    validationResults.errors.push(`Missing or invalid points in ${type} at index ${index}`);
                    validationResults.isValid = false;
                }

                // Check impact
                if (typeof item.impact !== 'number' || isNaN(item.impact)) {
                    validationResults.errors.push(`Missing or invalid impact in ${type} at index ${index}`);
                    validationResults.isValid = false;
                }

                // Check description
                if (!item.description || typeof item.description !== 'string') {
                    validationResults.errors.push(`Missing or invalid description in ${type} at index ${index}`);
                    validationResults.isValid = false;
                }

                return validationResults;
            };

            // Validate and log results for strengths and weaknesses
            const strengthsValidation = strengths.map((item, index) => validatePlayerData(item, index, 'strength'));
            const weaknessesValidation = weaknesses.map((item, index) => validatePlayerData(item, index, 'weakness'));

            console.log('Player Data Validation Results:', {
                strengths: strengthsValidation,
                weaknesses: weaknessesValidation
            });

            // Filter valid items and log the results
            const validatedStrengths = strengths.filter((_, index) => strengthsValidation[index].isValid);
            const validatedWeaknesses = weaknesses.filter((_, index) => weaknessesValidation[index].isValid);

            console.log('Validated Data Counts:', {
                originalStrengths: strengths.length,
                originalWeaknesses: weaknesses.length,
                validStrengths: validatedStrengths.length,
                validWeaknesses: validatedWeaknesses.length
            });

            if (validatedStrengths.length === 0 && validatedWeaknesses.length === 0) {
                console.error('No valid player data after validation');
                throw new Error('No valid player data found');
            }

            // Log the final analysis object being set
            const finalAnalysis = {
                ...analysisResult,
                team_analysis: {
                    ...analysisResult.team_analysis,
                    strengths: validatedStrengths,
                    weaknesses: validatedWeaknesses
                }
            };
            console.log('Final Analysis Object:', finalAnalysis);

            // Set the analysis result with validated data
            setAnalysis(finalAnalysis);
        } catch (error) {
            console.error('Analysis error:', error);
            setError(error.message === 'Invalid analysis result structure' || 
                    error.message === 'Missing required analysis sections' ||
                    error.message === 'No valid player data found'
                ? 'Die KI-Analyse konnte nicht korrekt verarbeitet werden. Bitte versuche es erneut.'
                : 'Fehler bei der Analyse. Bitte versuche es erneut.');
        } finally {
            setLoading(false);
        }
    };

    // Update the style section
    const styles = `
        .player-strength-card {
            background: rgba(17, 24, 39, 0.7);
            border-radius: 0.75rem;
            padding: 1.25rem;
            margin: 0.75rem 0;
            border: 1px solid rgba(96, 165, 250, 0.2);
            transition: all 0.2s ease-in-out;
        }

        .player-strength-card:hover {
            border-color: rgba(249, 115, 22, 0.5);
            transform: translateY(-2px);
        }

        .player-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            gap: 1rem;
        }

        .player-info {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            flex: 1;
            min-width: 0;
        }

        .player-avatar {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            border: 2px solid rgba(249, 115, 22, 0.5);
            background: rgba(17, 24, 39, 0.5);
            flex-shrink: 0;
        }

        .team-logo {
            width: 1.25rem;
            height: 1.25rem;
            flex-shrink: 0;
        }

        .player-name {
            color: #60a5fa;
            font-weight: 600;
            font-size: 0.875rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .player-stats {
            display: flex;
            align-items: center;
            gap: 1rem;
            flex-shrink: 0;
        }

        .player-description {
            color: #9ca3af;
            font-size: 0.75rem;
            margin-top: 0.5rem;
            padding-top: 0.5rem;
        }

        .stat-value {
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
        }

        .stat-value.points {
            color: #f97316;
            background: rgba(249, 115, 22, 0.1);
            border-color: rgba(249, 115, 22, 0.2);
            font-weight: 500;
        }

        .stat-value.trend-up {
            color: #34d399;
            background: rgba(52, 211, 153, 0.1);
            border-color: rgba(52, 211, 153, 0.2);
            font-weight: 500;
        }

        .stat-value.trend-down {
            color: #f87171;
            background: rgba(248, 113, 113, 0.1);
            border-color: rgba(248, 113, 113, 0.2);
            font-weight: 500;
        }

        .section-title {
            color: #f97316;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0.75rem;
            padding-bottom: 0.375rem;
            border-bottom: 1px solid rgba(249, 115, 22, 0.2);
        }

        .space-y-8 > * + * {
            margin-top: 1.5rem;
        }

        .space-y-6 > * + * {
            margin-top: 1rem;
        }

        .space-y-4 > * + * {
            margin-top: 0.75rem;
        }

        @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
        }

        @keyframes slideIn {
            from { 
                opacity: 0;
                transform: translateY(20px);
            }
            to { 
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes scaleIn {
            from { 
                opacity: 0;
                transform: scale(0.95);
            }
            to { 
                opacity: 1;
                transform: scale(1);
            }
        }

        .animate-fadeIn {
            animation: fadeIn 0.5s ease-out forwards;
        }

        .animate-slideIn {
            animation: slideIn 0.5s ease-out forwards;
        }

        .animate-scaleIn {
            animation: scaleIn 0.3s ease-out forwards;
        }

        .player-strength-card {
            animation: scaleIn 0.3s ease-out forwards;
            opacity: 0;
        }

        .stat-value {
            transition: all 0.2s ease-in-out;
        }

        .stat-value:hover {
            transform: translateY(-1px);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .player-description {
            animation: fadeIn 0.5s ease-out 0.2s forwards;
            opacity: 0;
        }

        .section-title {
            position: relative;
            overflow: hidden;
        }

        .section-title::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: rgba(249, 115, 22, 0.2);
            transform: scaleX(0);
            transform-origin: left;
            animation: slideWidth 0.5s ease-out 0.2s forwards;
        }

        @keyframes slideWidth {
            from { transform: scaleX(0); }
            to { transform: scaleX(1); }
        }
    `;

    // Update the marked options to allow HTML
    marked.setOptions({
        breaks: true,
        gfm: true,
        headerIds: false,
        mangle: false,
        sanitize: false
    });

    const PlayerCard = ({ player, stats, description }) => {
        const impactPercentage = stats.impact / stats.points * 100;
        const playerImageUrl = player ? `https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png` : null;
        const teamLogoUrl = player ? `https://kickbase.b-cdn.net/pool/teams/${player.teamId}.png` : null;
        const teamDummyUrl = player ? `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png` : null;
        const playerName = player ? player.fullName : stats.player.replace(/\*\*/g, '');

        // Calculate performance metrics
        const pointsPerGame = player ? (player.totalPoints / (player.gamesPlayed || 1)).toFixed(1) : 0;
        const valuePerPoint = player ? (player.marketValue / (player.totalPoints || 1)).toFixed(0) : 0;
        const efficiency = player ? ((player.totalPoints / (player.marketValue / 1000000)) || 0).toFixed(1) : 0;

        return (
            <div className="bg-gray-800/50 rounded-lg p-3">
                <div className="flex flex-col sm:flex-row gap-3">
                    <div className="flex items-center gap-2 min-w-0">
                        <div className="relative flex-shrink-0">
                            {player ? (
                                <>
                                    <img 
                                        src={playerImageUrl}
                                        alt={playerName}
                                        className="w-10 h-10 rounded-full border-2 border-orange-500/50"
                                        onError={(e) => { e.target.src = teamDummyUrl; }}
                                    />
                                    <img 
                                        src={teamLogoUrl}
                                        alt="Team"
                                        className="absolute -bottom-1 -right-1 w-4 h-4 rounded-full border border-gray-900"
                                        onError={(e) => { e.target.src = teamDummyUrl; }}
                                    />
                                </>
                            ) : (
                                <div className="w-10 h-10 rounded-full bg-gray-700" />
                            )}
                        </div>
                        <div className="flex flex-col min-w-0 flex-1">
                            <span className="text-lg font-semibold text-blue-400 truncate">{playerName}</span>
                            {player && (
                                <div className="flex items-center gap-2">
                                    <span className="text-sm text-gray-400">{player.position}</span>
                                    {player.status && (
                                        <span className={`text-xs px-2 py-0.5 rounded-full ${
                                            player.status === 'Fit' ? 'bg-green-500/20 text-green-400' :
                                            player.status === 'Verletzt' ? 'bg-red-500/20 text-red-400' :
                                            'bg-yellow-500/20 text-yellow-400'
                                        }`}>
                                            {player.status}
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center gap-3 flex-shrink-0 ml-auto">
                        <div className="flex flex-col items-center px-3 py-2 bg-gray-900/50 rounded-lg">
                            <span className="text-xs text-gray-400">Punkte</span>
                            <span className="text-lg font-semibold text-orange-500">
                                {formatNumber(stats.points, { minimumFractionDigits: 0 })}
                            </span>
                            <span className="text-xs text-gray-500">
                                Ø {pointsPerGame}/Spiel
                            </span>
                        </div>
                        <div className="flex flex-col items-center px-3 py-2 bg-gray-900/50 rounded-lg">
                            <span className="text-xs text-gray-400">Performance</span>
                            <span className={`text-lg font-semibold ${impactPercentage >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                {formatPercent(impactPercentage)}
                            </span>
                            <span className="text-xs text-gray-500">
                                vs. Ø Team
                            </span>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-2 mt-3 pt-3 border-t border-gray-700/50">
                    <div className="text-center p-2 bg-gray-900/50 rounded-lg">
                        <div className="text-xs text-gray-400 mb-1">Tore</div>
                        <div className="text-sm font-medium text-white">{player.goals || 0}</div>
                    </div>
                    <div className="text-center p-2 bg-gray-900/50 rounded-lg">
                        <div className="text-xs text-gray-400 mb-1">Assists</div>
                        <div className="text-sm font-medium text-white">{player.assists || 0}</div>
                    </div>
                    <div className="text-center p-2 bg-gray-900/50 rounded-lg">
                        <div className="text-xs text-gray-400 mb-1">Effizienz</div>
                        <div className="text-sm font-medium text-white">{efficiency}</div>
                    </div>
                </div>

                <div className="mt-3 pt-3 border-t border-gray-700/50">
                    <div 
                        className="text-gray-300 text-sm leading-relaxed"
                        dangerouslySetInnerHTML={{ __html: marked(description) }}
                    />
                </div>

                {player && player.marketStats && (
                    <div className="mt-3 pt-3 border-t border-gray-700/50">
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-400">Marktwert Trend</span>
                            <div className="flex items-center gap-2">
                                <span className={`text-sm ${player.marketStats.marketValueGainLoss >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                    {formatCurrency(player.marketStats.marketValueGainLoss)}
                                </span>
                                <span className={`text-xs ${player.marketStats.sevenDayTrend >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                    (7T: {formatPercent(player.marketStats.sevenDayTrend / 100)})
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderTeamBalance = (content) => {
        const teambalanceMatch = content.match(/\*\*Teambalance\*\*: ([^\\n]+)/);
        if (!teambalanceMatch) return null;

        // Parse the team balance text
        const balanceText = teambalanceMatch[1];
        const positions = balanceText.split(',').map(pos => pos.trim());

        // Get counts for each position
        const positionCounts = positions.reduce((acc, pos) => {
            const [role, count] = pos.split(' ');
            switch(role) {
                case 'Torwart':
                    acc[0] = parseInt(count);
                    break;
                case 'Verteidiger':
                    acc[1] = parseInt(count);
                    break;
                case 'Mittelfeldspieler':
                    acc[2] = parseInt(count);
                    break;
                case 'Stürmer':
                    acc[3] = parseInt(count);
                    break;
            }
            return acc;
        }, [0, 0, 0, 0]);

        // Create formation string (excluding goalkeeper)
        const formation = positionCounts.slice(1).join('-');

        return (
            <div className="bg-gray-800/50 p-3 rounded-lg">
                <div className="flex items-center justify-between">
                    <span className="text-orange-500 font-semibold text-sm">Formation</span>
                    <span className="text-lg text-white font-bold">{formation}</span>
                </div>
            </div>
        );
    };

    const renderMarketValue = (marktwertMatch, teamStats, budget) => {
        if (!marktwertMatch) return null;

        const currentValue = Number(marktwertMatch[1]);
        const dailyChange = Number(marktwertMatch[2]);
        const percentageChange = (dailyChange / currentValue) * 100;
        const investmentLimit = currentValue * 0.33;
        const availableFunds = investmentLimit + budget;

        return (
            <div className="bg-gray-800/50 rounded-lg">
                {/* Title */}
                <div className="p-3 border-b border-gray-700">
                    <h4 className="text-base font-semibold text-orange-500">Marktwert Analyse</h4>
                </div>

                {/* Main Value */}
                <div className="p-4">
                    <div className="text-3xl font-bold text-white">
                        {formatCurrency(currentValue)}
                    </div>
                    <div className={`text-base mt-1 ${dailyChange >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                        {dailyChange >= 0 ? '↑' : '↓'} {formatCurrency(Math.abs(dailyChange))} ({formatPercent(percentageChange / 100)})
                    </div>
                </div>

                {/* Budget */}
                <div className="p-4 border-t border-gray-700">
                    <div className="text-sm text-gray-400">Budget</div>
                    <div className="text-2xl font-bold text-white mt-1">
                        {formatCurrency(budget)}
                    </div>
                </div>

                {/* Available Funds */}
                <div className="p-4 border-t border-gray-700">
                    <div className="text-sm text-gray-400">Verfügbar (33%)</div>
                    <div className="text-2xl font-bold text-white mt-1">
                        {formatCurrency(availableFunds)}
                    </div>
                </div>

                {/* Market Development */}
                <div className="p-4 border-t border-gray-700">
                    <div className="text-sm text-gray-400 mb-2">Marktentwicklung</div>
                    <div className="space-y-2">
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-300">Tägliche Änderung</span>
                            <span className={`text-base font-medium ${dailyChange >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                {formatCurrency(dailyChange)}
                            </span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-300">Prozentuale Änderung</span>
                            <span className={`text-base font-medium ${percentageChange >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                {formatPercent(percentageChange / 100)}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Investment Status */}
                <div className="p-4 border-t border-gray-700">
                    <div className="text-sm text-gray-400 mb-2">Investment Status</div>
                    <div className="space-y-2">
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-300">Aktuelles Budget</span>
                            <span className="text-base font-medium text-white">
                                {formatCurrency(budget)}
                            </span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-300">Investitionsrahmen</span>
                            <span className="text-base font-medium text-white">
                                {formatCurrency(investmentLimit)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderSection = (title, content, className = "") => {
        if (!content) return null;
        
        if (title === "Teamübersicht") {
            const gesamtpunkteMatch = content.match(/\*\*Gesamtpunkte\*\*: (\d+)/);
            const durchschnittMatch = content.match(/\*\*Durchschnitt\*\*: (\d+)/);
            const trendMatch = content.match(/\*\*Trend\*\*: ([↑↓])/);
            const marktwertMatch = content.match(/\*\*Marktwert\*\*: (\d+)€ \(([+-]\d+)€ heute\)/);

            return (
                <div className={`animate-slideIn ${className}`}>
                    <h3 className="text-xl font-bold text-orange-500 mb-4">Teamübersicht</h3>
                    <div className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="bg-gray-800/50 p-3 rounded-lg">
                                <span className="text-gray-400 block mb-1 text-xs">Gesamtpunkte</span>
                                <span className="text-white font-semibold text-lg block">
                                    {gesamtpunkteMatch ? formatNumber(Number(gesamtpunkteMatch[1])) : '-'}
                                </span>
                                <span className="text-gray-500 text-xs block mt-1">Alle Punkte deines Teams</span>
                            </div>
                            <div className="bg-gray-800/50 p-3 rounded-lg">
                                <span className="text-gray-400 block mb-1 text-xs">Durchschnitt</span>
                                <span className="text-white font-semibold text-lg block">
                                    {durchschnittMatch ? formatNumber(Number(durchschnittMatch[1])) : '-'}
                                </span>
                                <span className="text-gray-500 text-xs block mt-1">Punkte pro Spieler</span>
                            </div>
                            {trendMatch && (
                                <div className="bg-gray-800/50 p-3 rounded-lg">
                                    <span className="text-gray-400 block mb-1 text-xs">Trend</span>
                                    <span className={`font-bold text-2xl block ${trendMatch[1] === '↑' ? 'text-green-400' : 'text-red-400'}`}>
                                        {trendMatch[1]}
                                    </span>
                                    <span className="text-gray-500 text-xs block mt-1">
                                        {trendMatch[1] === '↑' ? 'Positive' : 'Negative'} Entwicklung
                                    </span>
                                </div>
                            )}
                        </div>

                        {renderTeamBalance(content)}

                        {renderMarketValue(marktwertMatch, playerStoreInstance.teamStats, playerStoreInstance.currentBudget)}
                    </div>
                </div>
            );
        }

        return (
            <div className={`animate-slideIn ${className}`}>
                <h3 className="section-title">{title}</h3>
                <div className="stat-card">
                    <div dangerouslySetInnerHTML={{ __html: marked(content) }} />
                </div>
            </div>
        );
    };

    const renderList = (items, className = "") => {
        if (!items || !Array.isArray(items) || items.length === 0) return null;
        
        return (
            <div className={`space-y-4 ${className}`}>
                {items.map((item, index) => {
                    if (!item) return null;

                    if (typeof item === 'object' && item.player) {
                        const playerName = item.player.replace(/\*\*/g, '');
                        const player = squadData?.find(p => 
                            p.fullName?.toLowerCase().includes(playerName.toLowerCase()) ||
                            `${p.firstName} ${p.lastName}`.toLowerCase().includes(playerName.toLowerCase())
                        );

                        return (
                            <div 
                                key={index} 
                                className="animate-slideIn" 
                                style={{ animationDelay: `${index * 0.1}s` }}
                            >
                                <PlayerCard 
                                    player={player}
                                    stats={item}
                                    description={item.description}
                                />
                            </div>
                        );
                    }

                    return (
                        <div 
                            key={index} 
                            className="stat-card animate-slideIn" 
                            style={{ animationDelay: `${index * 0.1}s` }}
                            dangerouslySetInnerHTML={{ __html: marked(item) }}
                        />
                    );
                })}
            </div>
        );
    };

    const renderLeagueAnalysis = (analysis) => {
        console.log('Rendering League Analysis:', {
            rawAnalysis: analysis,
            hasPosition: !!analysis?.position,
            positionData: analysis?.position,
            competitorsData: analysis?.competitors
        });

        if (!analysis?.position) return null;

        return (
            <div className="space-y-6 mt-8">
                <h3 className="section-title">Ligaanalyse</h3>
                <div className="bg-gray-800/50 rounded-xl p-4">
                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-4">
                        <div className="flex items-baseline gap-4">
                            <span className="text-4xl font-bold text-orange-500">#{analysis.position.current}</span>
                            <span className="text-xl text-gray-400">
                                {formatNumber(analysis.position.points)} Punkte
                            </span>
                        </div>
                        {analysis.position.trend && (
                            <div className="flex items-center gap-2">
                                <span className="text-sm text-gray-400">Trend</span>
                                <span className={`text-2xl font-bold ${analysis.position.trend === '↑' ? 'text-green-400' : 'text-red-400'}`}>
                                    {analysis.position.trend}
                                </span>
                            </div>
                        )}
                    </div>

                    {analysis.position.description && (
                        <p className="text-gray-300 mb-6">{analysis.position.description}</p>
                    )}
                    
                    {analysis.competitors && analysis.competitors.length > 0 && (
                        <div className="space-y-3">
                            <h4 className="text-sm font-medium text-gray-400 mb-2">Nächste Konkurrenten</h4>
                            {analysis.competitors.map((competitor, index) => (
                                <div key={index} className="flex items-center justify-between p-3 bg-gray-900/50 rounded-lg">
                                    <div className="flex items-center gap-3">
                                        <span className="text-gray-500 text-sm">#{competitor.position}</span>
                                        <span className="text-white font-medium">{competitor.team}</span>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <span className="text-gray-400">{formatNumber(competitor.points)}</span>
                                        <span className={`text-sm font-medium ${competitor.difference > 0 ? 'text-green-400' : 'text-red-400'}`}>
                                            {competitor.difference > 0 ? '+' : ''}{formatNumber(competitor.difference)}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderAnalysis = () => {
        if (!analysis || typeof analysis !== 'object') {
            console.log('No analysis data available:', analysis);
            return null;
        }

        const hasTeamAnalysis = analysis.team_analysis && typeof analysis.team_analysis === 'object';
        const hasLeagueAnalysis = analysis.league_analysis && typeof analysis.league_analysis === 'object';
        const hasMarketRecommendations = analysis.market_recommendations && typeof analysis.market_recommendations === 'object';

        console.log('Analysis Structure:', {
            hasTeamAnalysis,
            hasLeagueAnalysis,
            hasMarketRecommendations,
            leagueAnalysisData: analysis.league_analysis,
            teamAnalysisKeys: hasTeamAnalysis ? Object.keys(analysis.team_analysis) : [],
            leagueAnalysisKeys: hasLeagueAnalysis ? Object.keys(analysis.league_analysis) : [],
            marketRecommendationsKeys: hasMarketRecommendations ? Object.keys(analysis.market_recommendations) : []
        });

        return (
            <div className="space-y-8">
                {hasTeamAnalysis && (
                    <div className="space-y-6">
                        {renderSection("Teamübersicht", analysis.team_analysis.overview)}
                        <div className="grid grid-cols-1 gap-6">
                            <div className="space-y-4">
                                <h3 className="section-title">Stärken</h3>
                                {renderList(analysis.team_analysis.strengths)}
                            </div>
                            <div className="space-y-4">
                                <h3 className="section-title">Schwächen</h3>
                                {renderList(analysis.team_analysis.weaknesses)}
                            </div>
                        </div>
                    </div>
                )}

                {hasLeagueAnalysis && (
                    <>
                        {console.log('League Analysis Data:', {
                            position: analysis.league_analysis.position,
                            competitors: analysis.league_analysis.competitors,
                            rawData: analysis.league_analysis
                        })}
                        {renderLeagueAnalysis(analysis.league_analysis)}
                    </>
                )}

                {hasMarketRecommendations && (
                    <div className="space-y-6 mt-8">
                        <h3 className="section-title">Marktempfehlungen</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="space-y-4">
                                <h4 className="text-green-400 text-lg font-semibold mb-4">Kaufempfehlungen</h4>
                                {analysis.market_recommendations.buy.map((recommendation, index) => (
                                    <div key={index} className="bg-gray-800/50 rounded-xl p-4">
                                        <div className="flex justify-between items-center mb-2">
                                            <span className="text-white font-medium">{recommendation.player}</span>
                                            <span className="text-green-400">
                                                +{formatPercent(recommendation.roi_potential)} ROI
                                            </span>
                                        </div>
                                        <div className="flex justify-between text-sm text-gray-400 mb-2">
                                            <span>Aktuell: {formatCurrency(recommendation.current_value)}</span>
                                            <span>Prognose: {formatCurrency(recommendation.expected_value)}</span>
                                        </div>
                                        <p className="text-gray-300 text-sm">{recommendation.reason}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="space-y-4">
                                <h4 className="text-red-400 text-lg font-semibold mb-4">Verkaufsempfehlungen</h4>
                                {analysis.market_recommendations.sell.map((recommendation, index) => (
                                    <div key={index} className="bg-gray-800/50 rounded-xl p-4">
                                        <div className="flex justify-between items-center mb-2">
                                            <span className="text-white font-medium">{recommendation.player}</span>
                                            <span className="text-red-400">
                                                {formatCurrency(recommendation.current_value)}
                                            </span>
                                        </div>
                                        <div className="flex justify-between text-sm text-gray-400 mb-2">
                                            <span>Prognose: {formatCurrency(recommendation.expected_value)}</span>
                                        </div>
                                        <p className="text-gray-300 text-sm">{recommendation.reason}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4">
            <div className="bg-gray-900 rounded-xl shadow-2xl p-4 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <style>
                    {styles}
                </style>

                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold text-white flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-orange-500" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12 1L9 9l-8 3 8 3 3 8 3-8 8-3-8-3-3-8z" />
                        </svg>
                        KI Analyse
                    </h2>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-white transition-colors"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {error && (
                    <div className="bg-red-500/10 border border-red-500 text-red-500 p-4 rounded-lg mb-6 flex items-center animate-fadeIn">
                        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        {error}
                    </div>
                )}

                {loading && (
                    <div className="flex flex-col items-center justify-center py-12 animate-fadeIn">
                        <div className="relative">
                            <ClipLoader color={"#ff4601"} size={40} />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <svg className="w-5 h-5 text-orange-500" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M12 1L9 9l-8 3 8 3 3 8 3-8 8-3-8-3-3-8z" />
                                </svg>
                            </div>
                        </div>
                        <p className="text-white mt-4 text-lg">Analysiere dein Team...</p>
                        <p className="text-gray-400 mt-2 text-sm">Dies kann einen Moment dauern</p>
                    </div>
                )}

                <div className="prose prose-custom text-sm">
                    {renderAnalysis()}
                </div>
            </div>
        </div>
    );
};

export default AIAnalytics; 