import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './firebase';

// Improved service worker registration with special handling for iOS
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      // Check if we're on iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                   window.navigator.standalone === true || 
                   document.referrer.includes('android-app://');
                   
      console.log('Environment detection for service worker:', { isIOS, isPWA });
      
      // Unregister any existing service workers first to avoid conflicts
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let registration of registrations) {
        console.log('Unregistering existing service worker:', registration.scope);
        await registration.unregister();
      }

      // Register the Firebase Messaging service worker with appropriate options
      const swOptions = {
        scope: '/'
      };
      
      // On iOS, setting updateViaCache to 'none' can help with service worker updates
      if (isIOS) {
        swOptions.updateViaCache = 'none';
      }

      // Register service worker
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', swOptions);
      console.log('Service Worker registration successful with scope:', registration.scope);
      
      // For iOS, ensure the service worker is controlling this page before proceeding
      if (isIOS && !navigator.serviceWorker.controller) {
        console.log('Waiting for service worker to control the page...');
        // Wait for the service worker to become active
        if (registration.installing) {
          await new Promise(resolve => {
            registration.installing.addEventListener('statechange', (event) => {
              if (event.target.state === 'activated') {
                console.log('Service worker is now activated and controlling the page');
                resolve();
              }
            });
          });
        }
      }
    } catch (error) {
      console.error('Service Worker registration failed:', error);
    }
  });
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
