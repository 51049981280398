import React, { useState } from 'react';
import { firestore } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const FeedbackForm = ({ onClose, isModal = false }) => {
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!feedback.trim()) return;

        setIsSubmitting(true);
        try {
            const username = localStorage.getItem('username') || 'Anonymous';
            await addDoc(collection(firestore, 'feedback'), {
                username,
                feedback: feedback.trim(),
                timestamp: new Date().toISOString(),
                dateCreated: new Date()
            });

            setIsSubmitted(true);
            setFeedback('');
            setTimeout(() => {
                if (onClose) onClose();
            }, 2000);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isModal) {
        return (
            <div className="bg-gray-800 p-8 rounded-lg w-full max-w-2xl">
                <h2 className="text-2xl font-bold mb-6 text-white">Dein Feedback</h2>
                {isSubmitted ? (
                    <div className="text-green-400 text-center py-6 text-lg">
                        Vielen Dank für dein Feedback!
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <textarea
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            className="w-full h-64 p-4 rounded-lg bg-gray-700 text-white border border-gray-600 focus:border-orange-500 focus:ring-1 focus:ring-orange-500 text-base"
                            placeholder="Hast du einen Bug gefunden oder eine Idee für Kickly? 🐛✨

Egal ob Fehler, Feature-Wünsche oder Verbesserungsvorschläge - dein Feedback ist wertvoll!

Beschreibe dein Anliegen hier"
                        />
                        <div className="flex justify-end mt-6 space-x-4">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-6 py-3 bg-gray-600 text-white rounded-lg hover:bg-gray-500 text-base"
                            >
                                Abbrechen
                            </button>
                            <button
                                type="submit"
                                disabled={isSubmitting || !feedback.trim()}
                                className={`px-6 py-3 bg-orange-600 text-white rounded-lg text-base ${
                                    isSubmitting || !feedback.trim() 
                                        ? 'opacity-50 cursor-not-allowed' 
                                        : 'hover:bg-orange-700'
                                }`}
                            >
                                {isSubmitting ? 'Wird gesendet...' : 'Feedback senden'}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        );
    }

    return (
        <div className="max-w-3xl mx-auto mb-8 px-4">
            {isSubmitted ? (
                <div className="text-green-400 text-center py-6 bg-gray-800 rounded-lg text-lg">
                    Vielen Dank für dein Feedback!
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                    <textarea
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        className="w-full h-64 p-4 rounded-lg bg-gray-700 text-white border border-gray-600 focus:border-orange-500 focus:ring-1 focus:ring-orange-500 text-base"
                        placeholder="Hast du einen Bug gefunden oder eine Idee für Kickly? 🐛✨

Egal ob Fehler, Feature-Wünsche oder Verbesserungsvorschläge - dein Feedback ist wertvoll!

Beschreibe dein Anliegen hier"
                    />
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={isSubmitting || !feedback.trim()}
                            className={`px-8 py-3 bg-orange-600 text-white rounded-lg text-base ${
                                isSubmitting || !feedback.trim() 
                                    ? 'opacity-50 cursor-not-allowed' 
                                    : 'hover:bg-orange-700'
                            }`}
                        >
                            {isSubmitting ? 'Wird gesendet...' : 'Feedback senden'}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default FeedbackForm; 