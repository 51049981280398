import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { playerStoreInstance } from './stores/PlayerStore';
import LeagueSelector from './LeagueSelector';
import { useObserver } from 'mobx-react-lite';
import { ClipLoader } from 'react-spinners';
import { getLeagueUserInfo, getLeagueRanking, runInAction } from './api';
import FeedbackForm from './components/FeedbackForm';
import Modal from 'react-modal';
import AIAnalytics from './components/AIAnalytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import notificationService from './services/notificationService';

const Header = ({ user, onLogout }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showLeagueModal, setShowLeagueModal] = useState(false);
    const [showBudgetModal, setShowBudgetModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showAIModal, setShowAIModal] = useState(false);
    const [aiLoading, setAiLoading] = useState(false);
    const [showNotificationSettings, setShowNotificationSettings] = useState(false);
    const [permissionStatus, setPermissionStatus] = useState('unknown');

    // Function to check notification permission status
    const checkNotificationPermissionStatus = useCallback(async () => {
        if (!notificationService.notificationsSupported) {
            setPermissionStatus('not-supported');
            return;
        }

        // Check if permissions API is available
        if (navigator.permissions) {
            try {
                const status = await navigator.permissions.query({ name: 'notifications' });
                setPermissionStatus(status.state);
                
                // Add event listener to track permission changes
                status.onchange = () => {
                    setPermissionStatus(status.state);
                    console.log('Notification permission changed to:', status.state);
                };
            } catch (error) {
                console.error('Error querying notification permission:', error);
                // Fallback to standard check
                setPermissionStatus(Notification.permission);
            }
        } else {
            // Fallback for browsers without Permissions API
            setPermissionStatus(Notification.permission);
        }
    }, []);

    // Check permission status on component mount
    useEffect(() => {
        checkNotificationPermissionStatus();
    }, [checkNotificationPermissionStatus]);

    // Function to handle notification permission request
    const handleRequestNotificationPermission = async () => {
        try {
            await notificationService.forcePermissionRequest();
            await checkNotificationPermissionStatus();
            toast.success('Benachrichtigungen wurden erfolgreich aktiviert!');
        } catch (error) {
            console.error('Error requesting notification permission:', error);
            
            // Show appropriate error message based on the error
            if (error.message.includes('previously denied') || error.message.includes('blocked')) {
                const resetInfo = notificationService.getPermissionResetInstructions();
                toast.error(
                    <div className="text-sm">
                        <p className="font-bold mb-1">Benachrichtigungen sind blockiert</p>
                        <p>Um Benachrichtigungen zu aktivieren, musst du die Einstellungen in deinem Browser ändern:</p>
                        <p className="mt-1 font-semibold">{resetInfo.instructions}</p>
                    </div>,
                    { autoClose: 10000 }
                );
            } else {
                toast.error(`Fehler beim Aktivieren der Benachrichtigungen: ${error.message}`);
            }
        }
    };

    // Notification settings dialog component
    const NotificationSettingsDialog = () => (
        <Modal
            isOpen={showNotificationSettings}
            onRequestClose={() => setShowNotificationSettings(false)}
            className="bg-gray-800 rounded-lg p-4 sm:p-6 w-11/12 sm:w-96 mx-auto mt-20 relative"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4"
        >
            <div className="flex flex-col space-y-4">
                <div className="relative bg-gray-900 rounded-xl p-4 border border-gray-700 shadow-lg">
                    <div className="absolute -top-3 left-4 px-2 bg-gray-900">
                        <span className="text-orange-500 text-sm font-medium">Benachrichtigungseinstellungen</span>
                    </div>
                    
                    <div className="flex flex-col space-y-4 mt-2">
                        <h2 className="text-white text-lg sm:text-xl font-semibold">
                            Benachrichtigungen verwalten
                        </h2>
                        
                        <div className="bg-gray-800 p-3 rounded-lg">
                            <p className="text-gray-300 text-sm mb-2">
                                Aktueller Status: 
                                <span className={`ml-2 font-semibold ${
                                    permissionStatus === 'granted' ? 'text-green-500' : 
                                    permissionStatus === 'denied' ? 'text-red-500' : 
                                    'text-yellow-500'
                                }`}>
                                    {permissionStatus === 'granted' ? 'Erlaubt' : 
                                     permissionStatus === 'denied' ? 'Blockiert' : 
                                     permissionStatus === 'prompt' ? 'Nicht festgelegt' :
                                     permissionStatus === 'not-supported' ? 'Nicht unterstützt' :
                                     'Unbekannt'}
                                </span>
                            </p>
                            
                            {permissionStatus === 'denied' && (
                                <div className="mt-2 p-3 bg-red-900 bg-opacity-30 rounded-lg">
                                    <p className="text-sm text-red-300">
                                        Benachrichtigungen sind blockiert. Um sie zu aktivieren, musst du die Einstellungen in deinem Browser ändern.
                                    </p>
                                    <div className="mt-2">
                                        <p className="text-xs text-gray-300">
                                            {notificationService.getPermissionResetInstructions().instructions}
                                        </p>
                                    </div>
                                </div>
                            )}
                            
                            {permissionStatus === 'not-supported' && (
                                <div className="mt-2 p-3 bg-yellow-900 bg-opacity-30 rounded-lg">
                                    <p className="text-sm text-yellow-300">
                                        Benachrichtigungen werden in diesem Browser nicht unterstützt.
                                    </p>
                                    {notificationService.isIOSDevice() && !notificationService.isPWA() && (
                                        <p className="text-xs text-gray-300 mt-2">
                                            Füge diese App zum Home-Bildschirm hinzu, um Benachrichtigungen auf iOS zu erhalten.
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                        
                        <div className="flex flex-col space-y-2">
                            <button
                                onClick={handleRequestNotificationPermission}
                                disabled={permissionStatus === 'granted' || permissionStatus === 'not-supported'}
                                className={`w-full py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2 font-semibold
                                    ${permissionStatus === 'granted' || permissionStatus === 'not-supported' 
                                        ? 'bg-gray-600 text-gray-400 cursor-not-allowed' 
                                        : 'bg-orange-500 hover:bg-orange-600 text-white'}`}
                            >
                                <FontAwesomeIcon icon={faBell} />
                                <span>
                                    {permissionStatus === 'granted' 
                                        ? 'Benachrichtigungen bereits aktiviert' 
                                        : permissionStatus === 'not-supported'
                                            ? 'Benachrichtigungen nicht unterstützt'
                                            : 'Benachrichtigungen aktivieren'}
                                </span>
                            </button>
                            
                            <button
                                onClick={() => setShowNotificationSettings(false)}
                                className="w-full bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded-lg transition-colors duration-200"
                            >
                                Schließen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );

    const formatCurrency = (value) => {
        const numValue = Number(value) || 0;
        return new Intl.NumberFormat('de-DE', { 
            style: 'currency', 
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(numValue);
    };

    useEffect(() => {
        const checkUserInfo = () => {
            if (playerStoreInstance.userInfo) {
                setLoading(false);
            }
        };

        checkUserInfo();
        const intervalId = setInterval(checkUserInfo, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Debug logging
    useEffect(() => {
        console.log('UserInfo:', playerStoreInstance.userInfo);
    }, []);

    // Calculate total players from tpc array
    const totalPlayers = useMemo(() => {
        const tpc = playerStoreInstance.userInfo?.tpc;
        if (!tpc || !Array.isArray(tpc)) return 0;
        return tpc.reduce((total, team) => total + Number(team.npt), 0);
    }, []);

    // Get max players allowed
    const maxPlayers = playerStoreInstance.userInfo?.mppu || 0;

    // Get values from store using getters
    const budget = playerStoreInstance.currentBudget;
    const teamValue = playerStoreInstance.currentTeamValue;
    const realValue = teamValue + budget;

    // Debug logging
    useEffect(() => {
        console.log('Current values:', {
            budget,
            teamValue,
            realValue
        });
    }, [budget, teamValue, realValue]);

    // Investment calculation (33% rule)
    const investmentLeft = useMemo(() => {
        if (budget < 0) {
            return (realValue * 0.33);
        }
        return (teamValue * 0.33) + budget;
    }, [budget, teamValue, realValue]);

    // Updated modal design matching the screenshot
    const BudgetModal = () => {
        const [isLoading, setIsLoading] = useState(true);
        const [retryCount, setRetryCount] = useState(0);
        const [modalData, setModalData] = useState({
            budget: playerStoreInstance.currentBudget,
            teamValue: playerStoreInstance.currentTeamValue,
            realValue: playerStoreInstance.realValue,
            investmentLeft: 0
        });

        // Effect to load data when modal opens
        useEffect(() => {
            let isMounted = true;
            let refreshInterval;

            const loadModalData = async () => {
                if (!isMounted) return;
                
                setIsLoading(true);
                try {
                    // Refresh user info
                    if (playerStoreInstance.leagueId) {
                        await playerStoreInstance.fetchUserInfo(playerStoreInstance.leagueId);
                        
                        // Wait a bit for the data to be processed
                        await new Promise(resolve => setTimeout(resolve, 100));
                    }

                    if (!isMounted) return;

                    // Get fresh values after data is loaded
                    const budget = playerStoreInstance.currentBudget;
                    const teamValue = playerStoreInstance.currentTeamValue;
                    const realValue = teamValue + budget;

                    console.log('Modal Data Refresh:', {
                        budget,
                        teamValue,
                        realValue,
                        retryCount,
                        leagueId: playerStoreInstance.leagueId,
                        hasUserInfo: !!playerStoreInstance.userInfo
                    });

                    // If teamValue is 0 and we haven't retried too many times, retry
                    if (teamValue === 0 && retryCount < 2) {
                        setRetryCount(prev => prev + 1);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        loadModalData();
                        return;
                    }

                    // Calculate investment using the 33% rule
                    const investment = budget < 0 
                        ? (realValue * 0.33)
                        : (teamValue * 0.33) + budget;

                    console.log('Investment calculation:', {
                        budget,
                        teamValue,
                        realValue,
                        investment,
                        formula: budget < 0 ? 'realValue * 0.33' : 'teamValue * 0.33 + budget',
                        calculation: budget < 0 
                            ? `${realValue} * 0.33 = ${investment}`
                            : `${teamValue} * 0.33 + ${budget} = ${investment}`
                    });

                    if (!isMounted) return;

                    setModalData({
                        budget,
                        teamValue,
                        realValue,
                        investmentLeft: investment
                    });
                } catch (error) {
                    console.error('Error loading modal data:', error);
                } finally {
                    if (isMounted) {
                        setIsLoading(false);
                    }
                }
            };

            loadModalData();

            // Set up an interval to refresh data
            refreshInterval = setInterval(loadModalData, 5000);

            // Cleanup
            return () => {
                isMounted = false;
                if (refreshInterval) {
                    clearInterval(refreshInterval);
                }
            };
        }, [retryCount]); // Only depend on retryCount

        // Render loading state
        if (isLoading) {
            return (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4">
                    <div className="bg-gray-900 rounded-xl shadow-2xl p-6 w-full max-w-md">
                        <h2 className="text-xl font-bold text-white mb-6 text-center">Finanzübersicht</h2>
                        <div className="flex justify-center items-center h-48">
                            <ClipLoader color={"#ff4601"} size={30} />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4">
                <div className="bg-gray-900 rounded-xl shadow-2xl p-6 w-full max-w-md">
                    <h2 className="text-xl font-bold text-white mb-6 text-center">Finanzübersicht</h2>
                    <div className="grid grid-cols-2 gap-4 mb-6">
                        <div className="bg-gray-800 p-4 rounded-lg">
                            <span className="block text-gray-400 text-sm mb-1">Kontostand</span>
                            <span className="block text-white text-lg font-bold">{formatCurrency(modalData.budget)}</span>
                        </div>
                        
                        <div className="bg-gray-800 p-4 rounded-lg">
                            <span className="block text-gray-400 text-sm mb-1">Teamwert</span>
                            <span className="block text-white text-lg font-bold">{formatCurrency(modalData.teamValue)}</span>
                        </div>
                        
                        <div className="bg-gray-800 p-4 rounded-lg">
                            <span className="block text-gray-400 text-sm mb-1">Gesamtwert</span>
                            <span className="block text-white text-lg font-bold">{formatCurrency(modalData.realValue)}</span>
                        </div>
                        
                        <div className="bg-gray-800 p-4 rounded-lg">
                            <span className="block text-gray-400 text-sm mb-1">Invest. Budget</span>
                            <span className="block text-white text-lg font-bold">{formatCurrency(modalData.investmentLeft)}</span>
                        </div>
                    </div>

                    <button 
                        onClick={() => setShowBudgetModal(false)}
                        className="w-full bg-red-500 hover:bg-red-600 text-white py-3 rounded-lg transition-colors duration-200 text-center"
                    >
                        Schließen
                    </button>
                </div>
            </div>
        );
    };

    // Get league name
    const leagueName = playerStoreInstance.userInfo?.lnm || '';

    const FinancialCards = () => (
        <div className="finance-container space-x-4">
            <div className="finance-card p-4 border rounded">
                <span className="block text-gray-400">Konto</span>
                <span className="block text-white text-lg">{formatCurrency(budget)}</span>
            </div>
            <div className="finance-card p-4 border rounded">
                <span className="block text-gray-400">Team</span>
                <span className="block text-white text-lg">{formatCurrency(teamValue)}</span>
            </div>
            <div className="finance-card p-4 border rounded">
                <span className="block text-gray-400">Status</span>
                <span className="block text-white text-lg">{playerStoreInstance.userInfo?.bs || 0}</span>
            </div>
            <div className="finance-card p-4 border rounded">
                <span className="block text-gray-400">Liga</span>
                <span className="block text-white text-lg">{leagueName.slice(0, 10)}...</span>
            </div>
        </div>
    );

    const MobileFinancialCards = () => (
        <div className="finance-container flex space-x-2">
            <div className="finance-card p-2 border rounded">
                <span className="block text-gray-400">Konto</span>
                <span className="block text-white text-lg">{formatCurrency(budget)}</span>
            </div>
            <div className="finance-card p-2 border rounded">
                <span className="block text-gray-400">Team</span>
                <span className="block text-white text-lg">{formatCurrency(teamValue)}</span>
            </div>
            <div className="finance-card p-2 border rounded">
                <span className="block text-gray-400">Status</span>
                <span className="block text-white text-lg">{playerStoreInstance.userInfo?.bs || 0}</span>
            </div>
            <div className="finance-card p-2 border rounded">
                <span className="block text-gray-400">Liga</span>
                <span className="block text-white text-lg">{leagueName.slice(0, 10)}...</span>
            </div>
        </div>
    );

    const handleLeagueChange = async (selectedLeagueId) => {
        console.log('League changed to:', selectedLeagueId);
        try {
            setLoading(true);
            // Update the store with the new league
            await playerStoreInstance.initData(selectedLeagueId);
            // Close the league modal if it's open
            setShowLeagueModal(false);
        } catch (error) {
            console.error('Error changing league:', error);
        } finally {
            setLoading(false);
        }
    };

    // Initialize data when component mounts
    useEffect(() => {
        const initializeData = async () => {
            try {
                setLoading(true);
                if (playerStoreInstance.leagueId) {
                    await Promise.all([
                        playerStoreInstance.fetchUserInfo(playerStoreInstance.leagueId),
                        playerStoreInstance.fetchLeagueRanking(playerStoreInstance.leagueId)
                    ]);
                }
            } catch (error) {
                console.error('Error initializing data:', error);
            } finally {
                setLoading(false);
            }
        };

        initializeData();
    }, [playerStoreInstance.leagueId]);

    // Add this to track when userInfo changes
    useEffect(() => {
        console.log('👀 UserInfo updated in Header:', playerStoreInstance.userInfo);
    }, []);

    // Remove the updateUserInfo call that was causing the error
    useEffect(() => {
        if (!playerStoreInstance.userInfo && playerStoreInstance.leagueId) {
            console.log('No userInfo, fetching...');
            playerStoreInstance.fetchUserInfo(playerStoreInstance.leagueId);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        window.location.reload(); // to refresh the page
    };

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'transparent',
            border: 'none',
            padding: 0
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
        }
    };

    return useObserver(() => (
        <div className="bg-gray-800 text-white py-3  lg:py-2 lg:px-4 flex justify-between items-center">
            <div className="flex items-center space-x-4 md:order-none order-1 mt-2 md:mt-0">
                <img 
                    src={require('./analyticlogo.png')} 
                    className="w-40 h-15 md:block hidden" 
                    alt="Kickly Logo" 
                />
                <img 
                    src={require('./analyticlogo.png')} 
                    className="w-18 h-10 md:hidden block ml-[value]" 
                    alt="Analytic Logo" 
                />

                <div className="md:block hidden">
                    <LeagueSelector 
                        onLeagueChange={handleLeagueChange}
                        selectedLeagueId={playerStoreInstance.leagueId}
                    />
                </div>

                <button 
                    className="md:hidden bg-gray-700 p-1.5 rounded" 
                    onClick={() => setShowLeagueModal(true)}
                >
                    {(playerStoreInstance.leagues.find(l => l.id === playerStoreInstance.leagueId)?.name || 'Liga').slice(0, 5)}...
                </button>

                {/* Finance Button - Bank Icon */}
                <button 
                    className="bg-gray-700 hover:bg-gray-600 p-2.5 rounded-lg flex items-center justify-center transition-colors" 
                    onClick={() => setShowBudgetModal(true)}
                    title="Finanzen"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l9-4 9 4v2H3V6z"/>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18v8H3v-8z"/>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 15v-3M12 15v-3M17 15v-3"/>
                    </svg>
                </button>

                {/* Donate Button with Text */}
                <button 
                    className="bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded-lg flex items-center space-x-2 transition-colors" 
                    onClick={() => setShowLogoutModal(true)}
                    title="Unterstütze Kickly"
                >
                    <span className="text-sm font-medium">Spenden</span>
                </button>

                <div className="hidden md:block">
                    <button
                        onClick={() => setShowFeedbackModal(true)}
                        className="bg-orange-600 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in"
                    >
                        Feedback
                    </button>
                </div>

                {/* Notification Settings Button */}
                <div className="hidden md:block">
                    <button
                        onClick={() => setShowNotificationSettings(true)}
                        className="bg-gray-700 hover:bg-gray-600 p-2.5 rounded-lg flex items-center justify-center transition-colors"
                        title="Benachrichtigungseinstellungen"
                    >
                        <FontAwesomeIcon icon={faBell} className="text-white" />
                    </button>
                </div>

                {/* AI Analytics Button with Gemini Star Icon - Hidden due to data issues */}
                {/* <button
                    onClick={() => setShowAIModal(true)}
                    className={`relative bg-blue-600 hover:bg-blue-700 p-2.5 rounded-lg flex items-center justify-center transition-colors ${aiLoading ? 'cursor-wait' : ''}`}
                    title="KI Analyse"
                    disabled={aiLoading}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${aiLoading ? 'opacity-0' : ''}`} viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 1L9 9l-8 3 8 3 3 8 3-8 8-3-8-3-3-8z" />
                    </svg>
                    {aiLoading && (
                        <div className="absolute inset-0 flex items-center justify-center">
                            <ClipLoader color="#ffffff" size={20} />
                        </div>
                    )}
                </button> */}

                {/* Desktop Donate Button */}
                <div className="hidden md:block">
                    <button 
                        onClick={() => window.location.href = "https://www.paypal.com/paypalme/kaiDetmers"} 
                        className="bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded-lg flex items-center space-x-2 transition-colors"
                        title="Unterstütze Kickly"
                    >
                        <span className="text-sm font-medium">Spenden</span>
                    </button>
                </div>
            </div>
            {
                user ? (
                    <div className="relative flex items-center justify-center space-x-4 md:flex-row md:block hidden">
                        {
                            loading ? (
                                <ClipLoader color={"#ff4601"} size={30} />
                            ) : (
                                <>
                                    <div className="flex items-center space-x-4">
                                        {/* User Info & Dropdown */}

                                        < div className="hidden md:block" >
                                            <button onClick={() => window.location.href = "https://www.paypal.com/paypalme/kaiDetmers"} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in">
                                                Spenden
                                            </button>
                                        </div >
                                        {/* Financial Cards */}
                                        < div className="finance-container space-x-4" >
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Kontostand</span>
                                                <span className="block text-lg">{formatCurrency(budget)}</span>
                                            </div>
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Teamwert</span>
                                                <span className="block text-lg">{formatCurrency(teamValue)}</span>
                                            </div>
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Spieler</span>
                                                <span className="block text-lg">{totalPlayers} / {maxPlayers}</span>
                                            </div>
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Liga</span>
                                                <span className="block text-lg">{leagueName.slice(0, 6)}...</span>
                                            </div>
                                        </div >
                                        <div className="flex items-center space-x-2">
                                            <span className="mr-2">{user.name}</span>
                                            <img src={user.profile} alt="User Profile" className="w-8 h-8 rounded-full cursor-pointer" onClick={() => setDropdownOpen(!dropdownOpen)} />
                                        </div>
                                    </div >

                                    {/* View Details button */}
                                    < button className="md:hidden bg-gray-700 p-2 rounded" onClick={() => setShowBudgetModal(true)}> Details</button >
                                    {dropdownOpen && (
                                        <div className="absolute right-1 ease-in-out w-38 bg-gray-700 text-white shadow-lg rounded z-10">
                                            <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-gray-600">Abmelden</button>
                                        </div>
                                    )}
                                </>
                            )}
                    </div >
                ) : (
                    <div></div>
                )}

            {
                showLeagueModal && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-80 flex justify-center items-center z-50 md:hidden">
                        <div className="bg-gray-700 p-4 rounded">
                            <LeagueSelector 
                                onLeagueChange={handleLeagueChange}
                                selectedLeagueId={playerStoreInstance.leagueId}
                            />
                            <button 
                                onClick={() => setShowLeagueModal(false)} 
                                className="mt-4 block mx-auto bg-red-500 p-2 rounded text-white"
                            >
                                Schließen
                            </button>
                        </div>
                    </div>
                )
            }

            {
                showLogoutModal && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-80 flex justify-center items-center z-50 md:hidden">
                        <div className="bg-gray-700 p-6 rounded-lg shadow-lg text-white max-w-md w-full mx-4">
                            <div className="text-center mb-6">
                                <h3 className="text-xl font-bold mb-3">Hallo liebe Community! 😊</h3>
                                <p className="text-gray-300 text-sm">
                                    Ich habe dieses Tool mit viel Liebe und Mühe für euch entwickelt. 
                                    Es ist kostenlos, aber es fallen laufende Kosten für mnich an. Vielleicht mögt ich mich ja unterstützen :).
                                </p>
                            </div>

                            {/* Prominent Donation Button */}
                            <div className="mb-6">
                                <button 
                                    onClick={() => window.location.href = "https://www.paypal.com/paypalme/kaiDetmers"} 
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg transition duration-200 ease-in w-full text-lg shadow-lg"
                                >
                                    ☕️ Kauf mir einen Kaffee
                                </button>
                            </div>

                            {/* 2-Column Grid for other buttons */}
                            <div className="grid grid-cols-2 gap-3">
                                <button 
                                    onClick={() => {
                                        setShowLogoutModal(false);
                                        setShowFeedbackModal(true);
                                    }}
                                    className="bg-orange-600 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in"
                                >
                                    Feedback
                                </button>
                                <button 
                                    onClick={onLogout}
                                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in"
                                >
                                    Ausloggen
                                </button>
                                <button 
                                    onClick={() => setShowLogoutModal(false)}
                                    className="col-span-2 bg-gray-600 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in"
                                >
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }


            {
                showBudgetModal && <BudgetModal />
            }

            <Modal
                isOpen={showFeedbackModal}
                onRequestClose={() => setShowFeedbackModal(false)}
                style={customModalStyles}
                contentLabel="Feedback Modal"
            >
                <FeedbackForm onClose={() => setShowFeedbackModal(false)} isModal={true} />
            </Modal>

            {showAIModal && (
                <AIAnalytics 
                    onClose={() => setShowAIModal(false)} 
                    onLoadingChange={setAiLoading}
                />
            )}

            <NotificationSettingsDialog />
        </div >
    ));
};


export default Header;




