import React, { createContext, useState, useContext, useCallback, useRef } from 'react';
import { doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { firestore } from '../firebase';

// Global cache to track fetch timestamps and data
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds
const fetchTimeCache = {};
const dataCache = {
  votes: {},
  userVotes: {}
};

const VoteContext = createContext();

export const VoteProvider = ({ children }) => {
  const [votes, setVotes] = useState({});
  const [userVotes, setUserVotes] = useState({});
  const [isLoading, setIsLoading] = useState({});
  // Use a ref to track pending requests to avoid duplicates
  const pendingRequests = useRef({});
  // Use a ref to track if we should update state to prevent loops
  const isInitialMount = useRef(true);

  const fetchVotes = useCallback(async (playerId) => {
    if (!playerId) return;
    
    // Check cache timestamp first
    const now = Date.now();
    const cacheKey = `votes-${playerId}`;
    const lastFetchTime = fetchTimeCache[cacheKey] || 0;
    
    // If data is cached and still fresh, use it
    if (now - lastFetchTime < CACHE_EXPIRY && dataCache.votes[playerId]) {
      // Only update component state if this is the initial mount or if data has changed
      const cachedData = dataCache.votes[playerId];
      const currentData = votes[playerId];
      
      const hasChanged = !currentData || 
        currentData.upvotes !== cachedData.upvotes || 
        currentData.downvotes !== cachedData.downvotes;
      
      if (isInitialMount.current || hasChanged) {
        setVotes(prev => ({
          ...prev,
          [playerId]: cachedData
        }));
      }
      return;
    }
    
    // Check if request is already pending
    if (pendingRequests.current[cacheKey]) return;
    pendingRequests.current[cacheKey] = true;

    try {
      const voteRef = doc(firestore, 'playerVotes', playerId);
      const voteDoc = await getDoc(voteRef);
      
      if (voteDoc.exists()) {
        const voteData = voteDoc.data();
        const formattedData = {
          upvotes: voteData.upvotes || 0,
          downvotes: voteData.downvotes || 0
        };
        
        // Update the cache
        dataCache.votes[playerId] = formattedData;
        fetchTimeCache[cacheKey] = now;
        
        // Check if state update is needed
        const currentData = votes[playerId];
        const hasChanged = !currentData || 
          currentData.upvotes !== formattedData.upvotes || 
          currentData.downvotes !== formattedData.downvotes;
          
        if (isInitialMount.current || hasChanged) {
          setVotes(prev => ({
            ...prev,
            [playerId]: formattedData
          }));
        }
      } else {
        // Create initial document if it doesn't exist
        const initialData = { upvotes: 0, downvotes: 0 };
        await setDoc(voteRef, initialData);
        
        // Update the cache
        dataCache.votes[playerId] = initialData;
        fetchTimeCache[cacheKey] = now;
        
        // Check if state needs updating
        if (isInitialMount.current || !votes[playerId]) {
          setVotes(prev => ({
            ...prev,
            [playerId]: initialData
          }));
        }
      }
    } catch (error) {
      // Silent error in production
    } finally {
      pendingRequests.current[cacheKey] = false;
      isInitialMount.current = false;
    }
  }, [votes]);

  const fetchUserVote = useCallback(async (playerId, userId) => {
    if (!playerId || !userId) return;
    
    // Check cache timestamp first
    const now = Date.now();
    const cacheKey = `userVotes-${userId}-${playerId}`;
    const lastFetchTime = fetchTimeCache[cacheKey] || 0;
    
    // If data is cached and still fresh, use it
    if (now - lastFetchTime < CACHE_EXPIRY && dataCache.userVotes[`${userId}_${playerId}`] !== undefined) {
      // Only update state if the value has changed
      const cachedValue = dataCache.userVotes[`${userId}_${playerId}`];
      const currentValue = userVotes[playerId];
      
      if (isInitialMount.current || cachedValue !== currentValue) {
        setUserVotes(prev => ({
          ...prev,
          [playerId]: cachedValue
        }));
      }
      return;
    }
    
    // Check if request is already pending
    if (pendingRequests.current[cacheKey]) return;
    pendingRequests.current[cacheKey] = true;

    try {
      const userVoteRef = doc(firestore, 'userVotes', `${userId}_${playerId}`);
      const userVoteDoc = await getDoc(userVoteRef);
      
      if (userVoteDoc.exists()) {
        const voteData = userVoteDoc.data().vote;
        
        // Update the cache
        dataCache.userVotes[`${userId}_${playerId}`] = voteData;
        fetchTimeCache[cacheKey] = now;
        
        // Check if state update is needed
        if (isInitialMount.current || userVotes[playerId] !== voteData) {
          setUserVotes(prev => ({
            ...prev,
            [playerId]: voteData
          }));
        }
      } else {
        // No vote exists yet - set cache with null value
        dataCache.userVotes[`${userId}_${playerId}`] = null;
        fetchTimeCache[cacheKey] = now;
        
        // Only update state if needed
        if (isInitialMount.current || userVotes[playerId] !== null) {
          setUserVotes(prev => ({
            ...prev,
            [playerId]: null
          }));
        }
      }
    } catch (error) {
      // Silent error in production
    } finally {
      pendingRequests.current[cacheKey] = false;
      isInitialMount.current = false;
    }
  }, [userVotes]);

  const handleVote = async (playerId, userId, voteType) => {
    if (!userId || !playerId || isLoading[playerId]) return;
    
    setIsLoading(prev => ({ ...prev, [playerId]: true }));
    
    try {
      const voteRef = doc(firestore, 'playerVotes', playerId);
      const userVoteRef = doc(firestore, 'userVotes', `${userId}_${playerId}`);
      const currentUserVote = userVotes[playerId];
      
      // If clicking the same vote type, remove the vote
      if (currentUserVote === voteType) {
        await updateDoc(voteRef, {
          [`${voteType}s`]: increment(-1)
        });
        
        await setDoc(userVoteRef, { 
          vote: null,
          timestamp: new Date()
        });
        
        // Update local state
        setUserVotes(prev => ({
          ...prev,
          [playerId]: null
        }));
        
        // Update votes count state
        setVotes(prev => {
          const newState = {
            ...prev,
            [playerId]: {
              ...prev[playerId],
              [`${voteType}s`]: Math.max(0, prev[playerId][`${voteType}s`] - 1)
            }
          };
          
          // Update cache
          dataCache.votes[playerId] = {...newState[playerId]};
          dataCache.userVotes[`${userId}_${playerId}`] = null;
          
          return newState;
        });
      } else {
        const updates = {};
        
        if (currentUserVote) {
          updates[`${currentUserVote}s`] = increment(-1);
        }
        updates[`${voteType}s`] = increment(1);
        
        await updateDoc(voteRef, updates);
        
        await setDoc(userVoteRef, {
          vote: voteType,
          timestamp: new Date()
        });
        
        // Update local state
        setUserVotes(prev => ({
          ...prev,
          [playerId]: voteType
        }));
        
        // Update votes count state
        setVotes(prev => {
          const newState = {
            ...prev,
            [playerId]: {
              ...prev[playerId],
              [`${currentUserVote}s`]: currentUserVote ? Math.max(0, prev[playerId][`${currentUserVote}s`] - 1) : prev[playerId][`${currentUserVote}s`] || 0,
              [`${voteType}s`]: (prev[playerId][`${voteType}s`] || 0) + 1
            }
          };
          
          // Update cache
          dataCache.votes[playerId] = {...newState[playerId]};
          dataCache.userVotes[`${userId}_${playerId}`] = voteType;
          
          return newState;
        });
      }
      
      // Update cache timestamps
      fetchTimeCache[`votes-${playerId}`] = Date.now();
      fetchTimeCache[`userVotes-${userId}-${playerId}`] = Date.now();
      
    } catch (error) {
      // Silent error handling in production
    } finally {
      setIsLoading(prev => ({ ...prev, [playerId]: false }));
    }
  };

  return (
    <VoteContext.Provider value={{ 
      votes,
      userVotes,
      isLoading,
      fetchVotes,
      fetchUserVote,
      handleVote
    }}>
      {children}
    </VoteContext.Provider>
  );
};

export const useVotes = () => useContext(VoteContext);
