import { VAPID_KEY } from '../firebase/config';
import { onMessage } from 'firebase/messaging';

class NotificationService {
  constructor() {
    // Safely check for notification support
    this.notificationsSupported = typeof window !== 'undefined' && 'Notification' in window;
    this.serviceWorkerSupported = typeof navigator !== 'undefined' && 'serviceWorker' in navigator;
    this.messagingSupported = false; // Will be set when initialized
    this.initialized = false;
    
    // Keep track of the current token
    this.currentToken = null;
    
    // Log detection results
    console.log('Notification support check:', {
      notificationsSupported: this.notificationsSupported,
      serviceWorkerSupported: this.serviceWorkerSupported,
      isMobile: this.isMobileDevice(),
      isIOS: this.isIOSDevice(),
      isPWA: this.isPWA()
    });
  }
  
  isMobileDevice() {
    return typeof navigator !== 'undefined' && 
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  
  isIOSDevice() {
    return typeof navigator !== 'undefined' &&
      /iPhone|iPad|iPod/i.test(navigator.userAgent) &&
      !window.MSStream; // Excludes IE11
  }
  
  // Check if running as installed PWA
  isPWA() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const isIOSStandalone = window.navigator.standalone === true;
    const isAndroidApp = document.referrer.includes('android-app://');
    
    const result = isStandalone || isIOSStandalone || isAndroidApp;
    console.log('PWA detection:', { isStandalone, isIOSStandalone, isAndroidApp, result });
    
    return result;
  }

  // Check if this iOS device might support web push (iOS 16.4+)
  mightSupportIOSWebPush() {
    if (!this.isIOSDevice()) return false;
    
    const iosVersion = this.getIOSVersion();
    const isPwaMode = this.isPWA();
    
    // iOS 16.4+ supports web push in PWA mode
    const supportsWebPush = iosVersion && 
      ((iosVersion.major > 16) || (iosVersion.major === 16 && iosVersion.minor >= 4)) && 
      isPwaMode;
    
    console.log('iOS web push support check:', { 
      iosVersion, 
      isPwaMode,
      supportsWebPush
    });
    
    return supportsWebPush;
  }

  async initialize() {
    if (this.initialized) return;

    // Don't attempt to initialize if notifications or service workers aren't supported
    if (!this.notificationsSupported || !this.serviceWorkerSupported) {
      console.warn("Notifications or Service Workers not supported in this browser");
      this.initialized = true; // Mark as initialized even if we can't fully initialize
      return;
    }

    try {
      // Import Firebase messaging only if we have the required browser features
      const { getMessaging } = await import('firebase/messaging');
      const { firebaseApp } = await import('../firebase/config');
      
      // Initialize messaging
      if (firebaseApp) {
        this.messaging = getMessaging(firebaseApp);
        this.messagingSupported = !!this.messaging;
      }
      
      this.initialized = true;
      console.log("Notification service initialized successfully");
    } catch (error) {
      console.error("Error initializing notification service:", error);
      this.initialized = true; // Mark as initialized even if we failed
    }
  }

  async requestPermission() {
    // Ensure we've attempted to initialize
    if (!this.initialized) {
      await this.initialize();
    }

    // For mobile devices where the Notification API isn't supported
    if (!this.notificationsSupported) {
      if (this.isIOSDevice() && this.mightSupportIOSWebPush()) {
        throw new Error('To enable notifications on iOS, please add this app to your home screen first');
      } else if (this.isMobileDevice()) {
        throw new Error('Notifications are not supported on this mobile device');
      } else {
        throw new Error('Notifications are not supported in this browser');
      }
    }

    console.log("Requesting notification permission...");
    
    // Safely get permission
    try {
      // Check if permission is already denied
      if (window.Notification.permission === 'denied') {
        console.warn("Notification permission was previously denied");
        
        // Inform the user they need to reset permissions
        throw new Error('Notification permission was previously denied. Please reset permissions in your browser settings.');
      }
      
      const permission = await window.Notification.requestPermission();
      console.log("Notification permission result:", permission);
      
      if (permission !== 'granted') {
        throw new Error('Notification permission denied');
      }
      
      return permission;
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      throw error;
    }
  }

  // Add a new method to check if permissions need to be reset
  needsPermissionReset() {
    return this.notificationsSupported && window.Notification.permission === 'denied';
  }

  // Add a method to guide users on how to reset permissions
  getPermissionResetInstructions() {
    let browser = 'your browser';
    let instructions = '';
    
    // Detect browser
    if (navigator.userAgent.includes('Chrome')) {
      browser = 'Chrome';
      instructions = 'Go to Settings > Privacy and Security > Site Settings > Notifications, find this site and click "Reset permissions"';
    } else if (navigator.userAgent.includes('Firefox')) {
      browser = 'Firefox';
      instructions = 'Go to Settings > Privacy & Security > Permissions > Notifications, find this site and remove it from the list';
    } else if (navigator.userAgent.includes('Safari')) {
      browser = 'Safari';
      instructions = 'Go to Safari > Preferences > Websites > Notifications, find this site and change the setting';
    } else if (navigator.userAgent.includes('Edge')) {
      browser = 'Edge';
      instructions = 'Go to Settings > Cookies and site permissions > Notifications, find this site and click "Reset permissions"';
    }
    
    return {
      browser,
      instructions,
      isPWA: this.isPWA()
    };
  }

  // Add a method to force a permission request (should be called from a user action)
  async forcePermissionRequest() {
    if (!this.notificationsSupported) {
      throw new Error('Notifications are not supported in this browser');
    }
    
    // For PWAs, we can try to use the Permissions API to request again
    if (this.isPWA() && navigator.permissions) {
      try {
        // Query the notification permission status
        const permissionStatus = await navigator.permissions.query({ name: 'notifications' });
        
        // Log the current state
        console.log('Current notification permission state:', permissionStatus.state);
        
        // If permission is denied, we need to guide the user to reset
        if (permissionStatus.state === 'denied') {
          const resetInfo = this.getPermissionResetInstructions();
          throw new Error(`Notifications are blocked. Please go to ${resetInfo.browser} settings to allow notifications.`);
        }
        
        // If permission is prompt, we can request again
        if (permissionStatus.state === 'prompt') {
          return this.requestPermission();
        }
        
        return permissionStatus.state;
      } catch (error) {
        console.error('Error with Permissions API:', error);
        // Fall back to standard request
        return this.requestPermission();
      }
    }
    
    // Standard browsers - just try to request again
    return this.requestPermission();
  }

  async getToken() {
    // Ensure we've attempted to initialize
    if (!this.initialized) {
      await this.initialize();
    }

    // Check support and permissions first
    const supportInfo = this.isSupported();
    if (!supportInfo.supported) {
      if (this.isIOSDevice() && this.mightSupportIOSWebPush()) {
        throw new Error('To enable notifications on iOS, please add this app to your home screen first');
      } else if (supportInfo.isMobile) {
        throw new Error('Notifications are not supported on this mobile device');
      } else {
        throw new Error('Notifications are not fully supported in this browser');
      }
    }

    if (supportInfo.permission !== 'granted') {
      throw new Error('Notification permission not granted');
    }

    try {
      const { getToken } = await import('firebase/messaging');
      const token = await getToken(this.messaging, {
        vapidKey: VAPID_KEY
      });
      
      if (token) {
        console.log("FCM token retrieved successfully:", token);
        return token;
      } else {
        throw new Error('Failed to get FCM token');
      }
    } catch (error) {
      console.error("Error getting FCM token:", error);
      throw error;
    }
  }

  onMessageListener() {
    return new Promise((resolve) => {
      if (!this.messaging) {
        console.warn('Firebase messaging is not available, cannot listen for messages');
        resolve(null);
        return;
      }
      
      try {
        console.log('Setting up foreground message listener');
        
        return onMessage(this.messaging, (payload) => {
          console.log('Foreground message received:', payload);
          
          // Check message format
          if (!payload.notification) {
            console.warn('Received message has no notification property:', payload);
          }
          
          // For iOS, we need to ensure notifications are shown regardless of PWA status
          if (this.isIOSDevice()) {
            try {
              console.log('iOS device detected, manually showing notification');
              
              // Show a notification manually for iOS
              if ('Notification' in window && payload.notification) {
                const title = payload.notification.title || 'Neue Nachricht';
                const options = {
                  body: payload.notification.body || '',
                  icon: payload.notification.icon || '/logo192.png',
                  badge: '/logo192.png',
                  data: {
                    ...(payload.data || {}),
                    timestamp: Date.now(),
                    received: 'foreground'
                  },
                  tag: payload.data?.playerId || 'default',
                  vibrate: [200, 100, 200] // Add vibration for devices that support it
                };
                
                // Show the notification
                const notification = new Notification(title, options);
                
                // Set up notification click handler for iOS
                notification.onclick = function() {
                  console.log('Notification clicked on iOS');
                  notification.close();
                  
                  // Navigate if the app is in the foreground
                  const baseUrl = payload.data?.baseUrl || window.location.origin;
                  const urlToOpen = payload.data?.url || `${baseUrl}/market`;
                  
                  // Focus window and navigate if needed
                  window.focus();
                  if (window.location.href !== urlToOpen) {
                    window.location.href = urlToOpen;
                  }
                };
                
                console.log('Manual notification displayed on iOS');
              } else {
                console.warn('Cannot show notification: Notification API unavailable or payload missing notification data');
              }
            } catch (error) {
              console.error('Error showing manual notification on iOS:', error);
            }
          }
          
          // For debugging, track notification in session storage
          try {
            // Store up to 10 recent notifications for debugging
            const recentNotifications = JSON.parse(sessionStorage.getItem('recentNotifications') || '[]');
            recentNotifications.push({
              timestamp: new Date().toISOString(),
              title: payload.notification?.title,
              body: payload.notification?.body,
              playerId: payload.data?.playerId,
              received: 'foreground'
            });
            
            // Keep only the 10 most recent notifications
            if (recentNotifications.length > 10) {
              recentNotifications.splice(0, recentNotifications.length - 10);
            }
            
            sessionStorage.setItem('recentNotifications', JSON.stringify(recentNotifications));
          } catch (storageError) {
            console.error('Error storing notification in session storage:', storageError);
          }
          
          resolve(payload);
        });
      } catch (error) {
        console.error('Error setting up message listener:', error);
        resolve(null);
      }
    });
  }
  
  // Method to get recent notifications for debugging
  getRecentNotifications() {
    try {
      return JSON.parse(sessionStorage.getItem('recentNotifications') || '[]');
    } catch (error) {
      console.error('Error retrieving recent notifications:', error);
      return [];
    }
  }
  
  // More robust iOS version detection
  getIOSVersion() {
    if (!this.isIOSDevice()) return null;
    
    try {
      // Get user agent
      const userAgent = navigator.userAgent;
      console.log('Detecting iOS version from user agent:', userAgent);
      
      // Try different patterns to extract iOS version
      // Pattern for iPhone/iPad/iPod: "OS X_Y"
      let match = userAgent.match(/OS\s+(\d+)[._](\d+)/i);
      
      if (!match) {
        // Pattern for iPadOS: "Version/X.Y"
        match = userAgent.match(/Version\/(\d+)\.(\d+)/i);
      }
      
      if (match && match.length >= 3) {
        const majorVersion = parseInt(match[1], 10);
        const minorVersion = parseInt(match[2], 10);
        
        console.log(`Detected iOS version: ${majorVersion}.${minorVersion}`);
        return { major: majorVersion, minor: minorVersion };
      }
      
      console.warn('Could not detect iOS version from user agent:', userAgent);
      return null;
    } catch (error) {
      console.error('Error detecting iOS version:', error);
      return null;
    }
  }
  
  // Check if notifications are supported and available
  isSupported() {
    const hasNotificationSupport = typeof window !== 'undefined' && 'Notification' in window;
    const hasServiceWorkerSupport = typeof navigator !== 'undefined' && 'serviceWorker' in navigator;
    const hasMessagingSupport = this.messagingSupported;
    const isMobile = this.isMobileDevice();
    const isIOS = this.isIOSDevice();
    const isPWA = this.isPWA();
    const iosWebPushPossible = this.mightSupportIOSWebPush();
    
    return {
      supported: hasNotificationSupport && hasServiceWorkerSupport && hasMessagingSupport,
      hasNotificationSupport,
      hasServiceWorkerSupport,
      hasMessagingSupport,
      isMobile,
      isIOS,
      isPWA,
      iosWebPushPossible,
      permission: hasNotificationSupport ? window.Notification.permission : 'unavailable'
    };
  }
}

// Create and export a singleton instance
const notificationService = new NotificationService();
export default notificationService; 