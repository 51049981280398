import { initializeApp, getApp } from 'firebase/app';
// Remove static imports to implement dynamic loading
// import { getMessaging } from 'firebase/messaging';
// import { getFirestore } from 'firebase/firestore';
// import { getFunctions } from 'firebase/functions';
// import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCSKl6kC3tRFdHJJbOrAohZPVv7bL4bDa8",
  authDomain: "kickbase-analytic.firebaseapp.com",
  databaseURL: "https://kickbase-analytic-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kickbase-analytic",
  storageBucket: "kickbase-analytic.firebasestorage.app",
  messagingSenderId: "711239456594",
  appId: "1:711239456594:web:44ceca1905dec0c24c8c8b",
  measurementId: "G-RX05XRD78G"
};

// Web Push certificate (VAPID key)
export const VAPID_KEY = "BBqOFeyIIUSaF8OZbJpHuxJFsNL66kxBgjb3y28dQr6cmh2flCtORf07r35TzehvE65I3cLHJRYeLjXcPkNhJeE";

let firebaseApp;
try {
  firebaseApp = getApp();
  console.log('Using existing Firebase app');
} catch {
  firebaseApp = initializeApp(firebaseConfig);
  console.log('Initialized new Firebase app');
}

// Declare service instances that will be lazily initialized
let messagingInstance = null;
let firestoreInstance = null;
let functionsInstance = null;
let analyticsInstance = null;

// Create service accessors that use dynamic imports
const getFirestoreInstance = async () => {
  if (!firestoreInstance) {
    try {
      const { getFirestore } = await import('firebase/firestore');
      firestoreInstance = getFirestore(firebaseApp);
      console.log('Firestore initialized dynamically');
    } catch (error) {
      console.error('Error loading Firestore module:', error);
      return null;
    }
  }
  return firestoreInstance;
};

const getMessagingInstance = async () => {
  // Skip messaging on unsupported browsers
  if (!('serviceWorker' in navigator)) return null;
  
  if (!messagingInstance) {
    try {
      // Log environment details for debugging
      const hasWindow = typeof window !== 'undefined';
      const hasNavigator = typeof navigator !== 'undefined';
      const hasServiceWorker = hasNavigator && 'serviceWorker' in navigator;
      const hasNotification = hasWindow && 'Notification' in window;
      const hasPushManager = hasWindow && 'PushManager' in window;
      const isSafari = hasNavigator && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const isIOS = hasNavigator && /iPhone|iPad|iPod/i.test(navigator.userAgent) && !window.MSStream;
      const isPWA = hasWindow && (window.matchMedia('(display-mode: standalone)').matches || 
                                (hasNavigator && navigator.standalone === true));
      
      // Log the state of critical requirements for push notifications
      console.log('Push Notification Requirements Check:', {
        hasServiceWorkerSupport: hasServiceWorker,
        hasNotificationSupport: hasNotification,
        hasPushManagerSupport: hasPushManager,
        notificationPermission: hasNotification ? window.Notification.permission : 'unavailable',
        isSafari,
        isIOS,
        isPWA,
        isHttps: hasWindow && (window.location.protocol === 'https:' || window.location.hostname === 'localhost'),
        serviceWorkerControlling: hasServiceWorker && navigator.serviceWorker.controller !== null
      });
      
      const { getMessaging } = await import('firebase/messaging');
      messagingInstance = getMessaging(firebaseApp);
      console.log('Firebase Messaging initialized dynamically');
    } catch (error) {
      console.error('Error loading Messaging module:', error);
      return null;
    }
  }
  return messagingInstance;
};

const getFunctionsInstance = async () => {
  if (!functionsInstance) {
    try {
      const { getFunctions } = await import('firebase/functions');
      functionsInstance = getFunctions(firebaseApp, 'europe-west1');
      console.log('Firebase Functions initialized dynamically with region: europe-west1');
    } catch (error) {
      console.error('Error loading Functions module:', error);
      return null;
    }
  }
  return functionsInstance;
};

const getAnalyticsInstance = async () => {
  if (!analyticsInstance) {
    try {
      const { getAnalytics } = await import('firebase/analytics');
      analyticsInstance = getAnalytics(firebaseApp);
      console.log('Analytics initialized dynamically');
    } catch (error) {
      console.error('Error loading Analytics module:', error);
      return null;
    }
  }
  return analyticsInstance;
};

// Initialize critical services right away (but async)
getFirestoreInstance();

console.log('Firebase config initialized with modular imports', {
  ...firebaseConfig,
  apiKey: '***',
  messagingSenderId: '***',
  region: 'europe-west1'
});

// Export for backward compatibility (these will be lazily loaded)
export { 
  firebaseApp, 
  messagingInstance as messaging, 
  firestoreInstance as firestore, 
  functionsInstance as functions,
  analyticsInstance as analytics,
  // Export the async accessors
  getFirestoreInstance,
  getMessagingInstance,
  getFunctionsInstance,
  getAnalyticsInstance,
  firebaseConfig
}; 