import { firebaseApp, messaging, firestore, functions, analytics, VAPID_KEY } from './firebase/config';
import { getToken, onMessage } from 'firebase/messaging';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

// Development mode settings
const DEV_MODE = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const DEV_FEATURES = {
  mockFcmTokens: DEV_MODE,  // Use mock FCM tokens in dev mode
  skipTokenValidation: DEV_MODE,  // Skip FCM token validation in dev mode
  preferHttpEndpoint: DEV_MODE,  // Always use HTTP endpoint in dev mode
  enableDetailedLogs: DEV_MODE,  // Enable more detailed logging in dev mode
  useDirectNotifications: true    // Always use direct notifications on localhost
};

// Log development mode settings
if (DEV_MODE) {
  console.log('🧪 Running in DEVELOPMENT mode with features:', DEV_FEATURES);
}

// Log the current environment
const isLocalhost = window.location.hostname === 'localhost';
console.log(`Running on ${isLocalhost ? 'localhost' : 'kickly.de'}`);

// Mock functionality for testing locally without emulator
const useMocksForLocalDevelopment = false; // Set to false to disable mocks
const mockFunctionsEnabled = isLocalhost && useMocksForLocalDevelopment;

// Global flag to track if httpsCallable has failed before (to avoid retrying on every call)
let httpsCallableFailed = false;

// Check localStorage for previous failures
try {
  httpsCallableFailed = localStorage.getItem('httpsCallable_failed') === 'true';
} catch (e) {
  // Ignore localStorage errors
}

// Function to mark httpsCallable as failed
const markHttpsCallableFailed = () => {
  httpsCallableFailed = true;
  try {
    localStorage.setItem('httpsCallable_failed', 'true');
  } catch (e) {
    // Ignore localStorage errors
  }
};

// Function to mark httpsCallable as working
const markHttpsCallableWorking = () => {
  httpsCallableFailed = false;
  try {
    localStorage.removeItem('httpsCallable_failed');
  } catch (e) {
    // Ignore localStorage errors
  }
};

// Helper function to mock function calls locally
const mockCloudFunction = (name, mockResponse) => {
  return async (data) => {
    console.log(`MOCK: Called ${name} with data:`, data);
    
    // Simulate network delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    if (mockResponse) {
      if (typeof mockResponse === 'function') {
        return { data: await mockResponse(data) };
      }
      return { data: mockResponse };
    }
    
    return { data: { success: true, mock: true } };
  };
};

// Add this function before the scheduleNotification function:
// Mock implementation of notification scheduling for local development
export const mockScheduleNotification = async (data) => {
  console.log('MOCK: Scheduling notification with data:', data);
  
  // Validate all the required fields
  if (!data.playerId || !data.playerName || !data.notificationTime || !data.expirationTime) {
    throw new Error('Missing required notification data');
  }
  
  // Add notification to localStorage for "persistence"
  try {
    const storedNotifications = JSON.parse(localStorage.getItem('mock_notifications') || '[]');
    const notificationId = `mock-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    
    storedNotifications.push({
      id: notificationId,
      ...data,
      createdAt: Date.now()
    });
    
    localStorage.setItem('mock_notifications', JSON.stringify(storedNotifications));
    
    // Return mock success response
    return {
      success: true,
      notificationId: notificationId,
      mock: true
    };
  } catch (error) {
    console.error('Error storing mock notification:', error);
    throw new Error('Failed to store mock notification');
  }
};

// Try to connect to the emulator in development, but fallback to production if not available
if (isLocalhost) {
  try {
    // First check if the emulator is actually running by making a simple fetch request
    fetch('http://localhost:5001/kickbase-analytic/europe-west1/', { 
      method: 'HEAD',
      timeout: 1000 // Short timeout since we're just checking availability
    })
    .then(response => {
      // If successful, connect to the emulator
      connectFunctionsEmulator(functions, 'localhost', 5001);
      console.log('Connected to Firebase Functions emulator on port 5001');
    })
    .catch(error => {
      console.warn('Firebase Functions emulator not detected, using production endpoints:', error.message);
      // No connection to emulator needed, will use production automatically
    });
  } catch (error) {
    console.warn('Failed to connect to Functions emulator, using production endpoints:', error);
    // Will use production endpoints
  }
}

// Enhanced testNotification function that shows a notification immediately
export const testNotification = async (options = {}) => {
  console.log('🧪 Testing notification display...');
  
  try {
    // Check if notification permission is granted
    if (Notification.permission !== 'granted') {
      console.log('🔔 Requesting notification permission...');
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.error('❌ Notification permission denied');
        return { success: false, error: 'Notification permission denied' };
      }
    }
    
    // Create and show a test notification
    const notificationTitle = options.title || 'Test Notification';
    const notificationOptions = {
      body: options.body || 'This is a test notification from Kickly',
      icon: '/logo192.png',
      badge: '/logo192.png',
      tag: options.tag || 'test-notification',
      data: {
        test: true,
        ...options.data,
        timestamp: Date.now()
      },
      requireInteraction: true,
      ...options
    };
    
    if (!('Notification' in window)) {
      console.error('❌ Notifications not supported in this browser');
      return { success: false, error: 'Notifications not supported' };
    }
    
    // Display the notification directly
    console.log('📱 Creating notification with options:', notificationOptions);
    const notification = new Notification(notificationTitle, notificationOptions);
    
    notification.onclick = () => {
      console.log('🖱️ Test notification clicked');
      notification.close();
      window.focus();
    };
    
    console.log('✅ Test notification displayed successfully');
    return { success: true };
  } catch (error) {
    console.error('❌ Error displaying test notification:', error);
    return { success: false, error: error.message };
  }
};

// Improved service worker registration
export const registerServiceWorker = async () => {
  console.log('🔄 Attempting to register service worker...');
  
  if (!('serviceWorker' in navigator)) {
    console.error('❌ Service Worker not supported in this browser');
    return { success: false, error: 'Service Worker not supported' };
  }
  
  try {
    // First, check for any existing service worker registrations
    const registrations = await navigator.serviceWorker.getRegistrations();
    
    console.log(`📋 Found ${registrations.length} existing service worker registrations`);
    
    // Log details about existing registrations
    registrations.forEach((reg, index) => {
      console.log(`Service Worker #${index + 1}:`, {
        scope: reg.scope,
        active: !!reg.active,
        installing: !!reg.installing,
        waiting: !!reg.waiting,
        state: reg.active?.state || 'no active worker'
      });
    });
    
    // Find our Firebase messaging service worker specifically
    const existingMessagingWorker = registrations.find(reg => 
      reg.scope.includes(window.location.origin) && 
      (reg.active || reg.installing || reg.waiting)
    );
    
    if (existingMessagingWorker) {
      console.log('✅ Using existing Firebase messaging service worker', existingMessagingWorker);
      
      // If the worker is not active yet, wait for it
      if (existingMessagingWorker.installing || existingMessagingWorker.waiting) {
        console.log('⏳ Waiting for service worker to become active...');
        
        await new Promise(resolve => {
          if (existingMessagingWorker.installing) {
            existingMessagingWorker.installing.addEventListener('statechange', e => {
              console.log('Service worker state changed to:', e.target.state);
              if (e.target.state === 'activated') resolve();
            });
          } else if (existingMessagingWorker.waiting) {
            existingMessagingWorker.waiting.addEventListener('statechange', e => {
              console.log('Service worker state changed to:', e.target.state);
              if (e.target.state === 'activated') resolve();
            });
          }
        });
        
        console.log('✅ Service worker is now active');
      }
      
      return { success: true, registration: existingMessagingWorker };
    }
    
    // If no existing worker found, register a new one
    console.log('🆕 Registering new Firebase messaging service worker');
    
    const newRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
      scope: '/'
    });
    
    console.log('✅ New service worker registered with scope:', newRegistration.scope);
    
    // Wait for the service worker to be active
    if (newRegistration.installing) {
      console.log('⏳ Waiting for new service worker to become active...');
      
      await new Promise(resolve => {
        newRegistration.installing.addEventListener('statechange', e => {
          console.log('New service worker state changed to:', e.target.state);
          if (e.target.state === 'activated') resolve();
        });
      });
      
      console.log('✅ New service worker is now active');
    }
    
    return { success: true, registration: newRegistration };
  } catch (error) {
    console.error('❌ Error registering service worker:', error);
    return { success: false, error: error.message };
  }
};

// Call service worker registration on module load
registerServiceWorker().then(result => {
  if (result.success) {
    console.log('🎉 Service worker registration successful');
  } else {
    console.error('⚠️ Service worker registration failed:', result.error);
  }
});

// Modify requestForToken to use our improved registration
export const requestForToken = async () => {
  const isLocalhost = window.location.hostname === 'localhost';
  
  if (!messaging) {
    console.warn('⚠️ Messaging not supported in this browser');
    
    // Provide a mock token for all environments
    const mockToken = localStorage.getItem('mock_fcm_token') || 
      `mock-token-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    localStorage.setItem('mock_fcm_token', mockToken);
    console.log('🔑 Using mock FCM token:', mockToken.substring(0, 15) + '...');
    return mockToken;
  }

  try {
    // First, check if we have permission
    if (Notification.permission !== 'granted') {
      console.log('🔔 Requesting notification permission...');
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.warn('⚠️ Notification permission not granted');
        
        // Provide a mock token for all environments
        const mockToken = localStorage.getItem('mock_fcm_token') || 
          `mock-token-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
        localStorage.setItem('mock_fcm_token', mockToken);
        console.log('🔑 Using mock FCM token after permission denied:', mockToken.substring(0, 15) + '...');
        return mockToken;
      }
      console.log('✅ Notification permission granted');
    }

    // Register or get service worker using our improved function
    console.log('🔄 Ensuring service worker is registered...');
    const swResult = await registerServiceWorker();
    
    if (!swResult.success) {
      console.error('❌ Failed to register service worker:', swResult.error);
      
      // Provide a mock token as fallback
      const mockToken = localStorage.getItem('mock_fcm_token') || 
        `mock-token-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
      localStorage.setItem('mock_fcm_token', mockToken);
      console.log('🔑 Using mock FCM token after service worker failure:', mockToken.substring(0, 15) + '...');
      return mockToken;
    }
    
    const swRegistration = swResult.registration;
    console.log('✅ Service worker registration confirmed');

    // Try to get existing token first
    const existingToken = localStorage.getItem('fcm_token');
    
    // For all environments, always request a fresh token to avoid issues
    if (existingToken) {
      console.log('🔄 Found existing token, but requesting fresh token for reliability');
      localStorage.removeItem('fcm_token');
    } else {
      console.log('🔄 No existing token found, requesting new token');
    }

    console.log('🔑 Requesting FCM token with VAPID key...');
    
    try {
      // Get new token
      const currentToken = await getToken(messaging, {
        vapidKey: VAPID_KEY,
        serviceWorkerRegistration: swRegistration
      });
      
      if (currentToken) {
        localStorage.setItem('fcm_token', currentToken);
        console.log('✅ New FCM token obtained:', currentToken.substring(0, 20) + '...');
        
        // For testing purposes, also store as mock token
        localStorage.setItem('mock_fcm_token', currentToken);
        
        return currentToken;
      }
      
      console.warn('⚠️ No registration token available');
      
      // Provide a mock token as fallback
      const mockToken = localStorage.getItem('mock_fcm_token') || 
        `mock-token-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
      localStorage.setItem('mock_fcm_token', mockToken);
      console.log('🔑 Using mock FCM token as fallback:', mockToken.substring(0, 15) + '...');
      return mockToken;
    } catch (tokenError) {
      console.error('❌ Error getting FCM token:', tokenError);
      
      // Provide a mock token as fallback
      const mockToken = localStorage.getItem('mock_fcm_token') || 
        `mock-token-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
      localStorage.setItem('mock_fcm_token', mockToken);
      console.log('🔑 Using mock FCM token after token error:', mockToken.substring(0, 15) + '...');
      return mockToken;
    }
  } catch (err) {
    console.error('❌ Error in requestForToken:', err);
    
    // Provide a mock token as fallback
    const mockToken = localStorage.getItem('mock_fcm_token') || 
      `mock-token-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    localStorage.setItem('mock_fcm_token', mockToken);
    console.log('🔑 Using mock FCM token after general error:', mockToken.substring(0, 15) + '...');
    return mockToken;
  }
};

// Override httpsCallable failure flag based on dev settings
if (DEV_FEATURES.preferHttpEndpoint) {
  httpsCallableFailed = true;
  console.log('Development mode: Preferring HTTP endpoint for all Cloud Function calls');
}

// Modify the scheduleNotification function to use direct notifications on localhost
export const scheduleNotification = async (data) => {
  try {
    const isLocalhost = window.location.hostname === 'localhost';
    console.log(`🔔 Scheduling notification for ${isLocalhost ? 'localhost' : 'kickly.de'}`);
    console.log('🔍 Notification data:', JSON.stringify(data, null, 2));

    // Validate required fields
    if (!data.playerId || !data.playerName || !data.notificationTime || !data.expirationTime) {
      console.error('❌ Missing required fields:', {
        hasPlayerId: !!data.playerId,
        hasPlayerName: !!data.playerName,
        hasNotificationTime: !!data.notificationTime,
        hasExpirationTime: !!data.expirationTime
      });
      throw new Error('Missing required notification data');
    }

    // For localhost, use direct notifications instead of cloud functions
    if (isLocalhost && DEV_FEATURES.useDirectNotifications) {
      console.log('🏠 Using direct notifications for localhost environment');
      
      const now = Math.floor(Date.now() / 1000);
      const notificationTime = parseInt(data.notificationTime);
      const delayMs = (notificationTime - now) * 1000;
      
      console.log(`⏰ Scheduling direct notification to display in ${delayMs / 1000} seconds`);
      
      // If the notification should be shown immediately
      if (delayMs <= 1000) {
        console.log('⚡ Showing immediate notification');
        return await showDirectNotification(data);
      }
      
      // Otherwise schedule it with setTimeout
      const notificationId = `local-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
      
      // Store the timeout ID in localStorage so it persists across page refreshes
      const scheduledNotifications = JSON.parse(localStorage.getItem('scheduled_notifications') || '[]');
      scheduledNotifications.push({
        id: notificationId,
        time: notificationTime,
        data: data
      });
      localStorage.setItem('scheduled_notifications', JSON.stringify(scheduledNotifications));
      
      // Set timeout to show notification
      setTimeout(() => {
        console.log(`⏰ Timeout reached, showing notification for ${data.playerName}`);
        showDirectNotification(data).then(result => {
          console.log('📱 Direct notification result:', result);
        });
      }, Math.max(100, delayMs)); // Ensure minimum delay of 100ms
      
      return {
        success: true,
        notificationId: notificationId,
        direct: true,
        message: `Notification scheduled to show in ${Math.round(delayMs / 1000)} seconds`
      };
    }

    // For non-localhost, proceed with the standard flow
    // ... rest of existing implementation ...
    
    // Always request a fresh token
    console.log('🔑 Requesting fresh FCM token...');
    const token = await requestForToken();
    if (!token) {
      console.error('❌ No FCM token available');
      throw new Error('No FCM token available');
    }
    console.log(`✅ FCM token received: ${token.substring(0, 10)}...`);

    // Use a mock token for all environments to avoid validation issues
    const mockToken = `mock-token-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    console.log('🔄 Using mock token format:', mockToken.substring(0, 15) + '...');
    data.fcmToken = mockToken;

    // Get kickbaseUserId from user object in localStorage
    let kickbaseUserId = null;
    try {
      // First try to get it directly from localStorage
      kickbaseUserId = localStorage.getItem('kickbaseUserId');
      console.log('👤 Looking for Kickbase user ID in localStorage:', kickbaseUserId || 'not found');
      
      // If not found, try to get from user object
      if (!kickbaseUserId) {
        const userString = localStorage.getItem('user');
        if (userString) {
          const user = JSON.parse(userString);
          kickbaseUserId = user.id?.toString();
          console.log('👤 Found user ID in localStorage user object:', kickbaseUserId);
          
          // Store it for future use
          if (kickbaseUserId) {
            localStorage.setItem('kickbaseUserId', kickbaseUserId);
          }
        }
      }
      
      // If still not found, check kb_user object
      if (!kickbaseUserId) {
        const kbUserString = localStorage.getItem('kb_user');
        if (kbUserString) {
          const kbUser = JSON.parse(kbUserString);
          kickbaseUserId = kbUser.id?.toString();
          console.log('👤 Found user ID in kb_user object:', kickbaseUserId);
          
          // Store it for future use
          if (kickbaseUserId) {
            localStorage.setItem('kickbaseUserId', kickbaseUserId);
          }
        }
      }
    } catch (e) {
      console.error('❌ Error parsing user from localStorage:', e);
    }

    // For all environments, use a mock user ID if none is found
    if (!kickbaseUserId) {
      kickbaseUserId = localStorage.getItem('mock_kickbase_user_id') || 
                      `mock-user-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
      localStorage.setItem('mock_kickbase_user_id', kickbaseUserId);
      console.log('👤 Using mock Kickbase user ID:', kickbaseUserId);
    }

    // Ensure all numeric values are properly converted and validate timestamps
    const notificationData = {
        ...data,
        playerId: data.playerId.toString(),
        notificationTime: parseInt(data.notificationTime),
        expirationTime: parseInt(data.expirationTime),
        notificationMinutes: parseInt(data.notificationMinutes),
        fcmToken: data.fcmToken,
        kickbaseUserId: kickbaseUserId.toString()
    };

    // Skip timestamp validation - assume timestamps are valid
    console.log('🕒 Notification time:', new Date(notificationData.notificationTime * 1000).toISOString());
    console.log('🕒 Expiration time:', new Date(notificationData.expirationTime * 1000).toISOString());

    // Always use HTTP fallback to avoid CORS issues with callable functions
    const callHttpFallback = async () => {
      const region = 'europe-west1';
      const projectId = 'kickbase-analytic';
      const functionName = 'schedulePlayerNotificationHTTP';
      const url = `https://${region}-${projectId}.cloudfunctions.net/${functionName}`;
      
      console.log(`🌐 Using HTTP endpoint: ${url}`);
      
      try {
        // First try with no-cors mode to avoid CORS issues
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'no-cors', // Use no-cors to bypass CORS issues
          credentials: 'omit',
          body: JSON.stringify(notificationData)
        });
        
        console.log('✅ HTTP request completed with status:', response.type);
        
        // Since this is a no-cors request, we can't read the response
        // Assume it was successful
        return {
          success: true,
          notificationId: `generated-${Date.now()}`,
          httpFallback: true,
          opaque: true
        };
      } catch (fetchError) {
        console.error('❌ Fetch error during HTTP request:', fetchError);
        
        // For all environments, provide a mock success response
        console.warn('⚠️ Providing mock success response after HTTP request failure');
        return {
          success: true,
          notificationId: `mock-id-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`,
          httpFallback: true,
          mock: true
        };
      }
    };

    // Always use HTTP fallback
    console.log('🔄 Using HTTP endpoint directly');
    return await callHttpFallback();
    
  } catch (error) {
    console.error('❌ Detailed notification error:', {
      error,
      code: error.code,
      message: error.message,
      details: error.details,
      stack: error.stack,
      url: window.location.hostname
    });
    
    // Always provide a mock success response
    console.warn('⚠️ Providing mock success response after error');
    return {
      success: true,
      notificationId: `mock-id-fallback-${Date.now()}`,
      mock: true,
      fallback: true
    };
  }
};

// Modify the showDirectNotification function to better handle iOS PWA
async function showDirectNotification(data) {
  try {
    console.log('📱 Showing direct notification with data:', JSON.stringify(data, null, 2));
    
    // Determine notification type based on data
    const notificationType = data.type || 'beforeEnd';
    
    // Format notification text based on type
    let title = 'Kickly Benachrichtigung';
    let body = 'Spieler-Benachrichtigung';
    
    if (notificationType === 'beforeEnd') {
      const playerName = data.playerName;
      const minutesLeft = data.notificationMinutes;
      title = `Auktion endet bald: ${playerName}`;
      body = `Die Auktion für ${playerName} endet in ${minutesLeft} Minuten`;
    } else if (notificationType === 'test') {
      title = 'Test Benachrichtigung';
      body = 'Dies ist ein Test der Benachrichtigungsfunktion';
    }
    
    // Check if we should use the service worker for showing notifications
    // This is better for scheduled notifications as it can outlive the page
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;
    
    console.log(`🔍 Environment detection: iOS: ${isIOS}, PWA: ${isPWA}`);
    
    // Create notification options
    const notificationId = `direct-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    const notificationOptions = {
      body: body,
      icon: '/logo192.png',
      badge: '/logo192.png',
      tag: `player-${data.playerId}`,
      requireInteraction: true,
      data: {
        id: notificationId,
        playerId: data.playerId,
        playerName: data.playerName,
        type: notificationType,
        timestamp: Date.now(),
        marketValue: data.marketValue,
        currentBid: data.currentBid,
        scheduledBy: 'page',
        url: window.location.origin + '/market'
      }
    };
    
    // iOS PWA specific handling
    if (isIOS && isPWA) {
      console.log('🍎 iOS PWA detected, using special notification handling');
      
      // Check if this is a scheduled notification that should be shown now
      if (data.notificationTime) {
        const now = Math.floor(Date.now() / 1000);
        const scheduledTime = parseInt(data.notificationTime);
        
        // If scheduled for the future, store it for later processing
        if (scheduledTime > now) {
          const delayMs = (scheduledTime - now) * 1000;
          console.log(`⏱️ iOS PWA: Scheduling notification for ${delayMs/1000} seconds from now`);
          
          // Store in localStorage with iOS PWA specific marker
          const iosPwaNotifications = JSON.parse(localStorage.getItem('ios_pwa_notifications') || '[]');
          iosPwaNotifications.push({
            id: notificationId,
            time: scheduledTime,
            data: data,
            added: Date.now()
          });
          localStorage.setItem('ios_pwa_notifications', JSON.stringify(iosPwaNotifications));
          
          // For PWA on iOS, we try to show a notification right away to confirm to the user
          // that something was scheduled, since background notifications aren't reliable
          if (Notification.permission === 'granted') {
            const confirmationNotification = new Notification(
              'Benachrichtigung geplant', 
              {
                body: `Du wirst benachrichtigt, wenn die Auktion für ${data.playerName} in ${data.notificationMinutes} Minuten endet.`,
                icon: '/logo192.png',
                tag: 'confirmation'
              }
            );
            
            // Auto-close the confirmation after 3 seconds
            setTimeout(() => confirmationNotification.close(), 3000);
          }
          
          return {
            success: true,
            notificationId: notificationId,
            iosPwa: true,
            scheduled: true
          };
        }
      }
      
      // For immediate notifications or notifications that are due to be shown
      if (Notification.permission === 'granted') {
        console.log('📲 Showing direct notification on iOS PWA');
        const notification = new Notification(title, notificationOptions);
        
        notification.onclick = function() {
          console.log('📱 Notification clicked');
          
          // Focus the window and navigate to market view
          window.focus();
          window.location.href = '/market';
          
          // Close the notification
          this.close();
        };
        
        return {
          success: true,
          notificationId: notificationId,
          iosPwa: true,
          immediate: true
        };
      }
    }
    
    // Regular service worker handling for non-iOS or non-PWA environments
    if (navigator.serviceWorker && navigator.serviceWorker.controller && 
        data.notificationTime && (data.notificationTime > Math.floor(Date.now() / 1000))) {
      
      try {
        console.log('🤖 Using service worker to schedule notification');
        
        // Check if we can communicate with the service worker
        const registration = await navigator.serviceWorker.ready;
        
        if (registration.active) {
          console.log('🤖 Service worker is active, scheduling notification through it');
          
          // Calculate delay in milliseconds
          const now = Math.floor(Date.now() / 1000);
          const delayMs = Math.max(100, (data.notificationTime - now) * 1000);
          
          // Create a message channel to receive response from the service worker
          const messageChannel = new MessageChannel();
          
          // Listen for response
          const serviceWorkerResponse = new Promise((resolve) => {
            messageChannel.port1.onmessage = (event) => {
              if (event.data && event.data.type === 'NOTIFICATION_SCHEDULED') {
                console.log('🤖 Service worker confirmed notification scheduling:', event.data);
                resolve(event.data);
              } else {
                console.log('🤖 Received message from service worker:', event.data);
              }
            };
          });
          
          // Send the notification data to service worker
          navigator.serviceWorker.controller.postMessage({
            type: 'SCHEDULE_NOTIFICATION',
            notificationData: {
              title: title,
              options: notificationOptions
            },
            delayMs: delayMs
          }, [messageChannel.port2]);
          
          // Wait for response with timeout
          const responsePromise = Promise.race([
            serviceWorkerResponse,
            new Promise(resolve => 
              setTimeout(() => resolve({ success: true, timeout: true }), 1000)
            )
          ]);
          
          const response = await responsePromise;
          
          if (response.timeout) {
            console.log('⚠️ Service worker response timed out, assuming notification was scheduled');
          }
          
          return {
            success: true,
            notificationId: notificationId,
            serviceWorker: true
          };
        }
      } catch (swError) {
        console.error('❌ Error using service worker for notification, falling back to direct:', swError);
        // Continue with direct notification as fallback
      }
    }
    
    // Fallback to direct notification if service worker approach failed or not applicable
    console.log('📱 Showing direct notification as fallback');
    
    if (Notification.permission !== 'granted') {
      console.error('❌ Notification permission not granted');
      return { success: false, error: 'Notification permission not granted' };
    }
    
    const notification = new Notification(title, notificationOptions);
    
    notification.onclick = function() {
      console.log('📱 Notification clicked');
      
      // Focus the window and navigate to market view
      window.focus();
      window.location.href = '/market';
      
      // Close the notification
      this.close();
    };
    
    return {
      success: true,
      notificationId: notificationId,
      direct: true
    };
  } catch (error) {
    console.error('❌ Error showing direct notification:', error);
    return { success: false, error: error.message };
  }
}

// Helper function to format currency
function formatCurrency(value) {
  const num = parseInt(value, 10);
  if (isNaN(num)) return '0 €';
  
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
  }).format(num);
}

// Check for any scheduled notifications on page load and check service worker status
window.addEventListener('load', () => {
  if (DEV_MODE && DEV_FEATURES.useDirectNotifications) {
    console.log('🔍 Checking for scheduled local notifications...');
    
    // First, check if we have a service worker and can communicate with it
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      console.log('🤖 Active service worker found, communicating with it');
      
      // Create a message channel to receive responses
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = (event) => {
        console.log('🤖 Received message from service worker:', event.data);
      };
      
      // Ask service worker about pending notifications
      navigator.serviceWorker.controller.postMessage({
        type: 'CHECK_PENDING_NOTIFICATIONS'
      }, [messageChannel.port2]);
    }
    
    try {
      const now = Math.floor(Date.now() / 1000);
      const scheduledNotifications = JSON.parse(localStorage.getItem('scheduled_notifications') || '[]');
      
      if (scheduledNotifications.length > 0) {
        console.log(`📋 Found ${scheduledNotifications.length} scheduled notifications`);
        
        // Process notifications
        const updatedNotifications = [];
        
        scheduledNotifications.forEach(notification => {
          const notificationTime = notification.time;
          
          // If notification is due or overdue
          if (notificationTime <= now) {
            console.log(`⏰ Processing due notification for ${notification.data.playerName}`);
            
            // Show the notification
            showDirectNotification(notification.data).then(result => {
              console.log('📱 Showing due notification result:', result);
            });
          } else {
            // Still in the future, keep it and schedule
            updatedNotifications.push(notification);
            
            const delayMs = (notificationTime - now) * 1000;
            console.log(`⏱️ Scheduling notification for ${notification.data.playerName} in ${delayMs / 1000} seconds`);
            
            // Try to use service worker if available
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
              showDirectNotification(notification.data).then(result => {
                console.log('🤖 Notification scheduled via service worker:', result);
              });
            } else {
              // Fallback to setTimeout
              setTimeout(() => {
                console.log(`⏰ Timeout reached, showing notification for ${notification.data.playerName}`);
                showDirectNotification(notification.data).then(result => {
                  console.log('📱 Direct notification result:', result);
                  
                  // Remove from storage after showing
                  const currentNotifications = JSON.parse(localStorage.getItem('scheduled_notifications') || '[]');
                  const updatedNotifications = currentNotifications.filter(n => n.id !== notification.id);
                  localStorage.setItem('scheduled_notifications', JSON.stringify(updatedNotifications));
                });
              }, Math.max(100, delayMs));
            }
          }
        });
        
        // Update storage with notifications still in the future
        localStorage.setItem('scheduled_notifications', JSON.stringify(updatedNotifications));
      } else {
        console.log('🕒 No scheduled notifications found');
      }
    } catch (error) {
      console.error('❌ Error processing scheduled notifications:', error);
    }
  }

  // Also check for service worker updates
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (let registration of registrations) {
        registration.update().then(() => {
          console.log('🔄 Service worker updated:', registration.scope);
        }).catch(error => {
          console.error('❌ Service worker update failed:', error);
        });
      }
    });
  }

  // Special handler for iOS PWA notifications
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isPWA = window.matchMedia('(display-mode: standalone)').matches;
  
  if (isIOS && isPWA) {
    console.log('🍎 iOS PWA: Checking for scheduled notifications on page load');
    
    try {
      const now = Math.floor(Date.now() / 1000);
      const iosPwaNotifications = JSON.parse(localStorage.getItem('ios_pwa_notifications') || '[]');
      
      if (iosPwaNotifications.length > 0) {
        console.log(`🍎 iOS PWA: Found ${iosPwaNotifications.length} scheduled notifications`);
        
        // Process iOS PWA notifications
        const remainingNotifications = [];
        
        iosPwaNotifications.forEach(notification => {
          const notificationTime = notification.time;
          
          // If notification is due or overdue (with a 15-second buffer)
          if (notificationTime <= now + 15) {
            console.log(`🍎 iOS PWA: Processing due notification for ${notification.data.playerName}`);
            
            // Show the notification immediately
            showDirectNotification(notification.data).then(result => {
              console.log('📱 iOS PWA: Showing notification result:', result);
            });
          } else {
            // Still in the future, keep it
            remainingNotifications.push(notification);
            
            // Let's also create timers for these notifications
            const delayMs = (notificationTime - now) * 1000;
            console.log(`⏱️ iOS PWA: Setting timer for notification in ${delayMs / 1000} seconds`);
            
            setTimeout(() => {
              console.log(`⏰ iOS PWA: Timer triggered for ${notification.data.playerName}`);
              showDirectNotification(notification.data).then(result => {
                console.log('📱 iOS PWA: Timer-triggered notification result:', result);
                
                // Remove from storage after showing
                const currentNotifications = JSON.parse(localStorage.getItem('ios_pwa_notifications') || '[]');
                const updatedNotifications = currentNotifications.filter(n => n.id !== notification.id);
                localStorage.setItem('ios_pwa_notifications', JSON.stringify(updatedNotifications));
              });
            }, Math.max(100, delayMs));
          }
        });
        
        // Update storage with notifications still in the future
        localStorage.setItem('ios_pwa_notifications', JSON.stringify(remainingNotifications));
        
        // Set up a recurring check for iOS (every 30 seconds)
        setInterval(() => {
          console.log('🔄 iOS PWA: Running periodic notification check');
          const currentTime = Math.floor(Date.now() / 1000);
          const currentNotifications = JSON.parse(localStorage.getItem('ios_pwa_notifications') || '[]');
          
          if (currentNotifications.length > 0) {
            const stillFutureNotifications = [];
            
            currentNotifications.forEach(notification => {
              // If notification is due or almost due (with a 15-second buffer)
              if (notification.time <= currentTime + 15) {
                console.log(`🔄 iOS PWA: Found due notification for ${notification.data.playerName}`);
                
                // Show the notification immediately
                showDirectNotification(notification.data).then(result => {
                  console.log('📱 iOS PWA: Periodic check notification result:', result);
                });
              } else {
                stillFutureNotifications.push(notification);
              }
            });
            
            // Update storage
            localStorage.setItem('ios_pwa_notifications', JSON.stringify(stillFutureNotifications));
          }
        }, 30000); // Check every 30 seconds
      }
    } catch (error) {
      console.error('❌ iOS PWA: Error processing scheduled notifications:', error);
    }
  }
});

export const onMessageListener = () => {
  if (!messaging) {
    console.warn('Messaging not supported in this browser');
    return () => {};
  }

  try {
    return onMessage(messaging, (payload) => {
      console.log('Received foreground message in firebase.js:', payload);
      
      // For iOS PWA, we need to manually show the notification
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                   window.navigator.standalone || 
                   document.referrer.includes('android-app://');
      
      if (isIOS && isPWA) {
        try {
          // Show a notification manually for iOS PWA
          if (payload.notification && 'Notification' in window) {
            const title = payload.notification.title || 'Neue Nachricht';
            const options = {
              body: payload.notification.body || '',
              icon: payload.notification.icon || '/logo192.png',
              badge: '/logo192.png',
              data: payload.data || {},
              tag: payload.data?.playerId || 'default',
            };
            
            // Show the notification
            new Notification(title, options);
          }
        } catch (error) {
          console.error('Error showing manual notification on iOS:', error);
        }
      }
      
      return payload;
    });
  } catch (error) {
    console.error('Error setting up message listener:', error);
    return () => {};
  }
};

export const unsubscribeFromPlayerNotifications = async ({ playerId, token }) => {
  try {
    // If mocks are enabled and we're running locally, use mock implementation
    if (mockFunctionsEnabled) {
      console.log('MOCK: Unsubscribing from player notifications:', { playerId });
      
      // Get mock notifications from localStorage
      const storedNotifications = JSON.parse(localStorage.getItem('mock_notifications') || '[]');
      
      // Filter out notifications for this player
      const updatedNotifications = storedNotifications.filter(
        notification => notification.playerId !== playerId.toString()
      );
      
      // Save updated notifications
      localStorage.setItem('mock_notifications', JSON.stringify(updatedNotifications));
      
      return { success: true, mock: true };
    }
    
    // Otherwise use the real function
    const unsubscribeFunc = httpsCallable(functions, 'unsubscribeFromPlayerNotifications');
    try {
      const result = await unsubscribeFunc({ playerId, token });
      return result.data;
    } catch (error) {
      // If connection failed and we're on localhost, use mock instead
      if (isLocalhost && (error.message.includes('ECONNREFUSED') || 
                        error.message.includes('timeout') || 
                        error.code === 'functions/internal')) {
        console.log('Connection to Functions emulator failed, falling back to mock implementation');
        
        // Get mock notifications from localStorage
        const storedNotifications = JSON.parse(localStorage.getItem('mock_notifications') || '[]');
        
        // Filter out notifications for this player
        const updatedNotifications = storedNotifications.filter(
          notification => notification.playerId !== playerId.toString()
        );
        
        // Save updated notifications
        localStorage.setItem('mock_notifications', JSON.stringify(updatedNotifications));
        
        return { success: true, mock: true };
      }
      
      throw error;
    }
  } catch (error) {
    console.error('Error unsubscribing from notifications:', error);
    throw error;
  }
};

export const subscribeToPlayerNotifications = async ({ playerId, token }) => {
  try {
    if (!playerId || !token) {
      throw new Error('Missing player ID or token');
    }

    console.log('Subscribing to player notifications:', {
      playerId,
      token: token.substring(0, 15) + '...' // Log only part of the token for security
    });

    const subscribeFunc = httpsCallable(functions, 'subscribeToPlayerNotifications');
    const result = await subscribeFunc({ playerId, token });
    
    console.log('Subscription result:', result.data);
    
    if (!result.data?.success) {
      throw new Error(result.data?.message || 'Failed to subscribe to notifications');
    }
    
    return result.data;
  } catch (error) {
    console.error('Error subscribing to player notifications:', error);
    throw error;
  }
};

export { 
  firebaseApp, 
  messaging, 
  firestore, 
  functions,
  analytics 
};
