import { apiInstance } from './instance';
import { analyzeTeamData } from './gemini';

const TOKEN_EXPIRY_DAYS = 3;

export const playerService = {
    _squadCache: new Map(), // { leagueId: { data, timestamp } }
    _userSettingsCache: null,
    _userSettingsTimestamp: null,
    CACHE_DURATION: 5 * 60 * 1000, // 5 minutes

    _isSquadCacheValid(leagueId) {
        const cache = this._squadCache.get(leagueId);
        if (!cache) return false;
        
        const now = Date.now();
        return (now - cache.timestamp) < this.CACHE_DURATION;
    },

    async getPlayerDetails(leagueId, playerId) {
        const response = await apiInstance.get(`/leagues/${leagueId}/players/${playerId}`);
        return response.data;
    },

    async getPlayerPerformance(leagueId, playerId) {
        const response = await apiInstance.get(`/leagues/${leagueId}/players/${playerId}/performance`);
        return response.data;
    },

    async getSquadPlayers(leagueId) {
        if (this._isSquadCacheValid(leagueId)) {
            console.log('Using cached squad data for league:', leagueId);
            return this._squadCache.get(leagueId).data;
        }

        try {
            console.log('Fetching fresh squad data for league:', leagueId);
            const response = await apiInstance.get(`/leagues/${leagueId}/squad`);
            
            this._squadCache.set(leagueId, {
                data: response.data,
                timestamp: Date.now()
            });

            return response.data;
        } catch (error) {
            console.error('Error fetching squad players:', error);
            throw error;
        }
    },

    async refreshSquadPlayers(leagueId) {
        try {
            console.log('Force refreshing squad data for league:', leagueId);
            const response = await apiInstance.get(`/leagues/${leagueId}/squad`);
            
            this._squadCache.set(leagueId, {
                data: response.data,
                timestamp: Date.now()
            });

            return response.data;
        } catch (error) {
            console.error('Error refreshing squad players:', error);
            throw error;
        }
    },

    async getMarketPlayers(leagueId) {
        try {
            const response = await apiInstance.get(`/leagues/${leagueId}/market`);
            console.log('Market players:', response.data);
            return {
                items: response.data.it.map(player => ({
                    id: player.i,
                    firstName: player.fn,
                    lastName: player.n,
                    teamId: player.tid,
                    position: player.pos,
                    status: player.st,
                    marketValueTrend: player.mvt,
                    marketValue: player.mv,
                    totalPoints: player.p,
                    averagePoints: player.ap,
                    expireInSeconds: player.exs,
                    price: player.prc,
                    seller: player.u,
                    isNew: player.isn,
                    isPosLocked: player.iposl,
                    dateTime: player.dt
                }))
            };
        } catch (error) {
            console.error('Error fetching market players:', error);
            throw error;
        }
    },

    async getEnhancedPlayerData(leagueId, playerId) {
        try {
            const [details, performance, squad] = await Promise.all([
                this.getPlayerDetails(leagueId, playerId),
                this.getPlayerPerformance(leagueId, playerId),
                this.getSquadPlayers(leagueId)
            ]);

            console.log('Raw Player Data:', {
                details,
                performance,
                squadData: squad.it.find(p => p.i === playerId)
            });

            const squadPlayer = squad.it.find(p => p.i === playerId);
            
            // Calculate market value trends
            const marketValueGainLoss = squadPlayer?.mvgl || 0;
            const sevenDayTrend = squadPlayer?.sdmvt || 0;
            const fourHourTrend = squadPlayer?.tfhmvt || details.tfhmvt || 0;
            
            // Enhanced debug information
            console.log('Detailed Player Information:', {
                // Basic Info
                id: details.i,
                name: `${details.fn} ${details.ln}`,
                number: details.shn,
                age: details.age,
                nationality: details.nat,
                
                // Team Info
                team: {
                    id: details.tid,
                    name: details.tn,
                    shortName: details.tsn,
                    logo: details.tl
                },
                
                // Status & Position
                status: {
                    code: details.st,
                    text: details.stxt,
                    lastStatus: squadPlayer?.lst,
                    position: details.pos,
                    positionDetail: details.posd
                },
                
                // Performance Stats
                performance: {
                    total: details.tp,
                    average: details.ap,
                    goals: details.g,
                    assists: details.a,
                    redCards: details.r,
                    yellowCards: details.y,
                    minutesPlayed: details.mp,
                    gamesPlayed: details.gp,
                    form: details.f
                },
                
                // Market Data
                market: {
                    currentValue: details.mv,
                    trend: details.mvt,
                    gainLoss: marketValueGainLoss,
                    sevenDayTrend,
                    fourHourTrend,
                    lastOwner: squadPlayer?.lo,
                    priceHistory: details.ph,
                    valueHistory: details.vh
                },
                
                // Match Data
                matches: {
                    current: details.day,
                    upcoming: details.mdsum,
                    history: details.mh,
                    nextMatch: details.nm
                },
                
                // Season Stats
                season: {
                    performance: performance.it,
                    statistics: details.stats,
                    highlights: details.hl
                },
                
                // Additional Data
                extra: {
                    rawDetails: details,
                    rawPerformance: performance,
                    rawSquadData: squadPlayer
                }
            });

            return {
                // Basic Info
                id: details.i,
                firstName: details.fn,
                lastName: details.ln,
                fullName: `${details.fn} ${details.ln}`,
                shirtNumber: details.shn,
                
                // Team Info
                teamId: details.tid,
                teamName: details.tn,
                
                // Status
                status: details.st,
                statusText: details.stxt,
                position: details.pos,
                
                // Performance
                totalPoints: details.tp,
                averagePoints: details.ap,
                goals: details.g,
                assists: details.a,
                
                // Market Values
                marketValue: details.mv,
                marketValueTrend: details.mvt,
                marketStats: {
                    marketValueGainLoss,
                    sevenDayTrend,
                    fourHourTrend,
                    lastOwner: squadPlayer?.lo,
                    lastStatus: squadPlayer?.lst
                },
                
                // Match Data
                currentDay: details.day,
                redCards: details.r,
                yellowCards: details.y,
                
                // Performance History
                performanceHistory: details.ph,
                
                // Match Schedule
                upcomingMatches: details.mdsum,
                
                // Season Performance
                seasonPerformance: performance.it
            };
        } catch (error) {
            console.error('Error fetching enhanced player data:', error);
            throw error;
        }
    },

    async login(email, password) {
        try {
            const response = await apiInstance.post('/auth/login', { email, password });
            const { token, user } = response.data;

            // Save token with expiration timestamp
            const expirationTime = Date.now() + (TOKEN_EXPIRY_DAYS * 24 * 60 * 60 * 1000);
            localStorage.setItem('token', token);
            localStorage.setItem('token_expiry', expirationTime.toString());
            localStorage.setItem('user', JSON.stringify(user));

            return { token, user };
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    },

    isTokenValid() {
        const token = localStorage.getItem('token');
        const expiryTime = localStorage.getItem('token_expiry');

        if (!token || !expiryTime) {
            return false;
        }

        return Date.now() < parseInt(expiryTime);
    },

    clearAuth() {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiry');
        localStorage.removeItem('user');
    },

    getAuthToken() {
        if (!this.isTokenValid()) {
            this.clearAuth();
            return null;
        }
        return localStorage.getItem('token');
    },

    async getLeagueRanking(leagueId) {
        try {
            const response = await apiInstance.get(`/leagues/${leagueId}/ranking`);
            
            // Log the raw response for debugging
            console.log('League ranking response:', response.data);
            
            // Handle different response structures
            if (response.data?.us) {
                // Response has user standings format
                return {
                    it: response.data.us.map(team => ({
                        id: team.i || team.id,
                        points: team.p || team.points || 0,
                        un: team.un || team.username || `Team ${team.i || team.id}`,
                        position: team.pos || team.position || 0,
                        teamValue: team.tv || team.teamValue || 0,
                        budget: team.b || team.budget || 0
                    }))
                };
            } else if (response.data?.st) {
                // Response has standings format
                return {
                    it: response.data.st.map(team => ({
                        id: team.i || team.id,
                        points: team.p || team.points || 0,
                        un: team.un || team.username || `Team ${team.i || team.id}`,
                        position: team.pos || team.position || 0
                    }))
                };
            } else if (Array.isArray(response.data)) {
                // Response is a direct array
                return {
                    it: response.data.map(team => ({
                        id: team.i || team.id,
                        points: team.p || team.points || 0,
                        un: team.un || team.username || `Team ${team.i || team.id}`,
                        position: team.pos || team.position || 0
                    }))
                };
            } else if (response.data?.ranking) {
                // Response has ranking property
                return {
                    it: response.data.ranking.map(team => ({
                        id: team.i || team.id,
                        points: team.p || team.points || 0,
                        un: team.un || team.username || `Team ${team.i || team.id}`,
                        position: team.pos || team.position || 0
                    }))
                };
            }

            // If no valid structure is found, return empty array with structure
            console.warn('Unexpected league ranking response structure:', response.data);
            return { it: [] };
        } catch (error) {
            console.error('Error fetching league ranking:', error);
            // Return empty array with structure instead of throwing
            return { it: [] };
        }
    },

    async getUserSettings() {
        // Check cache first
        if (this._userSettingsCache && this._userSettingsTimestamp) {
            const now = Date.now();
            const cacheAge = now - this._userSettingsTimestamp;
            if (cacheAge < 5 * 60 * 1000) { // Cache for 5 minutes
                return this._userSettingsCache;
            }
        }

        try {
            // Fix the URL to avoid duplicate v4
            const response = await apiInstance.get('/user/settings');
            this._userSettingsCache = response.data;
            this._userSettingsTimestamp = Date.now();
            return response.data;
        } catch (error) {
            console.error('Error fetching user settings:', error);
            // Instead of throwing, return a default structure
            return {
                u: {
                    i: localStorage.getItem('user_id') || null,
                    em: localStorage.getItem('user_email') || null,
                    unm: localStorage.getItem('user_name') || null
                }
            };
        }
    },

    async analyzeWithGemini(data, systemPrompt) {
        console.log("Calling Gemini API for analysis...");
        try {
            // First, optimize the data to reduce token count
            const optimizedData = this._optimizeDataForAPI(data);
            
            // Log the data size for debugging
            const dataSize = JSON.stringify(optimizedData).length;
            const tokenEstimate = dataSize / 4;
            console.log(`Optimized data size: ${dataSize} bytes, approx. ${tokenEstimate} tokens`);
            
            // Import the analyzeTeamData function from the gemini.js file
            const { analyzeTeamData } = await import('../api/gemini');
            
            // Call the analyzeTeamData function with the optimized data and system prompt
            const result = await analyzeTeamData(optimizedData, systemPrompt);
            
            // Return the result
            return result;
        } catch (error) {
            console.error("Error calling Gemini API:", error);
            throw error;
        }
    },

    // Helper method to optimize data sent to the API to reduce token count
    _optimizeDataForAPI(data) {
        console.log("Optimizing data for API...");
        // Create a deep copy to avoid modifying the original data
        const copy = JSON.parse(JSON.stringify(data));
        
        try {
            // Identify data type and extract only what's needed
            if (copy.squad && Array.isArray(copy.squad)) {
                // Already in optimized format
                // Just clean up unnecessary fields
                copy.squad = copy.squad.map(player => ({
                    id: player.id,
                    firstName: player.firstName,
                    lastName: player.lastName,
                    position: player.position,
                    points: player.points,
                    pointsPerGame: player.pointsPerGame,
                    gamesPlayed: player.gamesPlayed,
                    minutesPlayed: player.minutesPlayed,
                    goals: player.goals,
                    assists: player.assists,
                    yellowCards: player.yellowCards,
                    redCards: player.redCards,
                    marketValue: player.marketValue,
                    marketValueTrend: player.marketValueTrend,
                    status: player.status
                }));
            } else if (copy.it && Array.isArray(copy.it)) {
                // Handle Kickbase API response format
                copy.squad = copy.it.map(player => ({
                    id: player.i,
                    firstName: player.fn || '',
                    lastName: player.n || '',
                    position: player.pos || '',
                    points: player.p || 0,
                    pointsPerGame: player.ap || 0,
                    gamesPlayed: player.gp || 0,
                    minutesPlayed: player.mp || 0,
                    goals: player.g || 0,
                    assists: player.a || 0,
                    yellowCards: player.y || 0,
                    redCards: player.r || 0,
                    marketValue: player.mv || 0,
                    marketValueTrend: player.mvt || 0,
                    status: player.st || 0
                }));
                delete copy.it;
            } else if (copy.data && copy.data.it && Array.isArray(copy.data.it)) {
                // Handle nested data structure
                copy.squad = copy.data.it.map(player => ({
                    id: player.i,
                    firstName: player.fn || '',
                    lastName: player.n || '',
                    position: player.pos || '',
                    points: player.p || 0,
                    pointsPerGame: player.ap || 0,
                    gamesPlayed: player.gp || 0,
                    minutesPlayed: player.mp || 0,
                    goals: player.g || 0,
                    assists: player.a || 0,
                    yellowCards: player.y || 0,
                    redCards: player.r || 0,
                    marketValue: player.mv || 0,
                    marketValueTrend: player.mvt || 0,
                    status: player.st || 0
                }));
                delete copy.data;
            } else if (copy.enriched) {
                // Use the enriched data directly if available
                return copy.enriched;
            }
            
            // Remove any large raw data fields
            delete copy.raw;
            delete copy.rawData;
            delete copy.enriched;
            
            // If the squad is large, limit to most important players
            if (copy.squad && copy.squad.length > 25) {
                console.log(`Large squad detected (${copy.squad.length} players). Limiting to 25 most valuable players.`);
                // Sort by market value and limit to 25 players
                copy.squad.sort((a, b) => (b.marketValue || 0) - (a.marketValue || 0));
                copy.squad = copy.squad.slice(0, 25);
            }
            
            // Return the optimized data
            return copy;
        } catch (error) {
            console.error("Error optimizing data:", error);
            // Return original data if optimization fails
            return data;
        }
    }
}; 