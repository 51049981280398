import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import PlayerDetailModal from "./PlayerDetailModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
     faArrowUp, faArrowDown,faEquals,
     faChevronDown, faChevronUp,
     faBell, faBellSlash, 
     faInfoCircle, // Add this icon
     faCog, // Add settings icon
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import PlayerSearch from './PlayerSearch';
import { usePlayerStore } from '../src/stores/PlayerStore';
import VoteButtons from './components/VoteButtons';
import { toast } from 'react-toastify';  // Make sure to import this at the top of your file
import { Tooltip } from 'react-tooltip'; // Import Tooltip
import LoadingSpinner from './components/LoadingSpinner';
import { playerService } from './api/playerService';
import PlayerComparisonOverlay from './components/PlayerComparisonOverlay';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import notificationService from './services/notificationService';
import { scheduleNotification, unsubscribeFromPlayerNotifications, testNotification } from './firebase';

const formatNumber = (number) => {
    if (number === undefined || number === null) return '-';
    return new Intl.NumberFormat('de-DE').format(Math.round(number));
};

const MiniTrendGraph = ({ data, width = 50, height = 20 }) => {
    // Validate data
    if (!data || !Array.isArray(data) || data.length < 2) return null;

    // Filter out invalid entries and ensure numbers
    const validData = data
        .map(d => ({ m: Number(d.m) }))
        .filter(d => !isNaN(d.m));

    if (validData.length < 2) return null;

    const values = validData.map(d => d.m);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    
    // Prevent division by zero
    if (minValue === maxValue) return null;

    const xStep = width / (validData.length - 1);
    const points = validData
        .map((d, i) => {
            const x = i * xStep;
            const y = height - ((d.m - minValue) / (maxValue - minValue)) * height;
            return `${Math.round(x)},${Math.round(y)}`;
        })
        .join(' ');

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <polyline
                fill="none"
                stroke="#4CAF50"
                strokeWidth="2"
                points={points}
            />
        </svg>
    );
};

// Helper functions defined outside the component
const calculateMarketValueTrend = (player) => {
    if (!player) return "Unbekannt";
    
    // marketValueTrend: 1 = up, 2 = stable, 3 = down
    switch (player.marketValueTrend) {
        case 1:
            return "Steigend";
        case 2:
            return "Stabil";
        case 3:
            return "Fallend";
        default:
            return "Unbekannt";
    }
};

const calculatePlayerData = (player, playerStore) => {
    if (!player || typeof player !== 'object') {
        console.error('Invalid player data:', player);
        return {
            estimatedSalePrice: '-',
            financialPercentage: 0,
            positionCounts: {},
            maxPlayers: {
                'Torwart': 3,
                'Abwehr': 6,
                'Mittelfeld': 8,
                'Sturm': 4
            },
            teamPlayersCount: 0,
            comparisonResult: null,
            gamesPlayed: 0,
            totalGames: 0,
            overallFitPercentage: 0
        };
    }

    // Calculate position counts from playerStore
    const positionCounts = playerStore.players.reduce((acc, p) => {
        acc[p.position] = (acc[p.position] || 0) + 1;
        return acc;
    }, {});

    const maxPlayers = {
        'Torwart': 3,
        'Abwehr': 6,
        'Mittelfeld': 8,
        'Sturm': 4
    };

    const teamPlayersCount = playerStore.players.filter(p => p.teamId === player.teamId).length;
    const financialPercentage = (player.price / playerStore.budget) * 100;

    return {
        estimatedSalePrice: player.marketValue || '-',
        financialPercentage,
        positionCounts,
        maxPlayers,
        teamPlayersCount,
        comparisonResult: null,
        gamesPlayed: player.gamesPlayed || 0,
        totalGames: player.totalGames || 0,
        overallFitPercentage: calculateOverallFit(player, financialPercentage, positionCounts, maxPlayers, teamPlayersCount)
    };
};

const calculateOverallFit = (player, financialPercentage, positionCounts, maxPlayers, teamPlayersCount) => {
    if (!player) return 0;

    let score = 0;
    const weights = {
        financial: 0.4,
        position: 0.3,
        team: 0.3
    };

    // Financial fit (lower percentage is better)
    const financialFit = Math.max(0, 100 - financialPercentage);
    
    // Position fit
    const currentCount = positionCounts[player.position] || 0;
    const maxCount = maxPlayers[player.position] || 1;
    const positionFit = Math.max(0, 100 * (1 - currentCount / maxCount));

    // Team fit (more players from same team is better, up to a point)
    const teamFit = Math.min(100, teamPlayersCount * 20); // 20% per player, max 100%

    score = (financialFit * weights.financial) +
            (positionFit * weights.position) +
            (teamFit * weights.team);

    return Math.round(score);
};

const formatTimeRemaining = (seconds) => {
    if (!seconds) return 'Unbekannt';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours > 24) {
        const days = Math.floor(hours / 24);
        return `${days}d ${hours % 24}h`;
    }

    if (hours > 0) {
        return `${hours}h ${minutes}m`;
    }

    return `${minutes}m`;
};

// Helper function to format minutes into human-readable format
const formatMinutes = (minutes) => {
    if (!minutes && minutes !== 0) return 'Unbekannt';
    
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (hours > 0) {
        return remainingMinutes > 0 
            ? `${hours}h ${remainingMinutes}m` 
            : `${hours}h`;
    }
    
    return `${minutes}m`;
};

const standardTimings = [
    { label: '1 Minute', value: 1, type: 'beforeEnd' },
    { label: '2 Minuten', value: 2, type: 'beforeEnd' },
    { label: '3 Minuten', value: 3, type: 'beforeEnd' },
    { label: '5 Minuten', value: 5, type: 'beforeEnd' },
    { label: '10 Minuten', value: 10, type: 'beforeEnd' },
    { label: '15 Minuten', value: 15, type: 'beforeEnd' },
    { label: '30 Minuten', value: 30, type: 'beforeEnd' },
    { label: '1 Stunde', value: 60, type: 'beforeEnd' },
    { label: '2 Stunden', value: 120, type: 'beforeEnd' }
];

const MarketOverview = observer(() => {
    const playerStore = usePlayerStore();
    
    const [dataModalOpen, setDataModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [sortType, setSortType] = useState('expire');
    const [showAllPlayers, setShowAllPlayers] = useState(false);
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [calculatedPlayerData, setCalculatedPlayerData] = useState({});
    const [error, setError] = useState(null);
    const [showPerformanceDetails, setShowPerformanceDetails] = useState({});
    const [selectedPlayerForComparison, setSelectedPlayerForComparison] = useState(null);
    const [showComparison, setShowComparison] = useState(false);
    const [notificationEnabled, setNotificationEnabled] = useState({});
    const [pendingNotificationPlayerId, setPendingNotificationPlayerId] = useState(null);
    const [showNotificationDialog, setShowNotificationDialog] = useState(false);
    const [isLoadingPlayers, setIsLoadingPlayers] = useState(false);
    const [notificationLoadingStates, setNotificationLoadingStates] = useState({});
    const [userId, setUserId] = useState(null);
    const [customNotificationMinutes, setCustomNotificationMinutes] = useState('10');
    const [showTimingDialog, setShowTimingDialog] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState(null);
    const [showRecommendationDetails, setShowRecommendationDetails] = useState({});
    const [permissionStatus, setPermissionStatus] = useState(null);
    const [renderKey, setRenderKey] = useState(Date.now());
    const [showNotificationSettings, setShowNotificationSettings] = useState(false);

    // Initialize userId from localStorage on component mount
    useEffect(() => {
        // Get userId from localStorage
        const storedUserId = localStorage.getItem('kickbaseUserId');
        if (storedUserId) {
            console.log('Loading userId from localStorage:', storedUserId);
            setUserId(storedUserId);
        } else {
            // Try to find user ID from user object if not directly available
            try {
                const userString = localStorage.getItem('user');
                if (userString) {
                    const user = JSON.parse(userString);
                    if (user && user.id) {
                        console.log('Found userId in user object:', user.id);
                        setUserId(user.id.toString());
                        localStorage.setItem('kickbaseUserId', user.id.toString());
                    }
                }
            } catch (e) {
                console.error('Error parsing user from localStorage:', e);
            }
        }

        // Load notification status from localStorage
        try {
            const storedNotifications = localStorage.getItem('playerNotifications');
            if (storedNotifications) {
                const parsedNotifications = JSON.parse(storedNotifications);
                setNotificationEnabled(parsedNotifications);
                console.log('Loaded notification status from localStorage:', parsedNotifications);
            }
        } catch (e) {
            console.error('Error loading notification status from localStorage:', e);
        }
    }, []);

    // Add function to save notification status to localStorage
    const updateNotificationStatus = useCallback((playerId, enabled) => {
        setNotificationEnabled(prev => {
            const updated = { ...prev, [playerId]: enabled };
            // Save to localStorage
            localStorage.setItem('playerNotifications', JSON.stringify(updated));
            return updated;
        });
    }, []);

    const schedulePlayerNotification = useCallback(async (player, retryCount = 0) => {
        setNotificationLoadingStates(prev => ({ ...prev, [player.id]: true }));
        
        try {
            const now = Math.floor(Date.now() / 1000);
            const expireInSeconds = player.expireInSeconds;
            
            // Default to 15 minutes if not set by user
            const notificationMinutes = customNotificationMinutes || 15;
            
            // Format minutes for display
            const formattedTime = formatMinutes(notificationMinutes);
            
            console.log('🔍 Scheduling notification for player:', {
                id: player.id,
                name: player.fullName || `${player.firstName} ${player.lastName}`,
                requestedMinutes: notificationMinutes,
                formattedTime,
                willExpireAt: new Date((now + expireInSeconds) * 1000).toISOString()
            });

            if (expireInSeconds <= notificationMinutes * 60) {
                console.log('⚠️ Auction ends soon, scheduling immediate notification');
                const immediateNotificationMinutes = Math.floor(expireInSeconds / 60);
                
                // Notification timing is less than auction time, give user feedback
                toast.info(
                    `Auktion endet in weniger als ${formattedTime}. Plane sofortige Benachrichtigung für ${formatMinutes(immediateNotificationMinutes)}.`,
                    { autoClose: 5000 }
                );

                // Schedule an immediate notification (in 10 seconds from now)
                const immediateNotificationTime = now + 10; // 10 seconds from now for testing
                console.log('⏱️ Scheduling immediate notification at:', new Date(immediateNotificationTime * 1000).toISOString());
                
                const immediateResult = await scheduleNotification({
                    playerId: player.id.toString(),
                    playerName: player.fullName || `${player.firstName} ${player.lastName}`,
                    notificationTime: immediateNotificationTime,
                    expirationTime: now + expireInSeconds,
                    type: 'beforeEnd',
                    marketValue: player.marketValue?.toString() || '0',
                    currentBid: player.price?.toString() || player.marketValue?.toString() || '0',
                    notificationMinutes: immediateNotificationMinutes || 1 // Use at least 1 minute
                });

                console.log('📱 Immediate notification result:', immediateResult);

                if (immediateResult.success) {
                    localStorage.setItem(`notification_${player.id}`, JSON.stringify({
                        scheduledTime: immediateNotificationTime * 1000,
                        minutes: immediateNotificationMinutes,
                        notificationId: immediateResult.notificationId
                    }));
                    
                    // Use the updateNotificationStatus function
                    updateNotificationStatus(player.id, true);
                    setShowTimingDialog(false);
                    
                    toast.success(
                        `Benachrichtigung für ${player.fullName || `${player.firstName} ${player.lastName}`} in ${formatMinutes(immediateNotificationMinutes)} vor Ablauf eingerichtet.`,
                        { autoClose: 5000 }
                    );
                    return true;
                }
                
                console.error('❌ Failed to schedule immediate notification:', immediateResult);
                toast.error('Benachrichtigung konnte nicht eingerichtet werden. Bitte versuchen Sie es erneut.');
                return false;
            }

            // Add a user-friendly info message about the notification scheduling
            toast.info(
                `Plane Benachrichtigung für ${player.firstName} ${player.lastName} ${formattedTime} vor Ablauf...`,
                { autoClose: 3000 }
            );

            const expirationTime = now + expireInSeconds;
            const notificationTime = expirationTime - (notificationMinutes * 60);
            
            console.log('⏱️ Notification time calculated:', {
                now: new Date(now * 1000).toISOString(),
                expirationTime: new Date(expirationTime * 1000).toISOString(),
                notificationTime: new Date(notificationTime * 1000).toISOString(),
                minutesBeforeExpiration: notificationMinutes
            });

            // Add retry delay if this is a retry attempt
            if (retryCount > 0) {
                console.log(`🔄 Retry attempt ${retryCount}, waiting ${retryCount} seconds...`);
                await new Promise(resolve => setTimeout(resolve, retryCount * 1000));
            }

            console.log('📞 Calling scheduleNotification with data:', {
                playerId: player.id.toString(),
                playerName: player.fullName || `${player.firstName} ${player.lastName}`,
                notificationTime,
                expirationTime,
                type: 'beforeEnd',
                marketValue: player.marketValue?.toString() || '0',
                currentBid: player.price?.toString() || player.marketValue?.toString() || '0',
                notificationMinutes
            });

            const scheduledResult = await scheduleNotification({
                playerId: player.id.toString(),
                playerName: player.fullName || `${player.firstName} ${player.lastName}`,
                notificationTime,
                expirationTime,
                type: 'beforeEnd',
                marketValue: player.marketValue?.toString() || '0',
                currentBid: player.price?.toString() || player.marketValue?.toString() || '0',
                notificationMinutes
            });

            console.log('📱 Schedule notification result:', scheduledResult);

            if (!scheduledResult.success) {
                throw new Error('Benachrichtigung konnte nicht eingerichtet werden.');
            }

            localStorage.setItem(`notification_${player.id}`, JSON.stringify({
                scheduledTime: notificationTime * 1000,
                minutes: notificationMinutes,
                notificationId: scheduledResult.notificationId
            }));
            
            // Use the updateNotificationStatus function
            updateNotificationStatus(player.id, true);
            setShowTimingDialog(false);
            toast.success(
                `Benachrichtigung für ${player.fullName || `${player.firstName} ${player.lastName}`} ${formattedTime} vor Ablauf eingerichtet.`,
                { autoClose: 5000 }
            );
            return true;
        } catch (error) {
            console.error('❌ Detailed error in schedulePlayerNotification:', {
                error,
                message: error.message,
                code: error.code,
                stack: error.stack,
                player: {
                    id: player.id,
                    name: player.fullName || `${player.firstName} ${player.lastName}`
                },
                retryCount
            });

            // Handle specific error cases
            if (error.message.includes('Server error') && retryCount < 2) {
                console.log('🔄 Server error encountered, attempting retry...');
                toast.info('Versuche es erneut...');
                return schedulePlayerNotification(player, retryCount + 1);
            }

            if (error.message.includes('Authentication required')) {
                toast.error('Bitte melden Sie sich erneut an.');
                // Trigger re-authentication here if needed
                return false;
            }

            if (error.message.includes('Too many requests')) {
                toast.error('Zu viele Anfragen. Bitte warten Sie einen Moment.');
                return false;
            }

            toast.error(error.message || 'Fehler beim Einrichten der Benachrichtigung');
            return false;
        } finally {
            setNotificationLoadingStates(prev => ({ ...prev, [player.id]: false }));
        }
    }, [customNotificationMinutes, updateNotificationStatus]);

    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        console.log('Current userId from localStorage:', userId);

        // Ensure kickbaseUserId is saved in localStorage
        if (userId) {
            localStorage.setItem('kickbaseUserId', userId.toString());
            console.log('Saved kickbaseUserId to localStorage:', userId);
        } else {
            // Try to find user ID from user object if not directly available
            try {
                const userString = localStorage.getItem('user');
                if (userString) {
                    const user = JSON.parse(userString);
                    if (user && user.id) {
                        localStorage.setItem('kickbaseUserId', user.id.toString());
                        console.log('Found and saved user ID from user object:', user.id);
                    }
                }
            } catch (e) {
                console.error('Error parsing user from localStorage:', e);
            }
        }
    }, [userId]);

    const fetchData = async () => {
        setIsLoadingPlayers(true);
        try {
            if (!playerStore.leagueId) {
                setIsLoadingPlayers(false);
                return;
            }

            // Get market players with enhanced data
            const marketData = await playerService.getMarketPlayers(playerStore.leagueId);
            
            // Enhance each market player with detailed data
            const enhancedPlayersPromises = marketData.items.map(async marketPlayer => {
                try {
                    const enhancedData = await playerService.getEnhancedPlayerData(
                        playerStore.leagueId,
                        marketPlayer.id
                    );

                    // Combine market data with enhanced data
                    return {
                        ...enhancedData,
                        price: marketPlayer.price,
                        expireInSeconds: marketPlayer.expireInSeconds,
                        seller: marketPlayer.seller,
                        isNew: marketPlayer.isNew,
                        isPosLocked: marketPlayer.isPosLocked,
                        dateTime: marketPlayer.dateTime,
                    };
                } catch (error) {
                    console.error(`Error fetching enhanced data for market player ${marketPlayer.id}:`, error);
                    return null;
                }
            });

            const enhancedPlayers = await Promise.all(enhancedPlayersPromises);
            const validPlayers = enhancedPlayers.filter(Boolean);
            
            console.log('=== Market Overview Debug ===');
            console.log('Valid Players Count:', validPlayers.length);
            console.log('Sample Player Data:', validPlayers[0]);
            console.log('Player Fields:', validPlayers[0] ? Object.keys(validPlayers[0]) : 'No players');
            
            setPlayers(validPlayers);
            setIsLoadingPlayers(false); // Set loading to false after players are set
            
        } catch (error) {
            console.error('Error fetching market data:', error);
            setError('Failed to load market data');
            setIsLoadingPlayers(false); // Make sure to set loading to false even if there's an error
        }
    };

    // Fix useEffect dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 30000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (playerStore.marketPlayers) {
            setLoading(false);
        }
    }, [playerStore.marketPlayers]);

    // Calculate player data for each player
    useEffect(() => {
        const calculatedData = {};
        for (const player of players) {
            calculatedData[player.id] = calculatePlayerData(player, playerStore);
        }
        setCalculatedPlayerData(calculatedData);
    }, [players, playerStore]);

    // Update sorting logic
    const sortedAndFilteredPlayers = useMemo(() => {
        if (!players?.length) return [];
        
        let filteredPlayers = showAllPlayers 
            ? players 
            : players.filter(player => player && !player.seller);
        
        return filteredPlayers
            .filter(player => player != null)
            .sort((a, b) => {
                switch (sortType) {
                    case 'expire':
                        return a.expireInSeconds - b.expireInSeconds;
                    case 'marketValue':
                        return b.marketValue - a.marketValue;
                    case 'averagePoints':
                        return b.averagePoints - a.averagePoints;
                    default:
                        return a.expireInSeconds - b.expireInSeconds;
                }
            });
    }, [players, sortType, showAllPlayers]);

    useEffect(() => {
        console.log('Players state:', players);
        console.log('Sorted and filtered players:', sortedAndFilteredPlayers);
    }, [players, sortedAndFilteredPlayers]);

    const getFitColor = (percentage) => {
        if (percentage >= 90) return 'text-green-500';
        if (percentage >= 70) return 'text-green-400';
        if (percentage >= 50) return 'text-yellow-500';
        if (percentage >= 30) return 'text-orange-500';
        return 'text-red-500';
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
    };

    const getDetailedPerformance = (player) => {
        if (!player.seasonPerformance || player.seasonPerformance.length === 0) return null;
        const lastSeason = player.seasonPerformance[player.seasonPerformance.length - 1];
        if (!lastSeason || !lastSeason.ph || lastSeason.ph.length === 0) return null;
        
        const currentMatch = lastSeason.ph.find(match => match.cur === true);
        if (!currentMatch) return null;

        const gamesPlayed = lastSeason.ph.filter(match => match.p !== undefined).length;
        const gamesStarted = lastSeason.ph.filter(match => {
            if (!match.mp || !match.mp.includes("'")) return false;
            const minutes = parseInt(match.mp);
            return minutes >= 45;
        }).length;
        const gamesSubstitute = lastSeason.ph.filter(match => {
            if (!match.mp || !match.mp.includes("'")) return false;
            const minutes = parseInt(match.mp);
            return minutes < 45;
        }).length;
        
        const totalMinutes = lastSeason.ph.reduce((total, match) => {
            if (match.mp && match.mp.includes("'")) {
                return total + parseInt(match.mp);
            }
            return total;
        }, 0);

        // Get last match details
        const lastMatch = lastSeason.ph.filter(match => match.p !== undefined)
            .sort((a, b) => b.day - a.day)[0];

        return {
            averagePoints: currentMatch.ap || 0,
            totalPoints: currentMatch.tp || 0,
            matchesPlayed: gamesPlayed,
            matchesStarted: gamesStarted,
            matchesSubstitute: gamesSubstitute,
            totalMinutes: totalMinutes,
            season: lastSeason.ti,
            pointsPerGame: gamesPlayed > 0 ? (currentMatch.tp || 0) / gamesPlayed : 0,
            lastMatch: lastMatch ? {
                points: lastMatch.p,
                minutes: lastMatch.mp ? parseInt(lastMatch.mp) : 0,
                wasStarter: lastMatch.mp ? parseInt(lastMatch.mp) >= 45 : false,
                matchday: lastMatch.day,
                result: lastMatch.t1g !== undefined ? `${lastMatch.t1g}:${lastMatch.t2g}` : null
            } : null
        };
    };

    const togglePerformanceDetails = useCallback((playerId) => {
        console.log('Toggling performance details for player:', playerId);
        
        setShowPerformanceDetails(prev => {
            const newState = { ...prev, [playerId]: !prev[playerId] };
            
            // If we're opening the details, let's analyze the player's data
            if (newState[playerId]) {
                const player = players.find(p => p.id === playerId);
                if (player?.seasonPerformance?.length > 0) {
                    const lastSeason = player.seasonPerformance[player.seasonPerformance.length - 1];
                    const matches = lastSeason.ph || [];
                    
                    // Log match analysis
                    matches.forEach((match, index) => {
                        if (match.p !== undefined) {  // Only analyze matches with performance data
                            console.log(`Match ${index + 1} (Day ${match.day}):`, {
                                points: match.p,                    // Points scored
                                minutesPlayed: match.mp,           // Minutes played (e.g., "78'")
                                wasStarter: match.mp ? parseInt(match.mp) >= 45 : false,
                                result: match.t1g !== undefined ? `${match.t1g}:${match.t2g}` : 'N/A',
                                averagePoints: match.ap,           // Average points
                                totalPoints: match.tp,             // Total points
                                status: match.st,                  // Status (0-4)
                                current: match.cur                 // If this is the current matchday
                            });
                        }
                    });
                }
            }
            
            return newState;
        });
    }, [players]);

    // Handle click on the Compare button
    const handleCompareClick = (player) => {
        setSelectedPlayerForComparison(player);
        setShowComparison(true);
    };

    const handleNotificationRequest = async (playerId) => {
        const player = players.find(p => p.id === playerId);
        if (!player) {
            toast.error('Spieler konnte nicht gefunden werden.');
            return;
        }

        // Set loading state
        setNotificationLoadingStates(prev => ({ ...prev, [playerId]: true }));

        // If notifications are already enabled, disable them
        if (notificationEnabled[playerId]) {
            try {
                // Use updateNotificationStatus to update and persist the change
                updateNotificationStatus(playerId, false);
                
                // Use notificationService instead of requestForToken directly
                const token = await notificationService.getToken();
                if (token) {
                    await unsubscribeFromPlayerNotifications({ playerId, token });
                    
                    // Remove saved notification info
                    localStorage.removeItem(`notification_${playerId}`);
                    
                    // Only show toast, state is already updated
                    toast.success('Benachrichtigung deaktiviert');
                }
            } catch (error) {
                console.error('Error unsubscribing from notifications:', error);
                toast.error('Fehler beim Deaktivieren der Benachrichtigung');
                
                // Revert state on error
                updateNotificationStatus(playerId, true);
            } finally {
                // Clear loading state
                setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
            }
            return;
        }

        // Rest of the function remains the same...
        // Check if auction is still valid for notifications
        if (!player.expireInSeconds || player.expireInSeconds <= 60) {
            toast.error('Die Auktion endet zu früh für Benachrichtigungen.');
            setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
            return;
        }

        // First check if notifications are supported
        const supportInfo = notificationService.isSupported();
        
        if (!supportInfo.supported) {
            // Special handling for iOS devices (which can support notifications as PWAs)
            if (supportInfo.isIOS) {
                if (supportInfo.iosWebPushPossible) {
                    // iOS device with PWA installed on iOS 16.4+
                    toast.info(
                        <div className="text-sm">
                            <p className="font-bold mb-1">Fast geschafft!</p>
                            <p>Um Benachrichtigungen zu aktivieren, erlaube sie in den Einstellungen, wenn du dazu aufgefordert wirst.</p>
                        </div>, 
                        { autoClose: 8000 }
                    );
                } else if (supportInfo.isPWA) {
                    // PWA on older iOS
                    toast.error(
                        <div className="text-sm">
                            <p className="font-bold mb-1">iOS Version nicht unterstützt</p>
                            <p>Push-Benachrichtigungen werden ab iOS 16.4 unterstützt.</p>
                            <p className="mt-1">Bitte aktualisiere dein iOS oder nutze einen Desktop-Browser.</p>
                        </div>, 
                        { autoClose: 8000 }
                    );
                } else {
                    // Not in PWA mode yet
                    toast.info(
                        <div className="text-sm">
                            <p className="font-bold mb-1">Benachrichtigungen auf iOS aktivieren</p>
                            <p>Füge diese App zum Homescreen hinzu, um Push-Benachrichtigungen zu erhalten.</p>
                            <p className="mt-1">Tippe auf <span className="font-bold">Teilen</span> und dann <span className="font-bold">"Zum Home-Bildschirm"</span>, dann öffne die App vom Home-Bildschirm.</p>
                        </div>, 
                        { autoClose: 12000 }
                    );
                }
                setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
                return;
            }
            
            // For non-iOS mobile devices
            else if (supportInfo.isMobile) {
                toast.error(
                    <div className="text-sm">
                        <p className="font-bold mb-1">Benachrichtigungen werden auf diesem Gerät nicht unterstützt.</p>
                        <p>Leider unterstützen die meisten mobilen Browser keine Push-Benachrichtigungen.</p>
                        <p className="mt-1">Bitte versuche es auf einem Desktop-Browser für volle Benachrichtigungsfunktionalität.</p>
                    </div>, 
                    { autoClose: 8000 }
                );
                setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
                return;
            }
            
            // For desktop browsers that don't support notifications
            let errorMsg = 'Benachrichtigungen werden in diesem Browser nicht unterstützt.';
            if (!supportInfo.hasNotificationSupport) {
                errorMsg = 'Benachrichtigungen werden in diesem Browser nicht unterstützt.';
            } else if (!supportInfo.hasServiceWorkerSupport) {
                errorMsg = 'Service Worker werden in diesem Browser nicht unterstützt.';
            } else if (!supportInfo.hasMessagingSupport) {
                errorMsg = 'Firebase Messaging konnte nicht initialisiert werden.';
            }
            toast.error(errorMsg);
            setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
            return;
        }

        // Check if permissions need to be reset
        if (notificationService.needsPermissionReset()) {
            // Show notification settings dialog
            setShowNotificationSettings(true);
            setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
            return;
        }

        // Check permission status
        if (supportInfo.permission !== 'granted') {
            try {
                // Use our new force permission request method
                await notificationService.forcePermissionRequest();
                // Update permission status
                await checkNotificationPermissionStatus();
            } catch (error) {
                console.error('Error requesting notification permission:', error);
                
                // Special handling based on the error message
                if (error.message && error.message.includes('previously denied')) {
                    // Show notification settings dialog
                    setShowNotificationSettings(true);
                    setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
                    return;
                } else if (error.message && error.message.includes('add this app to your home screen')) {
                    toast.info(
                        <div className="text-sm">
                            <p className="font-bold mb-1">Benachrichtigungen auf iOS aktivieren</p>
                            <p>Füge diese App zum Homescreen hinzu, um Push-Benachrichtigungen zu erhalten.</p>
                            <p className="mt-1">Tippe auf <span className="font-bold">Teilen</span> und dann <span className="font-bold">"Zum Home-Bildschirm"</span>, dann öffne die App vom Home-Bildschirm.</p>
                        </div>, 
                        { autoClose: 12000 }
                    );
                    setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
                    return;
                } else if (error.message && error.message.includes('mobile device')) {
                    toast.error(
                        <div className="text-sm">
                            <p className="font-bold mb-1">Benachrichtigungen werden auf mobilen Geräten nicht unterstützt.</p>
                            <p>Bitte versuche es auf einem Desktop-Browser.</p>
                        </div>,
                        { autoClose: 8000 }
                    );
                    setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
                    return;
                } else if (error.message && error.message.includes('denied')) {
                    // User explicitly denied permission
                    toast.error(
                        <div className="text-sm">
                            <p className="font-bold mb-1">Benachrichtigungen blockiert</p>
                            <p>Du hast Benachrichtigungen blockiert. Bitte ändere die Einstellungen in deinem Browser, um Benachrichtigungen zu erlauben.</p>
                        </div>,
                        { autoClose: 8000 }
                    );
                    setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
                    return;
                } else {
                    // Generic error
                    toast.error('Fehler bei der Anfrage nach Benachrichtigungserlaubnis. Bitte prüfe deine Browser-Einstellungen.');
                    setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
                    return;
                }
            }
        }

        // If we get here, we have permission to show notifications
        setSelectedPlayerId(playerId);
        setShowTimingDialog(true);
        setNotificationLoadingStates(prev => ({ ...prev, [playerId]: false }));
    };

    const NotificationTimingDialog = () => (
        <Modal
            isOpen={showTimingDialog}
            onRequestClose={() => setShowTimingDialog(false)}
            className="bg-gray-800 rounded-lg p-4 sm:p-6 w-11/12 sm:w-96 mx-auto mt-20 relative"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4"
        >
            {/* Add close button */}
            <button
                onClick={() => setShowTimingDialog(false)}
                className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors duration-200"
                aria-label="Schließen"
            >
                ✕
            </button>
            <div className="flex flex-col space-y-4">
                <div className="relative bg-gray-900 rounded-xl p-4 border border-gray-700 shadow-lg">
                  
                    
                    <div className="flex flex-col space-y-4 mt-2">
                        <h2 className="text-white text-lg sm:text-xl font-semibold">
                            Wann möchtest du benachrichtigt werden?
                        </h2>
                        
                        <p className="text-gray-300 text-sm">
                            Wähle aus, wie lange vor Ablauf der Auktion du eine Benachrichtigung erhalten möchtest.
                        </p>

                        <div className="grid gap-2">
                            {standardTimings.map(timing => (
                                <button
                                    key={timing.value}
                                    onClick={() => {
                                        // Set the custom notification minutes first
                                        setCustomNotificationMinutes(timing.value.toString());
                                        setShowTimingDialog(false);
                                        
                                        // Find the player
                                        const player = players.find(p => p.id === selectedPlayerId);
                                        console.log('Selected timing:', timing.label, timing.value);
                                        console.log('Selected player:', player);
                                        
                                        if (player) {
                                            // Use the timing.value directly instead of relying on state update
                                            const notificationData = {
                                                ...player,
                                                customNotificationMinutes: timing.value // Pass the value directly to ensure it's used
                                            };
                                            schedulePlayerNotification(notificationData);
                                        } else {
                                            console.error('Player not found:', selectedPlayerId);
                                            toast.error('Spieler konnte nicht gefunden werden.');
                                        }
                                    }}
                                    className="w-full px-4 py-2 text-white bg-gray-700 hover:bg-gray-600 rounded-lg transition-colors duration-200"
                                >
                                    {timing.label}
                                </button>
                            ))}
                            
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-700"></div>
                                </div>
                                <div className="relative flex justify-center">
                                    <span className="px-2 bg-gray-900 text-gray-400 text-sm">oder</span>
                                </div>
                            </div>

                            <div className="mt-2">
                                {renderNotificationInput()}
                                <button
                                    onClick={() => {
                                        setShowTimingDialog(false);
                                        const player = players.find(p => p.id === selectedPlayerId);
                                        if (player) {
                                            // Pass the current value of customNotificationMinutes with the player data
                                            const notificationData = {
                                                ...player,
                                                customNotificationMinutes: parseInt(customNotificationMinutes)
                                            };
                                            schedulePlayerNotification(notificationData);
                                        }
                                    }}
                                    className="w-full mt-2 bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2 font-semibold"
                                >
                                    <FontAwesomeIcon icon={faBell} className="text-white" />
                                    <span>Benutzerdefinierte Zeit bestätigen</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );

    const requestNotificationPermission = async () => {
        const result = await Notification.requestPermission();
        setPermissionStatus(result);
        return result;
    };

    const [notificationLoading, setNotificationLoading] = useState({});

    const handleNotificationDialogConfirm = async () => {
        setShowNotificationDialog(false);
        const permission = await requestNotificationPermission();
        if (permission === 'granted' && pendingNotificationPlayerId) {
            handleNotificationRequest(pendingNotificationPlayerId);
        }
        setPendingNotificationPlayerId(null);
    };

    useEffect(() => {
        localStorage.setItem('notificationEnabled', JSON.stringify(notificationEnabled));
    }, [notificationEnabled]);

    // Single source of truth for messaging state
    const [messagingState, setMessagingState] = useState({
        isSupported: false,
        isPermissionGranted: false,
        hasToken: false,
        error: null
    });

    // Single messaging setup effect
    useEffect(() => {
        let isActive = true; // For cleanup

        const setupMessaging = async () => {
            try {
                // Use our improved notificationService to check support
                const supportInfo = notificationService.isSupported();
                if (!isActive) return;
                
                setMessagingState(prev => ({ 
                    ...prev, 
                    isSupported: supportInfo.supported,
                    isPermissionGranted: supportInfo.permission === 'granted',
                    error: !supportInfo.supported ? 'Notifications not supported in this browser' : null
                }));
                
                if (!supportInfo.supported) {
                    console.warn('Push notifications not supported in this browser:', supportInfo);
                    return;
                }

                // Request permission if not granted
                if (supportInfo.permission !== 'granted') {
                    try {
                        await notificationService.requestPermission();
                        if (!isActive) return;
                        
                        setMessagingState(prev => ({ 
                            ...prev, 
                            isPermissionGranted: true 
                        }));
                    } catch (error) {
                        console.error('Permission request error:', error);
                        setMessagingState(prev => ({ 
                            ...prev, 
                            isPermissionGranted: false, 
                            error: 'Failed to request permission'
                        }));
                        return;
                    }
                }

                // Try to get FCM token
                try {
                    const token = await notificationService.getToken();
                    if (!isActive) return;

                    if (!token) {
                        setMessagingState(prev => ({ 
                            ...prev, 
                            hasToken: false, 
                            error: 'Failed to get FCM token'
                        }));
                        return;
                    }

                    setMessagingState(prev => ({ ...prev, hasToken: true }));
                    console.log('FCM token obtained successfully');
                } catch (error) {
                    console.error('Token request error:', error);
                    setMessagingState(prev => ({ 
                        ...prev, 
                        hasToken: false, 
                        error: 'Failed to get FCM token'
                    }));
                    return;
                }

                // We don't need to set up a message listener here anymore
                // as it's now handled globally in App.js
            } catch (error) {
                console.error('Messaging setup error:', error);
                if (isActive) {
                    setMessagingState(prev => ({ 
                        ...prev, 
                        error: 'Failed to setup messaging'
                    }));
                }
            }
        };

        setupMessaging();

        // Cleanup
        return () => {
            isActive = false;
        };
    }, []); // No dependencies needed
    
    // Log messaging state changes for debugging
    useEffect(() => {
        console.log('Messaging state:', messagingState);
    }, [messagingState]);

    // Add a function to check notification permission status
    const checkNotificationPermissionStatus = useCallback(async () => {
        if (!notificationService.notificationsSupported) {
            setPermissionStatus('not-supported');
            return;
        }

        // Check if permissions API is available
        if (navigator.permissions) {
            try {
                const status = await navigator.permissions.query({ name: 'notifications' });
                setPermissionStatus(status.state);
                
                // Add event listener to track permission changes
                status.onchange = () => {
                    setPermissionStatus(status.state);
                    console.log('Notification permission changed to:', status.state);
                };
            } catch (error) {
                console.error('Error querying notification permission:', error);
                // Fallback to standard check
                setPermissionStatus(Notification.permission);
            }
        } else {
            // Fallback for browsers without Permissions API
            setPermissionStatus(Notification.permission);
        }
    }, []);

    // Check permission status on component mount
    useEffect(() => {
        checkNotificationPermissionStatus();
    }, [checkNotificationPermissionStatus]);

    // Add a function to handle notification permission request
    const handleRequestNotificationPermission = async () => {
        try {
            await notificationService.forcePermissionRequest();
            await checkNotificationPermissionStatus();
            toast.success('Benachrichtigungen wurden erfolgreich aktiviert!');
        } catch (error) {
            console.error('Error requesting notification permission:', error);
            
            // Show appropriate error message based on the error
            if (error.message.includes('previously denied') || error.message.includes('blocked')) {
                const resetInfo = notificationService.getPermissionResetInstructions();
                toast.error(
                    <div className="text-sm">
                        <p className="font-bold mb-1">Benachrichtigungen sind blockiert</p>
                        <p>Um Benachrichtigungen zu aktivieren, musst du die Einstellungen in deinem Browser ändern:</p>
                        <p className="mt-1 font-semibold">{resetInfo.instructions}</p>
                    </div>,
                    { autoClose: 10000 }
                );
            } else {
                toast.error(`Fehler beim Aktivieren der Benachrichtigungen: ${error.message}`);
            }
        }
    };

    // Add a notification debugging section
    const NotificationDebuggingInfo = () => {
        const [testResult, setTestResult] = useState(null);
        
        const sendTestNotification = async () => {
            try {
                // Try direct notification first
                console.log('🧪 Testing direct notification display...');
                const directResult = await testNotification();
                
                if (directResult.success) {
                    setTestResult({
                        success: true,
                        message: 'Direkte Benachrichtigung erfolgreich angezeigt!'
                    });
                    return;
                }
                
                console.log('⚠️ Direct notification failed, trying scheduled notification');
                
                // Try scheduled notification as fallback
                const now = Math.floor(Date.now() / 1000);
                const result = await scheduleNotification({
                    playerId: 'test-notification',
                    playerName: 'Test Spieler',
                    notificationTime: now + 10, // Show in 10 seconds
                    expirationTime: now + 3600, // Expires in 1 hour
                    type: 'test',
                    marketValue: '1000000',
                    currentBid: '1000000',
                    notificationMinutes: 60
                });
                
                console.log('📱 Test notification result:', result);
                
                if (result.success) {
                    setTestResult({
                        success: true,
                        message: 'Test-Benachrichtigung geplant! Sie sollte in 10 Sekunden erscheinen.'
                    });
                } else {
                    setTestResult({
                        success: false,
                        message: 'Fehler beim Planen der Test-Benachrichtigung: ' + (result.error || 'Unbekannter Fehler')
                    });
                }
            } catch (error) {
                console.error('❌ Error testing notification:', error);
                setTestResult({
                    success: false,
                    message: 'Fehler: ' + error.message
                });
            }
        };

        return (
            <div className="mt-6 p-4 bg-gray-800 rounded-lg shadow">
                <h4 className="text-lg font-bold text-white mb-2">Benachrichtigungen Testen</h4>
                
                <div className="flex flex-col gap-2">
                    <button
                        onClick={sendTestNotification}
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                    >
                        Test-Benachrichtigung senden
                    </button>
                    
                    {testResult && (
                        <div className={`mt-2 p-2 rounded ${testResult.success ? 'bg-green-800' : 'bg-red-800'}`}>
                            {testResult.message}
                        </div>
                    )}
                    
                    <div className="mt-4 text-sm text-gray-400">
                        <p>Notification API Status:</p>
                        <ul className="list-disc pl-5 mt-1">
                            <li>Permission: {Notification.permission}</li>
                            <li>Service Worker: {'serviceWorker' in navigator ? 'Unterstützt' : 'Nicht unterstützt'}</li>
                            <li>Notifications: {'Notification' in window ? 'Unterstützt' : 'Nicht unterstützt'}</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    // Add a notification settings dialog component
    const NotificationSettingsDialog = () => (
        <Modal
            isOpen={showNotificationSettings}
            onRequestClose={() => setShowNotificationSettings(false)}
            className="bg-gray-800 rounded-lg p-4 sm:p-6 w-11/12 sm:w-96 mx-auto mt-20 relative"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4"
        >
            {/* Add close button */}
            <button
                onClick={() => setShowNotificationSettings(false)}
                className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors duration-200"
                aria-label="Schließen"
            >
                ✕
            </button>
            <div className="flex flex-col space-y-4">
                <div className="relative bg-gray-900 rounded-xl p-4 border border-gray-700 shadow-lg">
                    <div className="absolute -top-3 left-4 px-2 bg-gray-900">
                        <span className="text-orange-500 text-sm font-medium">Benachrichtigungseinstellungen</span>
                    </div>
                    
                    <div className="pt-2">
                        <p className="text-white mb-4">
                            Aktiviere Benachrichtigungen, um informiert zu werden, wenn ein Spieler kurz vor dem Auslaufen steht.
                        </p>
                        
                        <div className="flex flex-col space-y-2">
                            <button
                                onClick={handleRequestNotificationPermission}
                                disabled={permissionStatus === 'granted' || permissionStatus === 'not-supported'}
                                className={`w-full py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2 font-semibold
                                    ${permissionStatus === 'granted' || permissionStatus === 'not-supported' 
                                        ? 'bg-gray-600 text-gray-400 cursor-not-allowed' 
                                        : 'bg-orange-500 hover:bg-orange-600 text-white'}`}
                            >
                                <FontAwesomeIcon icon={faBell} />
                                <span>
                                    {permissionStatus === 'granted' 
                                        ? 'Benachrichtigungen bereits aktiviert' 
                                        : permissionStatus === 'not-supported'
                                            ? 'Benachrichtigungen nicht unterstützt'
                                            : 'Benachrichtigungen aktivieren'}
                                </span>
                            </button>
                            
                            <button
                                onClick={() => setShowNotificationSettings(false)}
                                className="w-full bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded-lg transition-colors duration-200"
                            >
                                Schließen
                            </button>
                        </div>
                        
                        {/* Add the debugging component */}
                        <NotificationDebuggingInfo />
                    </div>
                </div>
            </div>
        </Modal>
    );

    const renderNotificationInput = () => {
        // Definiere Zeitoptionen für das Scrollrad - nach Stunden und Minuten gruppiert
        const timeOptions = [
            // Minuten
            { label: '5 Minuten', value: 5 },
            { label: '10 Minuten', value: 10 },
            { label: '15 Minuten', value: 15 },
            { label: '20 Minuten', value: 20 },
            { label: '30 Minuten', value: 30 },
            { label: '45 Minuten', value: 45 },
            // Stunden
            { label: '1 Stunde', value: 60 },
            { label: '1,5 Stunden', value: 90 },
            { label: '2 Stunden', value: 120 },
            { label: '3 Stunden', value: 180 },
            { label: '4 Stunden', value: 240 },
            { label: '5 Stunden', value: 300 },
            { label: '6 Stunden', value: 360 },
            { label: '8 Stunden', value: 480 },
            { label: '10 Stunden', value: 600 },
            { label: '12 Stunden', value: 720 },
            { label: '18 Stunden', value: 1080 },
            { label: '24 Stunden', value: 1440 },
        ];

        // Finde den aktuell ausgewählten Wert oder den nächstgelegenen
        const selectedValue = parseInt(customNotificationMinutes) || 10;
        
        return (
            <div className="notification-timing-input">
                <div className="text-sm text-white mb-2">Benachrichtigung vor Ablauf:</div>
                <div className="relative bg-gray-700 rounded-lg overflow-hidden border border-gray-600">
                    <select
                        className="w-full py-3 px-4 bg-gray-700 text-white appearance-none focus:outline-none focus:ring-2 focus:ring-orange-500"
                        value={selectedValue}
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setCustomNotificationMinutes(value.toString());
                        }}
                    >
                        {timeOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                        <FontAwesomeIcon icon={faChevronDown} className="text-gray-400" />
                    </div>
                </div>
            </div>
        );
    };

    // Add missing canBuyPlayer function
    const canBuyPlayer = (player, playerData) => {
        if (!playerStore.leagueInfo) {
            return { canBuy: true, reason: "" }; // Default to allowing purchase if we don't have league info
        }

        const teamPlayersCount = playerStore.players.filter(p => p.teamId === player.teamId).length;
        const totalPlayersCount = playerStore.players.length;
        const financialPercentage = playerData.financialPercentage;

        if (teamPlayersCount >= playerStore.leagueInfo.mpst) {
            return { canBuy: false, reason: "Maximale Spieler pro Team erreicht" };
        }
        if (totalPlayersCount >= playerStore.leagueInfo.pl) {
            return { canBuy: false, reason: "Maximale Spieleranzahl erreicht" };
        }
        if (financialPercentage > 100) {
            return { canBuy: false, reason: "Nicht genug Budget" };
        }
        return { canBuy: true, reason: "" };
    };

    // Add missing toggleRecommendationDetails function
    const toggleRecommendationDetails = (playerId) => {
        setShowRecommendationDetails(prev => ({
            ...prev,
            [playerId]: !prev[playerId]
        }));
    };

    return (
        <div key={renderKey} className="p-2 sm:p-4">
            <NotificationTimingDialog />
            <NotificationSettingsDialog />
            {/* Filter and Button Section */}
            <div className="bg-gray-800 text-white p-4 rounded shadow-md flex flex-col md:flex-row justify-between items-center mb-4 w-full">
                <div className="w-full md:w-auto mb-4 md:mb-0">
                    <select
                        value={sortType}
                        onChange={e => setSortType(e.target.value)}
                        className="w-full md:w-auto p-2 border bg-gray-700 text-white focus:outline-none focus:ring-1 focus:ring-indigo-500 rounded"
                    >
                        <option value="expire">Zeit bis Ablauf</option>
                        <option value="marketValue">Marktwert</option>
                        <option value="averagePoints">Durchschnittspunkte</option>
                        <option value="pointsPerEuro">Durchsch. Punkte/Euro</option>
                        <option value="valueChange">MW-Veränderung</option>
                        <option value="valueForMoney">Gesamtpunkte/Euro</option>
                    </select>
                </div>

                <div className="flex items-center">
                    <label htmlFor="showAllPlayers" className="flex items-center cursor-pointer mr-4">
                        <div className="relative">
                            <input
                                id="showAllPlayers"
                                type="checkbox"
                                className="hidden"
                                checked={showAllPlayers}
                                onChange={() => setShowAllPlayers(prev => !prev)}
                            />
                            <div className="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                            <div className="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                        </div>
                        <div className="ml-3 text-white">Alle</div>
                    </label>
                </div>
            </div>

            {/* Loading Spinner */}
            {isLoadingPlayers ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <LoadingSpinner size="large" />
                </div>
            ) : error ? (
                <div className="text-center text-red-500 p-4">
                    {error}
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                    {sortedAndFilteredPlayers.map(player => {
                     

                        const playerData = calculatedPlayerData[player.id] || {};
                        const trend = calculateMarketValueTrend(player);
                        const { canBuy, reason } = canBuyPlayer(player, playerData);
                        const values = player.performanceHistory || [];

                        return (
                            <div key={player.id} className="bg-gray-800 p-6 rounded-xl shadow-2xl max-w-md transition-transform relative mr-2">
                                {/* Status Badge - New! */}
                                {player.isNew && (
                                    <div className="absolute top-2 left-2 bg-green-500 text-white px-2 py-1 rounded-full text-xs">
                                        Neu
                                    </div>
                                )}
                                
                                
                            

                                {/* Notification button */}
                                {permissionStatus === 'granted' && (
                                    <button 
                                        onClick={() => handleNotificationRequest(player.id)}
                                        className="absolute top-4 right-4 transition-colors duration-200"
                                        aria-label={notificationEnabled[player.id] ? "Benachrichtigungen deaktivieren" : "Benachrichtigungen aktivieren"}
                                        disabled={notificationLoadingStates[player.id]}
                                        tabIndex={0}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                                handleNotificationRequest(player.id);
                                            }
                                        }}
                                    >
                                        <div className={`relative ${notificationLoadingStates[player.id] ? 'animate-pulse' : ''}`}>
                                            {notificationLoadingStates[player.id] && (
                                                <div className="absolute inset-0 bg-[#f23d00] opacity-25 rounded-full animate-ping"></div>
                                            )}
                                            <FontAwesomeIcon 
                                                icon={notificationEnabled[player.id] ? faBell : faBellSlash} 
                                                className={`text-1xl ${
                                                    notificationEnabled[player.id] 
                                                        ? 'text-[#f23d00]' 
                                                        : notificationLoadingStates[player.id]
                                                            ? 'text-[#f23d00] opacity-75'
                                                            : 'text-white hover:text-[#f23d00]'
                                                }`}
                                            />
                                        </div>
                                    </button>
                                )}

                                {/* Player basic info */}
                                <div className="flex items-center mb-4">
                                    <img 
                                        src={`https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`}
                                        alt={`${player.firstName} ${player.lastName}`}
                                        className="w-16 h-16 rounded-full mr-4 object-cover object-center"
                                      
                                    />
                                    <div>
                                        <h4 className="text-lg font-bold text-white">{player.firstName} {player.lastName}</h4>
                                        <div className="flex items-center mt-1">
                                            <img 
                                                src={`https://kickbase.b-cdn.net/pool/teams/${player.teamId}.png`}
                                                alt={player.teamName}
                                                className="w-6 h-6 mr-2 rounded-full"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;
                                                }}
                                            />
                                            <span className="text-xs text-gray-400">{player.teamName}</span>
                                        </div>
                                        <div className="flex items-center mt-1">
                                            <span className="text-xs text-gray-400">
                                                {player.position === 1 ? 'Torwart' : 
                                                 player.position === 2 ? 'Abwehr' : 
                                                 player.position === 3 ? 'Mittelfeld' : 'Sturm'}
                                            </span>
                                            <span className="text-xs text-gray-400 ml-2">#{player.shirtNumber}</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Market value and expiry time - Made prominent */}
                                <div className="mb-4">
                                    <button
                                        onClick={() => { setSelectedPlayer(player); setDataModalOpen(true); }}
                                        className="w-full bg-[#11a0f8] hover:bg-[#0d8ad9] text-white text-center py-1.5 px-3 rounded-lg transition-colors duration-300 text-lg mb-1 font-semibold"
                                    >
                                        {formatCurrency(player.marketValue)}
                                    </button>
                                    <div className="text-center font-semibold text-lg text-white mb-3">
                                        {formatTimeRemaining(player.expireInSeconds)}
                                    </div>
                                </div>

                                {/* Stats Grid - Compact version */}
                                <div className="grid grid-cols-2 gap-2 mb-3">
                                    <div className="bg-gray-700 p-1.5 rounded-lg text-center">
                                        <span className="block text-xs text-gray-400">Ø Punkte</span>
                                        <span className="text-base font-semibold text-white">{player.averagePoints || '0'}</span>
                                    </div>
                                    <div className="bg-gray-700 p-1.5 rounded-lg text-center">
                                        <span className="block text-xs text-gray-400">Gesamt</span>
                                        <span className="text-base font-semibold text-white">{player.totalPoints || '0'}</span>
                                    </div>
                                    <div className="bg-gray-700 p-1.5 rounded-lg text-center">
                                        <span className="block text-xs text-gray-400">Tore</span>
                                        <span className="text-base font-semibold text-white">{player.goals || '0'}</span>
                                    </div>
                                    <div className="bg-gray-700 p-1.5 rounded-lg text-center">
                                        <span className="block text-xs text-gray-400">Startelf</span>
                                        <span className="text-base font-semibold text-white">
                                            {(() => {
                                                const performance = getDetailedPerformance(player);
                                                if (!performance) return '0/0';
                                                return `${performance.matchesStarted}/${performance.matchesPlayed}`;
                                            })()}
                                        </span>
                                    </div>
                                </div>

                                {/* Market Trend - Compact version */}
                                <div className="bg-gray-700 p-1.5 rounded-lg flex justify-center items-center mb-2">
                                    <span className={`text-base font-semibold flex items-center gap-1.5 
                                        ${player.fourHourTrend > 0 ? 'text-green-500' : 
                                          player.fourHourTrend < 0 ? 'text-red-500' : 
                                          'text-gray-400'}`}
                                    >
                                        {formatCurrency(player.fourHourTrend || 0)}
                                        {player.fourHourTrend > 0 ? <FontAwesomeIcon icon={faArrowUp} size="sm" /> : 
                                         player.fourHourTrend < 0 ? <FontAwesomeIcon icon={faArrowDown} size="sm" /> : null}
                                    </span>
                                </div>

                                {/* Performance History - Only show if enough data */}
                                {player.performanceHistory && player.performanceHistory.filter(p => p && typeof p.p !== 'undefined').length >= 2 && (
                                    <div className="bg-gray-700 p-1.5 rounded-lg mb-2">
                                        <span className="text-xs text-gray-400 block mb-1">Performanceverlauf:</span>
                                        <MiniTrendGraph 
                                            data={player.performanceHistory
                                                .filter(p => p && typeof p.p !== 'undefined')
                                                .map(p => ({ m: p.p }))} 
                                        />
                                    </div>
                                )}

                                {/* Market Value Change */}
                                {player.marketStats && (
                                    <div className="bg-gray-700 p-3 rounded-lg mb-3">
                                        <span className="text-sm text-gray-400 block mb-2 text-center">Marktwertänderung (4h):</span>
                                        <div className={`text-base font-semibold text-center ${player.marketStats.fourHourTrend > 0 ? 'text-green-400' : player.marketStats.fourHourTrend < 0 ? 'text-red-400' : 'text-gray-400'}`}>
                                            {player.marketStats.fourHourTrend !== 0 ? (
                                                <div>
                                                    {player.marketStats.fourHourTrend > 0 ? '+' : ''}{formatNumber(player.marketStats.fourHourTrend)} €
                                                    <div className="text-sm mt-1">
                                                        ({player.marketStats.fourHourTrend > 0 ? '+' : ''}{((player.marketStats.fourHourTrend / player.marketValue * 100) || 0).toFixed(1)}%)
                                                    </div>
                                                </div>
                                            ) : (
                                                'Keine Änderung'
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* Next Matches */}
                                {player.upcomingMatches && player.upcomingMatches.length > 1 && (
                                    <div className="bg-gray-700 p-3 rounded-lg">
                                        <span className="text-sm text-gray-400 block mb-2">Nächste Spiele:</span>
                                        <div className="flex justify-center gap-6">
                                            {player.upcomingMatches.slice(1, 3).map((match, index) => {
                                                const isHome = match.t1 === player.teamId;
                                                const opponentTeamId = isHome ? match.t2 : match.t1;
                                                
                                                return (
                                                    <div 
                                                        key={index} 
                                                        className="flex flex-col items-center bg-gray-800 rounded-lg p-2"
                                                    >
                                                        <div className="relative">
                                                            <img
                                                                src={`https://kickbase.b-cdn.net/pool/teams/${opponentTeamId}.png`}
                                                                alt="Opponent"
                                                                className="w-8 h-8 rounded-full bg-gray-700 p-1"
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${opponentTeamId}.png`;
                                                                }}
                                                                loading="eager"
                                                            />
                                                            <div className={`absolute -bottom-1 -right-1 w-4 h-4 rounded-full flex items-center justify-center text-[10px] font-medium
                                                                ${isHome ? 'bg-green-500/20 text-green-400' : 'bg-blue-500/20 text-blue-400'}`}>
                                                                {isHome ? 'H' : 'A'}
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col items-center mt-1">
                                                            {match.day && (
                                                                <span className="text-[10px] text-gray-400">
                                                                    {`ST ${match.day}`}
                                                                </span>
                                                            )}
                                                            {match.date && (
                                                                <span className="text-[10px] text-gray-400">
                                                                    {new Date(match.date).toLocaleDateString('de-DE', { 
                                                                        month: 'numeric', 
                                                                        day: 'numeric' 
                                                                    })}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}

                                {/* Recommendation section */}
                                <div className="bg-gray-700  rounded-lg mt-4">
                                    <div className="flex justify-between items-center cursor-pointer mb-2" onClick={() => toggleRecommendationDetails(player.id)}>
                                        {player.status === 1 ? (
                                            <span className="text-orange-500 font-medium p-4">
                                                {player.statusText}
                                            </span>
                                        ) : null}
                                        <div className="flex items-center">
                                            {!player.status === 1 && (
                                                <div>
                                                    <span className={`font-bold ${getFitColor(playerData.fitPercentage)} mr-2`}>
                                                        {isNaN(playerData.fitPercentage) ? '0' : Math.round(playerData.fitPercentage)}%
                                                    </span>
                                                    <FontAwesomeIcon
                                                        icon={showRecommendationDetails[player.id] ? faChevronUp : faChevronDown}
                                                        className="text-blue-500"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Keep the recommendation details section, but only show if status is not 1 */}
                                    {!player.status === 1 && showRecommendationDetails[player.id] && (
                                        <div className="mt-4 space-y-2 text-sm">
                                            {/* Form */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Form:</span>
                                                <span>{playerData.form || 'Normale Form'}</span>
                                            </div>

                                            {/* Daily Value Increase */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Tägliche Wertsteigerung:</span>
                                                <span>{formatCurrency(player.marketValueIncrease)}</span>
                                            </div>

                                            {/* Future Trend */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Zukünftiger Trend:</span>
                                                <span>{playerData.futureValueIncrease ? formatCurrency(playerData.futureValueIncrease.estimatedDailyChange) : 'N/A'}</span>
                                            </div>

                                            {/* Position Comparison */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Vergleich auf der Pos.:</span>
                                                <span>{playerData.comparisonResult ? `${playerData.comparisonResult.difference.toFixed(1)} Punkte/Spiel` : 'Keine Daten'}</span>
                                            </div>

                                            {/* Financial Fit */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Finanzielle Passung:</span>
                                                <span>{!isNaN(playerData.financialPercentage) ? `${playerData.financialPercentage.toFixed(1)}% vom Budget` : 'Keine Daten'}</span>
                                            </div>

                                            {/* Freie Plätze */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Freie Plätze auf Pos.:</span>
                                                <span>
                                                    {playerData.positionCounts[player.position] > playerData.maxPlayers[player.position] 
                                                        ? '-2' 
                                                        : `${playerData.positionCounts[player.position] || 0} / ${playerData.maxPlayers[player.position] || 'N/A'}`}
                                                </span>
                                            </div>

                                            {/* Players from Team */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Spieler aus dem Team:</span>
                                                <span>{playerData.teamPlayersCount !== undefined ? `${playerData.teamPlayersCount} im Team` : 'Keine Daten'}</span>
                                            </div>

                                            {/* Estimated Sale Price */}
                                            {/* <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Geschätzter Verkaufspreis:</span>
                                                <span>{playerData.estimatedSalePrice === '-' ? '-' : `${formatCurrency(playerData.estimatedSalePrice)} `}</span>
                                            </div>

                                           
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Spiele absolviert:</span>
                                                <span>{`${playerData.gamesPlayed} / ${playerData.totalGames}`}</span>
                                            </div> */}
                                        </div>
                                    )}
                                </div>

                                {/* Add VoteButtons component with info icon */}
                                <div className="mt-4">
                                    <div className="bg-gray-700 p-2 rounded-lg">
                                        <div className="flex items-center justify-between">
                                            <span className="text-sm font-bold text-white flex items-center">
                                                Deine Einschätzung
                                                <FontAwesomeIcon 
                                                    icon={faInfoCircle} 
                                                    className="ml-2 text-blue-400 cursor-pointer"
                                                    data-tooltip-id="vote-info"
                                                    data-tooltip-content="Gib deine Meinung ab, ob du diesen Spieler zu diesem Zeitpunkt kaufen oder empfehlen würdest. Die Bewertungen werden alle vier Wochen zurückgesetzt."
                                                />
                                            </span>
                                            <VoteButtons 
                                                playerId={player.id} 
                                                userId={userId} 
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    onClick={() => handleCompareClick(player)}
                                    className="w-full mt-4 bg-[#11a0f8] hover:bg-[#0d8ad9] text-white py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2 font-semibold"
                                >
                                    <FontAwesomeIcon icon={faChartBar} className="text-white" />
                                    <span>Vergleichen</span>
                                </button>

                                {/* Performance section - Enhanced */}
                                {player.seasonPerformance && (
                                    <div className="bg-gray-700 p-3 rounded-lg mb-3">
                                        <div 
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() => togglePerformanceDetails(player.id)}
                                        >
                                            <span className="text-sm text-gray-400">Aktuelle Saison:</span>
                                            <FontAwesomeIcon
                                                icon={showPerformanceDetails[player.id] ? faChevronUp : faChevronDown}
                                                className="text-gray-400"
                                            />
                                        </div>
                                        {(() => {
                                            const performance = getDetailedPerformance(player);
                                            if (!performance) return <span className="text-sm">Keine Daten verfügbar</span>;
                                            
                                            return (
                                                <div className={`space-y-2 ${showPerformanceDetails[player.id] ? 'mt-3' : 'mt-2'}`}>
                                                    <div className="flex justify-between items-center">
                                                        <span className="text-sm">Ø Punkte:</span>
                                                        <span className="font-semibold">{performance.averagePoints.toFixed(1)}</span>
                                                    </div>
                                                    {showPerformanceDetails[player.id] && (
                                                        <div>
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-sm">Punkte pro Spiel:</span>
                                                                <span className="font-semibold">{performance.pointsPerGame.toFixed(1)}</span>
                                                            </div>
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-sm">Gesamtpunkte:</span>
                                                                <span className="font-semibold">{performance.totalPoints}</span>
                                                            </div>
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-sm">Spiele gesamt:</span>
                                                                <span className="font-semibold">{performance.matchesPlayed}</span>
                                                            </div>
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-sm">Startelf:</span>
                                                                <span className="font-semibold">{performance.matchesStarted}x</span>
                                                            </div>
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-sm">Eingewechselt:</span>
                                                                <span className="font-semibold">{performance.matchesSubstitute}x</span>
                                                            </div>
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-sm">Spielminuten:</span>
                                                                <span className="font-semibold">{performance.totalMinutes}'</span>
                                                            </div>
                                                            {performance.lastMatch && (
                                                                <div className="mt-3 pt-2 border-t border-gray-600">
                                                                    <span className="text-sm text-gray-400 block mb-2">Letztes Spiel (ST {performance.lastMatch.matchday}):</span>
                                                                    <div className="flex justify-between items-center">
                                                                        <span className="text-sm">Punkte:</span>
                                                                        <span className={`font-semibold ${performance.lastMatch.points >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                                                            {performance.lastMatch.points}
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex justify-between items-center">
                                                                        <span className="text-sm">Einsatz:</span>
                                                                        <span className="font-semibold">
                                                                            {performance.lastMatch.wasStarter ? 'Startelf' : 'Eingewechselt'} ({performance.lastMatch.minutes}')
                                                                        </span>
                                                                    </div>
                                                                    {performance.lastMatch.result && (
                                                                        <div className="flex justify-between items-center">
                                                                            <span className="text-sm">Ergebnis:</span>
                                                                            <span className="font-semibold">{performance.lastMatch.result}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })()}
                                    </div>
                                )}

                            </div>
                        );
                    })}
                </div>
            )}

            {/* Modals for Player Detail and Search */}
            {dataModalOpen && selectedPlayer && (
                <PlayerDetailModal 
                    player={selectedPlayer} 
                    onClose={() => setDataModalOpen(false)}
                />
            )}
            {searchModalOpen && (
                <Modal
                    isOpen={searchModalOpen}
                    onRequestClose={() => setSearchModalOpen(false)}
                    overlayClassName="fixed inset-0 flex items-center justify-center z-50"
                    className="bg-transparent"
                >
                    <button
                        className="absolute top-2 right-2 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                        onClick={() => setSearchModalOpen(false)}
                    >
                        &times;
                    </button>
                    <PlayerSearch onClose={() => setSearchModalOpen(false)} />
                </Modal>
            )}

            {showNotificationDialog && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm">
                        <h2 className="text-xl font-bold mb-4">Benachrichtigungen aktivieren</h2>
                        <p className="mb-4">
                            Wir möchten dir Benachrichtigungen senden, um dich 10 Minuten vor dem Auktionsende eines Spielers zu informieren. 
                            Dies hilft dir, keine wichtigen Auktionen zu verpassen.
                        </p>
                        <div className="flex justify-end space-x-4">
                            <button 
                                onClick={() => setShowNotificationDialog(false)} 
                                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                            >
                                Abbrechen
                            </button>
                            <button 
                                onClick={handleNotificationDialogConfirm} 
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                                Erlauben
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Add Tooltip component outside of the map function */}
            <Tooltip id="vote-info" place="top" effect="solid" className="max-w-xs text-sm" />

            {/* Add a button in your component to trigger this function */}

            {showComparison && selectedPlayerForComparison && (
                <PlayerComparisonOverlay
                    currentPlayer={selectedPlayerForComparison}
                    onClose={() => setShowComparison(false)}
                />
            )}
        </div>
    );
}
);

export default MarketOverview;