import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { useVotes } from '../contexts/VoteContext';
import PropTypes from 'prop-types';

// Cache to track last fetch time per playerId
const lastFetchTimesCache = {};
const FETCH_INTERVAL_MS = 5 * 60 * 1000; // 5 minutes in milliseconds

const VoteButtons = ({ playerId, userId }) => {
  const { 
    votes, 
    userVotes, 
    isLoading, 
    fetchVotes, 
    fetchUserVote, 
    handleVote 
  } = useVotes();
  
  // Add local state for mock voting when no userId is available
  const [mockUserVote, setMockUserVote] = useState(null);
  const [mockVotes, setMockVotes] = useState({ upvotes: 0, downvotes: 0 });
  const initialLoadRef = useRef(true);
  const isMountedRef = useRef(true);
  const fetchRequestedRef = useRef(false);

  // Main effect to fetch votes data with proper caching
  useEffect(() => {
    // Set isMounted flag
    isMountedRef.current = true;
    
    // Cleanup function to prevent state updates after unmount
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Separate effect to handle data fetching
  useEffect(() => {
    if (!playerId || fetchRequestedRef.current) return;

    const fetchData = async () => {
      const now = Date.now();
      const cacheKey = `${playerId}-${userId || 'anonymous'}`;
      const lastFetchTime = lastFetchTimesCache[cacheKey] || 0;
      const shouldFetch = initialLoadRef.current || (now - lastFetchTime > FETCH_INTERVAL_MS);
      
      if (!shouldFetch) return;
      
      // Mark fetch as requested to prevent multiple requests
      fetchRequestedRef.current = true;
      
      try {
        await fetchVotes(playerId);
        lastFetchTimesCache[`${playerId}-votes`] = now;
        
        if (userId) {
          await fetchUserVote(playerId, userId);
          lastFetchTimesCache[cacheKey] = now;
        }
      } catch (error) {
        // Silent error handling for production
      } finally {
        if (isMountedRef.current) {
          initialLoadRef.current = false;
          fetchRequestedRef.current = false;
        }
      }
    };

    fetchData();
  }, [playerId, userId, fetchVotes, fetchUserVote]); // Removed votes from dependencies
  
  // Sync mock votes with real votes when they change
  useEffect(() => {
    if (!playerId || !userId) return;
    
    const playerVotesData = votes[playerId];
    if (!playerVotesData) return;
    
    // Update mock votes only if user is not logged in
    if (!userId && isMountedRef.current) {
      setMockVotes(playerVotesData);
    }
  }, [votes, playerId, userId]);

  // Handle mock voting when no userId is available
  const handleMockVote = (voteType) => {
    if (mockUserVote === voteType) {
      // Remove vote
      setMockUserVote(null);
      setMockVotes(prev => ({
        ...prev,
        [voteType === 'upvote' ? 'upvotes' : 'downvotes']: Math.max(0, prev[voteType === 'upvote' ? 'upvotes' : 'downvotes'] - 1)
      }));
    } else {
      // Add new vote, remove old vote if exists
      if (mockUserVote) {
        setMockVotes(prev => ({
          ...prev,
          [mockUserVote === 'upvote' ? 'upvotes' : 'downvotes']: Math.max(0, prev[mockUserVote === 'upvote' ? 'upvotes' : 'downvotes'] - 1)
        }));
      }
      
      setMockUserVote(voteType);
      setMockVotes(prev => ({
        ...prev,
        [voteType === 'upvote' ? 'upvotes' : 'downvotes']: prev[voteType === 'upvote' ? 'upvotes' : 'downvotes'] + 1
      }));
    }
  };

  // After a user votes, update the cache time to prevent immediate refetch
  const handleVoteWithCacheUpdate = (playerId, userId, voteType) => {
    handleVote(playerId, userId, voteType);
    const cacheKey = `${playerId}-${userId}`;
    const now = Date.now();
    lastFetchTimesCache[cacheKey] = now;
    lastFetchTimesCache[`${playerId}-votes`] = now;
  };

  // Use either real votes or mock votes
  const playerVotes = userId ? (votes[playerId] || { upvotes: 0, downvotes: 0 }) : mockVotes;
  const userVote = userId ? userVotes[playerId] : mockUserVote;
  const loading = isLoading[playerId];

  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={() => userId ? handleVoteWithCacheUpdate(playerId, userId, 'upvote') : handleMockVote('upvote')}
        disabled={loading && userId}
        className={`flex items-center space-x-1 px-2 py-1 rounded transition-all duration-200 ${
          userVote === 'upvote'
            ? 'bg-green-500 text-white'
            : 'bg-gray-200 text-gray-700 hover:bg-green-400'
        }`}
        title={userVote === 'upvote' ? 'Remove upvote' : 'Upvote'}
        aria-label={userVote === 'upvote' ? 'Remove upvote' : 'Upvote'}
      >
        <FontAwesomeIcon icon={faThumbsUp} />
        <span>{playerVotes.upvotes}</span>
      </button>
      
      <button
        onClick={() => userId ? handleVoteWithCacheUpdate(playerId, userId, 'downvote') : handleMockVote('downvote')}
        disabled={loading && userId}
        className={`flex items-center space-x-1 px-2 py-1 rounded transition-all duration-200 ${
          userVote === 'downvote'
            ? 'bg-red-500 text-white'
            : 'bg-gray-200 text-gray-700 hover:bg-red-400'
        }`}
        title={userVote === 'downvote' ? 'Remove downvote' : 'Downvote'}
        aria-label={userVote === 'downvote' ? 'Remove downvote' : 'Downvote'}
      >
        <FontAwesomeIcon icon={faThumbsDown} />
        <span>{playerVotes.downvotes}</span>
      </button>
    </div>
  );
};

VoteButtons.propTypes = {
  playerId: PropTypes.string.isRequired,
  userId: PropTypes.string
};

export default VoteButtons;