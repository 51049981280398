import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { observer } from 'mobx-react-lite';
import { playerStoreInstance } from './stores/PlayerStore';
import Login from './Login';
import Dashboard from './Dashboard';
import { CookieProvider } from './CookieContext';
import PlayerDebugView from './components/PlayerDebugView';
import ErrorBoundary from './components/ErrorBoundary';
import { VoteProvider } from './contexts/VoteContext';
import notificationService from './services/notificationService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingState } from './components/LoadingState';

const App = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const store = playerStoreInstance;
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [notificationsInitialized, setNotificationsInitialized] = useState(false);

  useEffect(() => {
    let unsubscribe = null;
    
    const setupNotifications = async () => {
      try {
        // Initialize notification service
        await notificationService.initialize();
        
        // Check if notifications are supported
        const supportInfo = notificationService.isSupported();
        console.log('Notification support info:', supportInfo);
        
        // Only proceed if notifications are supported
        if (supportInfo.supported) {
          // Set up message listener for foreground notifications
          const messageListener = notificationService.onMessageListener();
          
          // Handle incoming messages
          messageListener.then(payload => {
            if (payload?.notification) {
              const title = payload.notification.title || 'Neue Nachricht';
              const body = payload.notification.body || '';
              
              // Show toast notification
              toast.info(`${title}: ${body}`, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              
              // Update notification state
              setNotification({
                title,
                body
              });
            }
          }).catch(err => {
            console.error('Error setting up message listener:', err);
          });
        } else {
          console.log('Notifications not supported in this browser/device:', supportInfo);
        }
        
        setNotificationsInitialized(true);
      } catch (error) {
        console.error('Error initializing notifications:', error);
        setNotificationsInitialized(true); // Still mark as initialized to prevent infinite retries
      }
    };
    
    // Only setup notifications if not already initialized
    if (!notificationsInitialized) {
      setupNotifications();
    }
    
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [notificationsInitialized]);

  useEffect(() => {
    const initApp = async () => {
      try {
        setIsLoading(true);
        await store.initialize();
        
        if (store.isAuthenticated && store.leagues.length > 0) {
          const storedLeagueId = localStorage.getItem('selectedLeagueId');
          const validLeagueId = storedLeagueId && store.leagues.some(l => l.id === storedLeagueId)
            ? storedLeagueId
            : store.leagues[0].id;
            
          await store.setLeagueId(validLeagueId);
          localStorage.setItem('selectedLeagueId', validLeagueId);
        }
      } catch (error) {
        console.error('App initialization failed:', error);
      } finally {
        setIsLoading(false);
      }
    };
    initApp();
  }, []);

  useEffect(() => {
    let unsubscribeFromMessages = () => {};

    // This function is now redundant since we have the setupNotifications function above
    // We'll keep a simplified version just to maintain compatibility
    const setupMessaging = async () => {
      // We'll just log that this is redundant and not do anything
      console.log('Note: setupMessaging is redundant, using main notification initialization instead');
    };

    try {
      setupMessaging();
    } catch (error) {
      console.error('Unhandled error in notification setup:', error);
    }

    return () => {
      if (typeof unsubscribeFromMessages === 'function') {
        unsubscribeFromMessages();
      }
    };
  }, []);

  useEffect(() => {
    const handleError = (event) => {
      console.error('Global error:', event.error);
      toast.error('An unexpected error occurred. Please try again.');
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  useEffect(() => {
    const handleOffline = () => {
    };

    const handleOnline = () => {
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  if (isLoading) {
    return <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  return (
    <QueryClientProvider client={new QueryClient()}>
      <VoteProvider>
        <Router>
          <div className="bg-gray-900 w-full text-white">
            <CookieProvider>
              <Routes>
                <Route 
                  path="/login" 
                  element={
                    store.isAuthenticated ? 
                      <Navigate to="/dashboard" replace /> : 
                      <Login />
                  } 
                />
                <Route 
                  path="/dashboard/*" 
                  element={
                    store.isAuthenticated ? 
                      <ErrorBoundary>
                        <Dashboard />
                      </ErrorBoundary> : 
                      <Navigate to="/login" replace />
                  } 
                />
                <Route 
                  path="*" 
                  element={
                    <Navigate to={store.isAuthenticated ? "/dashboard" : "/login"} replace />
                  } 
                />
              </Routes>
            </CookieProvider>
          </div>
        </Router>
      </VoteProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
});

export default App;